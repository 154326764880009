import React, {useState, useEffect, useContext} from "react";
import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";

import {
	Dialog,
	DialogContent,
	DialogTitle,
	Button,
	DialogActions,
	CircularProgress,
	TextField,
	Switch,
	FormControlLabel,
} from "@material-ui/core";

import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import QueueSelect from "../QueueSelect";
import Typography from "@material-ui/core/Typography";
import {AuthContext} from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},

	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	informativo: {
		textDecoration: "italic",
		fontSize: "9pt",
		marginLeft: "5px"
	},
	subtitle: {
		marginBottom: 8,
		marginTop: 12,
		fontWeight: "bold"
	}
}));

const SessionSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
});

const WhatsAppModal = ({ open, onClose, whatsAppId }) => {
	const classes = useStyles();
	const initialState = {
		name: "",
		greetingMessage: "",
		greetingBotMessage: "",
		greetingMessageRedirect: "",
		farewellMessage: "",
		isDefault: false,
		metaIntegration: false,
		publicIDTypebot: "",
		publicDNSTypebot: "",
		nps: false,
		// 1️⃣2️⃣3️⃣4️⃣5️⃣ 😁😊😐😞😠
		npsMessage: "Olá, {{{name}}}!\n" +
			"\n" +
			"Estamos sempre buscando melhorar nossos serviços e sua opinião é muito importante para nós. Você poderia nos dizer, em uma escala de 1 a 5, como foi nosso atendimento?\n" +
			"\n" +
			"1️⃣ - Péssimo 😠\n" +
			"2️⃣ - Ruim 😞\n" +
			"3️⃣ - Médio 😐\n" +
			"4️⃣ - Bom 😊\n" +
			"5️⃣ - Ótimo! 😁",
		npsTksMessage: "Agradecemos sua participação e feedback!"
	};
	const [whatsApp, setWhatsApp] = useState(initialState);
	const [selectedQueueIds, setSelectedQueueIds] = useState([]);
	const [metaIntegration, setMetaIntegration] = useState(false);
	const [wwebjsIntegration, setWwebjsIntegration] = useState(false);
	const [baileysIntegration, setBaileysIntegration] = useState(true);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		const fetchSession = async () => {
			if (!whatsAppId) return;

			try {
				const { data } = await api.get(`whatsapp/${whatsAppId}`);
				data.npsMessage = data.npsMessage ?? "";
				data.npsTksMessage = data.npsTksMessage ?? "";
				data.phoneNumberId = data.phoneNumberId ?? undefined;
				data.wabaId = data.wabaId ?? undefined;
				data.tokenMeta = data.tokenMeta ?? undefined;
				setWhatsApp(data);
				setMetaIntegration(data.api === "cloud_api")
				setWwebjsIntegration(data.api === "wwebjs")
				setBaileysIntegration(data.api === "baileys")

				const whatsQueueIds = data.queues?.map(queue => queue.id);
				setSelectedQueueIds(whatsQueueIds);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, [whatsAppId]);

	const handleSaveWhatsApp = async values => {
		let apiConnector = "baileys";
		if (metaIntegration)
			apiConnector = "cloud_api";
		if (baileysIntegration)
			apiConnector = "baileys";
		if (wwebjsIntegration)
			apiConnector = "wwebjs";

 		const whatsappData = { ...values, queueIds: selectedQueueIds, api: apiConnector };

		try {
			if (whatsAppId) {
				await api.put(`/whatsapp/${whatsAppId}`, whatsappData);

				await api.post("/log", {
					log: {
						descricao: `O usuário ${user?.name} alterou a conexão ${whatsappData?.name}`,
						origem: 'Whatsapp',
						userId: user?.id,
						whatsappId: whatsAppId
					}
				});
			} else {
				const { data: wpp } = await api.post("/whatsapp", whatsappData);

				await api.post("/log", {
					log: {
						descricao: `O usuário ${user?.name} cadastrou uma nova conexão (${whatsappData?.name})`,
						origem: 'Whatsapp',
						userId: user?.id,
						whatsappId: wpp.id
					}
				});
			}
			toast.success(i18n.t("whatsappModal.success"));
			handleClose();
		} catch (err) {
			console.log(err)
			toastError(err);
		}
	};

	const handleClose = () => {
		onClose();
		setWhatsApp(initialState);
		setMetaIntegration(false);
	};

	return (
		<div className={classes.root}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="sm"
				fullWidth
				scroll="paper"
			>
				<DialogTitle>
					{whatsAppId
						? i18n.t("whatsappModal.title.edit")
						: i18n.t("whatsappModal.title.add")}
				</DialogTitle>
				<Formik
					initialValues={whatsApp}
					enableReinitialize={true}
					validationSchema={SessionSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveWhatsApp(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, touched, errors, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.name")}
										autoFocus
										name="name"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="isDefault"
												checked={values.isDefault}
											/>
										}
										label={i18n.t("whatsappModal.form.default")}
									/>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="nps"
												checked={values.nps}
											/>
										}
										label="N.P.S."
									/>
								</div>
								<div className={classes.multFieldLine} style={{marginTop: 10}}>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												disabled={!!whatsAppId}
												name="wwebjsIntegration"
												checked={wwebjsIntegration}
												onChange={(e) => {
													setWwebjsIntegration(!wwebjsIntegration);
													if (e.target.checked) {
														setBaileysIntegration(false);
														setMetaIntegration(false);
													}
												}}
											/>
										}
										label={"API 1. (W)"}
									/>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												disabled={!!whatsAppId}
												name="baileysIntegration"
												checked={baileysIntegration}
												onChange={(e) => {
													setBaileysIntegration(!baileysIntegration);
													if (e.target.checked) {
														setWwebjsIntegration(false);
														setMetaIntegration(false);
													}
												}}
											/>
										}
										label={"API 2. (B)"}
									/>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="metaIntegration"
												disabled={!!whatsAppId}
												checked={metaIntegration}
												onChange={(e) => {
													setMetaIntegration(!metaIntegration);
													if (e.target.checked) {
														setBaileysIntegration(false);
														setWwebjsIntegration(false);
													}
												}}
											/>
										}
										label={"Meta Cloud API"}
									/>
								</div>

								<Typography
									style={{marginBottom: 8, marginTop: 12, display: metaIntegration ? "flex" : "none"}}
									variant="subtitle1"
								>
									Meta Cloud API
								</Typography>
								<div className={classes.multFieldLine} style={{display: metaIntegration ? "flex" : "none"}}>
									<Field
										as={TextField}
										label={"Número ID"}
										name="phoneNumberId"
										error={touched.phoneNumberId && Boolean(errors.phoneNumberId)}
										helperText={touched.phoneNumberId && errors.phoneNumberId}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>

									<Field
										as={TextField}
										label={"Waba ID"}
										name="wabaId"
										error={touched.wabaId && Boolean(errors.wabaId)}
										helperText={touched.wabaId && errors.wabaId}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>

									<Field
										as={TextField}
										label={"Token Meta"}
										name="tokenMeta"
										error={touched.tokenMeta && Boolean(errors.tokenMeta)}
										helperText={touched.tokenMeta && errors.tokenMeta}
										variant="outlined"
										margin="dense"
										className={classes.textField}
									/>
								</div>

								<Typography
									className={classes.subtitle}
									variant="subtitle1"
								>
									Saudações
								</Typography>
								<div>
									<Field
										as={TextField}
										label={i18n.t("queueModal.form.greetingMessage")}
										type="greetingMessage"
										multiline
										minRows={5}
										fullWidth
										name="greetingMessage"
										error={
											touched.greetingMessage && Boolean(errors.greetingMessage)
										}
										helperText={
											touched.greetingMessage && errors.greetingMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<div>
									<Field
										as={TextField}
										label={"Mensagem de saudação para atendimentos via BOT"}
										type="greetingBotMessage"
										multiline
										minRows={5}
										fullWidth
										name="greetingBotMessage"
										error={
											touched.greetingBotMessage && Boolean(errors.greetingBotMessage)
										}
										helperText={
											touched.greetingBotMessage && errors.greetingBotMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<div>
									<Field
										as={TextField}
										label={"Mensagem de saudação para redirecionamentos automáticos"}
										type="greetingMessageRedirect"
										multiline
										minRows={5}
										fullWidth
										name="greetingMessageRedirect"
										error={
											touched.greetingMessageRedirect && Boolean(errors.greetingMessageRedirect)
										}
										helperText={
											touched.greetingMessageRedirect && errors.greetingMessageRedirect
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<Typography component="p" className={classes.informativo}>Opções fixas:</Typography>
								<Typography component="p" className={classes.informativo}>99 para transferir o atendimento para um atendente</Typography>
								<Typography component="p" className={classes.informativo}>00 para encerrar um atendimento (caso ativo)</Typography>
								<Typography
									className={classes.subtitle}
									variant="subtitle1"
								>
									Despedida
								</Typography>
								<div>
									<Field
										as={TextField}
										label={i18n.t("whatsappModal.form.farewellMessage")}
										type="farewellMessage"
										multiline
										minRows={5}
										fullWidth
										name="farewellMessage"
										error={
											touched.farewellMessage && Boolean(errors.farewellMessage)
										}
										helperText={
											touched.farewellMessage && errors.farewellMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<Typography
									className={classes.subtitle}
									variant="subtitle1"
								>
									Filas
								</Typography>
								<QueueSelect
									selectedQueueIds={selectedQueueIds}
									onChange={selectedIds => setSelectedQueueIds(selectedIds)}
								/>

								<Typography
									className={classes.subtitle}
									variant="subtitle1"
								>
									Typebot
								</Typography>
								<div>
									<Field
										as={TextField}
										label="Public DNS Typebot"
										fullWidth
										name="publicDNSTypebot"
										error={
											touched.publicDNSTypebot && Boolean(errors.publicDNSTypebot)
										}
										helperText={
											touched.publicDNSTypebot && errors.publicDNSTypebot
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<div>
									<Field
										as={TextField}
										label="Public ID Typebot"
										fullWidth
										name="publicIDTypebot"
										error={
											touched.publicIDTypebot && Boolean(errors.publicIDTypebot)
										}
										helperText={
											touched.publicIDTypebot && errors.publicIDTypebot
										}
										variant="outlined"
										margin="dense"
									/>
								</div>

								<Typography
									className={classes.subtitle}
									variant="subtitle1"
								>
									N.P.S.
								</Typography>
								<div>
									<Field
										as={TextField}
										label={"Mensagem de avaliação"}
										type="greetingBotMessage"
										multiline
										minRows={5}
										fullWidth
										name="npsMessage"
										error={
											touched.npsMessage && Boolean(errors.npsMessage)
										}
										helperText={
											touched.npsMessage && errors.npsMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
								<Typography component="p" className={classes.informativo}>Os notas de avaliação vão somente de 1 à 5</Typography><br/>
								<div>
									<Field
										as={TextField}
										label="Mensagem de agradecimento pela avaliação"
										fullWidth
										name="npsTksMessage"
										error={
											touched.npsTksMessage && Boolean(errors.npsTksMessage)
										}
										helperText={
											touched.npsTksMessage && errors.npsTksMessage
										}
										variant="outlined"
										margin="dense"
									/>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("whatsappModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{whatsAppId
										? i18n.t("whatsappModal.buttons.okEdit")
										: i18n.t("whatsappModal.buttons.okAdd")}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default React.memo(WhatsAppModal);
