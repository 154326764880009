import React, {useState, useEffect, useRef} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import whatsBackground from "../../assets/wa-background.png";
import {i18n} from "../../translate/i18n";

import TemplateSelect from "../TemplateSelect";
import {Done, OpenInNew, Phone} from "@material-ui/icons";
import clsx from "clsx";
import MarkdownWrapper from "../MarkdownWrapper";
import {Divider} from "@material-ui/core";
import api from "../../services/api";
import {toast} from "react-toastify";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    messagesList: {
        backgroundImage: `url(${whatsBackground})`,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        borderRadius: 10,
        marginTop: 10,
        padding: "20px 20px 20px 20px",
        overflowY: "scroll",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "90px",
        },
        ...theme.scrollbarStyles,
    },
    messageRight: {
        marginLeft: 20,
        marginTop: 2,
        minWidth: 100,
        maxWidth: 600,
        height: "auto",
        display: "block",
        position: "relative",
        "&:hover #messageActionsButton": {
            display: "flex",
            position: "absolute",
            top: 0,
            right: 0,
        },

        whiteSpace: "pre-wrap",
        backgroundColor: "#dcf8c6",
        color: "#303030",
        alignSelf: "flex-end",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 0,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: "0 1px 1px #b3b3b3",
    },
    textContentItem: {
        overflowWrap: "break-word",
        padding: "3px 80px 6px 6px",
    },

    textContentItemDeleted: {
        fontStyle: "italic",
        color: "rgba(0, 0, 0, 0.36)",
        overflowWrap: "break-word",
        padding: "3px 80px 6px 6px",
    },
    timestamp: {
        fontSize: 11,
        position: "absolute",
        bottom: 0,
        right: 5,
        color: "#999",
    },
    ackIcons: {
        fontSize: 18,
        verticalAlign: "middle",
        marginLeft: 4,
    },
    downloadMedia: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "inherit",
        padding: 10,
    },
    dailyLog: {
        alignItems: "center",
        textAlign: "center",
        alignSelf: "center",
        width: "450px",
        backgroundColor: "#e1f3fb",
        margin: "10px",
        borderRadius: "10px",
        boxShadow: "0 1px 1px #b3b3b3",
        "&:hover": {
            cursor: "pointer"
        }
    },
    dailyTimestampText: {
        color: "#808888",
        padding: 8,
        alignSelf: "center",
        marginLeft: "0px",
    },
}));

const TemplateModal = ({open, onClose, ticketId, initialValues, onSave}) => {
    const classes = useStyles();
    const isMounted = useRef(true);

    // const {user} = useContext(AuthContext);
    const [selectedTemplateIds, setSelectedTemplateIds] = useState([]);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClose = () => {
        onClose();
        setSelectedTemplateIds([]);
    };

	const handleSave = async () => {
        const templateData = JSON.stringify(JSON.parse(selectedTemplateIds.data));

        await api.post("/meta/templates", {
            template: templateData,
            ticketId
        }).then(response => {
            toast.success("Mensagem enviada com sucesso!");
            handleClose();
        }).catch(error => {
            toast.error("Não foi possível enviar a mensagem");
            console.log(error);
        });
    };

    const renderTemplateMessage = (template) => {
        if (template) {
            template = JSON.parse(template);
            return (
                <React.Fragment key={template.id}>
                    <div className={classes.messageRight}>
                        <div
                            className={clsx(classes.textContentItem, {
                                [classes.textContentItemDeleted]: false,
                            })}
                        >
                            <MarkdownWrapper>
                                {`*${template.components[0].text}*\n\n${template.components[1].text}\n\n_${template.components[2].text}_`}
                            </MarkdownWrapper>

                            <span className={classes.timestamp}>
                                {`${new Date().getHours()}:${new Date().getMinutes()}`}
                                <Done fontSize="small" className={classes.ackIcons}/>
                            </span>
                        </div>

                        {template.components[3] && (<Divider />)}

                        <div className={classes.downloadMedia}>
                            {template.components[3]?.buttons?.map(button => {
                                return (
                                    <Button
                                        startIcon={button.type === "PHONE_NUMBER" ? (<Phone/>) : (<OpenInNew/>)}
                                        key={`${template.id}-${button.type}`}
                                        color="primary"
                                        variant="outlined"
                                        target="_blank"
                                        style={{marginRight: 5}}
                                        href={button.type === "PHONE_NUMBER" ? `https://api.whatsapp.com/send?phone=${button.phone_number.replaceAll("+", "")}` : button.url}
                                    >
                                        {button.text}
                                    </Button>
                                )
                            })}
                        </div>
                    </div>
                </React.Fragment>
            );
        }

        return (
            <span
                className={classes.dailyLog}
            >
                <div className={classes.dailyTimestampText}>
                  Selecione um template ver uma prévia da mensagem
                </div>
              </span>
        )
    }

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth scroll="paper">
                <DialogTitle id="form-dialog-title">
                    Templates
                </DialogTitle>

                <DialogContent dividers>
                    <Typography
                        variant="subtitle1"
                    >
                        Modelos de mensagem
                    </Typography>
                    <div>
                        <TemplateSelect
                            ticketId={ticketId}
                            selectedTemplateIds={selectedTemplateIds}
                            onChange={values => {
                                setSelectedTemplateIds(values);
                            }}
                        />
                    </div>

                    <div className={classes.messagesList}>
                        {renderTemplateMessage(selectedTemplateIds?.data)}
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        // disabled={isSubmitting}
                        variant="outlined"
                    >
                        {i18n.t("contactModal.buttons.cancel")}
                    </Button>
                    <Button
                        type="button"
                        color="primary"
                        disabled={!selectedTemplateIds?.data}
                        variant="contained"
                        className={classes.btnWrapper}
						onClick={handleSave}
                    >
                        Enviar
                        {/*{isSubmitting && (
                            <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                            />
                        )}*/}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default TemplateModal;
