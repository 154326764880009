import React from "react";


import {makeStyles} from "@material-ui/core/styles";
import {Tooltip} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import clsx from "clsx";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Badge from "@material-ui/core/Badge";
import {format, isSameDay, parseISO} from "date-fns";
import MarkdownWrapper from "../MarkdownWrapper";
import Divider from "@material-ui/core/Divider";
import {green} from "@material-ui/core/colors";
// import {useHistory} from "react-router-dom";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    ticket: {
        position: "relative",
    },

    pendingTicket: {
        cursor: "unset",
    },

    noTicketsDiv: {
        display: "flex",
        height: "100px",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },

    noTicketsText: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4",
    },

    noTicketsTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px",
    },

    contactNameWrapper: {
        display: "flex",
        justifyContent: "space-between",
    },

    lastMessageTime: {
        justifySelf: "flex-end",
    },

    closedBadge: {
        alignSelf: "center",
        justifySelf: "center",
        marginRight: 42,
        paddingRight: 20,
        top: "-2px",
        marginLeft: "auto",
    },

    contactLastMessage: {
        paddingRight: 20,
    },

    newMessagesCount: {
        alignSelf: "center",
        marginRight: 8,
        marginLeft: "auto",
    },

    badgeStyle: {
        color: "white",
        backgroundColor: green[500],
    },

    acceptButton: {
        position: "absolute",
        left: "50%",
    },

    ticketQueueColor: {
        flex: "none",
        width: "8px",
        height: "100%",
        position: "absolute",
        top: "0%",
        left: "0%",
    },
    userTag: {
        position: "absolute",
        marginRight: 5,
        right: 40,
        bottom: 5,
        background: "#2576D2",
        color: "#ffffff",
        border: "1px solid #CCC",
        padding: 1,
        paddingLeft: 5,
        paddingRight: 5,
        borderRadius: 10,
        fontSize: "0.9em"
    },
}));

const RenderUserTicketInfoModal = ({ticket}) => {
    const classes = useStyles();
    // const history = useHistory();

    return (
        <React.Fragment key={ticket.id}>
            <ListItem
                dense
                button
                onClick={e => {
                    if (window.confirm("Deseja visualizar esse ticket?")) {
                        window.open(`/tickets/${ticket.id}`);
                    }
                }}
                // selected={ticketId && +ticketId === ticket.id}
                className={clsx(classes.ticket, {
                    [classes.pendingTicket]: ticket.status === "pending",
                })}
            >
                <Tooltip
                    arrow
                    placement="right"
                    title={ticket.queue?.name || "Sem fila"}
                >
					<span
                        style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
                        className={classes.ticketQueueColor}
                    ></span>
                </Tooltip>
                <ListItemAvatar>
                    <Avatar src={ticket?.contact?.profilePicUrl} />
                </ListItemAvatar>
                <ListItemText
                    disableTypography
                    primary={
                        <span className={classes.contactNameWrapper}>
							<Typography
                                noWrap
                                component="span"
                                variant="body2"
                                color="textPrimary"
                            >
								{ticket.contact.name}
							</Typography>
                            {ticket.status === "closed" && (
                                <Badge overlap="rectangular"
                                       className={classes.closedBadge}
                                       badgeContent={"Encerrada"}
                                       color="primary"
                                />
                            )}
                            {ticket.lastMessage && (
                                <Typography
                                    className={classes.lastMessageTime}
                                    component="span"
                                    variant="body2"
                                    color="textSecondary"
                                >
                                    {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                                        <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                                    ) : (
                                        <>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
                                    )}
                                </Typography>
                            )}
                            {ticket.whatsappId && (
                                <div className={classes.userTag} title="Conexão que está sendo utilizada nesse ticket.">{ticket.whatsapp?.name}</div>
                            )}
						</span>
                    }
                    secondary={
                        <span className={classes.contactNameWrapper}>
							<Typography
                                className={classes.contactLastMessage}
                                noWrap
                                component="span"
                                variant="body2"
                                color="textSecondary"
                            >
								{ticket.lastMessage ? (
                                    <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
                                ) : (
                                    <br />
                                )}
							</Typography>

							<Badge overlap="rectangular"
                                   className={classes.newMessagesCount}
                                   badgeContent={ticket.unreadMessages}
                                   classes={{
                                       badge: classes.badgeStyle,
                                   }}
                            />
						</span>
                    }
                />
            </ListItem>
            <Divider variant="inset" />
        </React.Fragment>
    )
};

export default RenderUserTicketInfoModal;
