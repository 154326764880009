import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import Tickets from "../pages/Tickets/";
// import Signup from "../pages/Signup/";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Queues from "../pages/Queues/";
import Tags from "../pages/Tags/";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";

import TicketsBot from "../pages/TicketsBot";
import Perguntas from "../pages/PerguntasRespostas"
import EnvioMassa from "../pages/EnvioMassa";
import Agendamentos from "../pages/Agendamentos";
import Protocolos from "../pages/Protocolos";
import Nps from "../pages/Nps";

const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          {/*<Route exact path="/signup" component={Signup} />*/}
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Route exact path="/" component={Dashboard} isPrivate />
              <Route
                exact
                path="/tickets/:ticketId?/:chatId?"
                component={Tickets}
                isPrivate
              />
              <Route
                exact
                path="/connections"
                component={Connections}
                isPrivate
              />
              <Route exact path="/contacts" component={Contacts} isPrivate />
              <Route exact path="/users" component={Users} isPrivate />

              <Route
                exact
                path="/ticketsBot"
                component={TicketsBot}
                isPrivate
              />

              <Route
                exact
                path="/perguntas"
                component={Perguntas}
                isPrivate
              />

              <Route
                  exact
                  path="/envios"
                  component={EnvioMassa}
                  isPrivate
              />

              <Route
                  exact
                  path="/agendamentos"
                  component={Agendamentos}
                  isPrivate
              />

              <Route
                exact
                path="/quickAnswers"
                component={QuickAnswers}
                isPrivate
              />

              <Route
                exact
                path="/protocolos"
                component={Protocolos}
                isPrivate
              />
              <Route exact path="/Settings" component={Settings} isPrivate />
              <Route exact path="/Queues" component={Queues} isPrivate />
              <Route exact path="/Nps" component={Nps} isPrivate />
              <Route exact path="/tags" component={Tags} isPrivate />
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
