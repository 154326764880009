import React, { useState, useRef, useEffect, useContext } from "react";

import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import openSocket from "socket.io-client";
import useSound from "use-sound";

import Popover from "@material-ui/core/Popover";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { makeStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
import ChatIcon from "@material-ui/icons/Chat";

import TicketListItem from "../TicketListItem";
import { i18n } from "../../translate/i18n";
import useTickets from "../../hooks/useTickets";
import alertSound from "../../assets/sound.mp3";
import { AuthContext } from "../../context/Auth/AuthContext";
import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	tabContainer: {
		overflowY: "auto",
		maxHeight: 350,
		...theme.scrollbarStyles,
	},
	popoverPaper: {
		width: "100%",
		maxWidth: 350,
		marginLeft: theme.spacing(2),
		marginRight: theme.spacing(1),
		[theme.breakpoints.down("sm")]: {
			maxWidth: 270,
		},
	},
	noShadow: {
		boxShadow: "none !important",
	},
}));

const NotificationsPopOver = () => {
	const classes = useStyles();

	const history = useHistory();
	const { user } = useContext(AuthContext);
	const ticketIdUrl = +history.location.pathname.split("/")[2];
	const ticketIdRef = useRef(ticketIdUrl);
	const anchorEl = useRef();
	const [isOpen, setIsOpen] = useState(false);
	const [notifications, setNotifications] = useState([]);

	const [, setDesktopNotifications] = useState([]);

	const { tickets } = useTickets({ withUnreadMessages: "true" });
	const [play] = useSound(alertSound);
	const soundAlertRef = useRef();

	// const historyRef = useRef(history);

	useEffect(() => {
		soundAlertRef.current = play;

		/*if (!("Notification" in window)) {
			console.log("This browser doesn't support notifications");
		} else {
			Notification.requestPermission();
		}*/
	}, [play]);

	useEffect(() => {
		setNotifications(tickets);
	}, [tickets]);

	useEffect(() => {
		ticketIdRef.current = ticketIdUrl;
	}, [ticketIdUrl]);

	useEffect(() => {
		const socket = openSocket(window.env?.REACT_APP_BACKEND_URL || process.env.REACT_APP_BACKEND_URL);

		socket.on("connect", () => socket.emit("joinNotification"));

		socket.on("ticket", data => {
			if (data.action === "updateUnread" || data.action === "delete") {
				setNotifications(prevState => {
					const ticketIndex = prevState.findIndex(t => t.id === data.ticketId);
					if (ticketIndex !== -1) {
						prevState.splice(ticketIndex, 1);
						return [...prevState];
					}
					return prevState;
				});

				setDesktopNotifications(prevState => {
					const notfiticationIndex = prevState.findIndex(
						n => n.tag === String(data.ticketId)
					);
					if (notfiticationIndex !== -1) {
						prevState[notfiticationIndex].close();
						prevState.splice(notfiticationIndex, 1);
						return [...prevState];
					}
					return prevState;
				});
			}

			if (data.action === "update" && data.ticket?.queueId !== null) {
				const userQueue = user?.queues.findIndex(q => q.id === data.ticket.queueId);
				const ticketQueue = data.ticket.queueId;

				if (userQueue === -1 && ticketQueue !== null) {
					setTimeout(()=>{
						setNotifications(prevState => {
							const ticketIndex = prevState.findIndex(t => t.id === data.ticket.id);
							if (ticketIndex !== -1) {
								prevState.splice(ticketIndex, 1);
								return [...prevState];
							}
							return prevState;
						});

						setDesktopNotifications(prevState => {
							const notfiticationIndex = prevState.findIndex(
								n => n.tag === String(data.ticket.id)
							);
							if (notfiticationIndex !== -1) {
								prevState[notfiticationIndex].close();
								prevState.splice(notfiticationIndex, 1);
								return [...prevState];
							}
							return prevState;
						});
					},2000);
				}
			}
		});

		socket.on("appMessage", async data => {
			if (
				data.action === "create" &&
				!data.message.read &&
				(data.ticket.userId === user?.id || !data.ticket.userId || data.contact.isGroup)
			) {
				let notificateAllTickets = await fetchSettings('notificateAllTickets');
				let onlyAdminSeeEmptyQueue = await fetchSettings('onlyAdminSeeEmptyQueue');
				let notifyGroupToAllUsers = await fetchSettings('notifyGroupToAllUsers');
				if (onlyAdminSeeEmptyQueue === "enabled")
					if ((user.profile === "user" || user.profile === "gerente") && data.ticket.queueId === null)
						return;

				if (notificateAllTickets === "disabled") {
					const shouldNotificate = user?.queues.findIndex(q => q.id === data.ticket.queueId);
					if (shouldNotificate === -1) return;
				} else {
					const userQueue = user?.queues.findIndex(q => q.id === data.ticket.queueId);
					const ticketQueue = data.ticket.queueId;

					if (userQueue === -1 && ticketQueue !== null) return;
				}

				setNotifications(prevState => {
					const ticketIndex = prevState.findIndex(t => t.id === data.ticket.id);
					if (ticketIndex !== -1) {
						prevState[ticketIndex] = data.ticket;
						return [...prevState];
					}
					return [data.ticket, ...prevState];
				});

				const shouldNotNotificate =
					(data.message.ticketId === ticketIdRef.current &&
						document.visibilityState === "visible") ||
					((data.ticket.userId && data.ticket.userId !== user?.id) && (data.contact.isGroup && notifyGroupToAllUsers !== "enabled"));

				if (shouldNotNotificate) return;

				if (data.ticket.status !== "pending")
					handleNotifications(data);
			}
		});

		return () => {
			socket.disconnect();
		};
	}, [user]);

	const fetchSettings = async (key) => {
		try {
			const { data } = await api.get("/settings");

			const nat = data.find(s => s.key === key)
			if (nat?.value)
				return (nat.value);
			else
				return "disabled";
		} catch (err) {
			toastError(err);
		}
	};

	const handleNotifications = data => {
		const { message, contact, ticket } = data;

		const options = {
			data: {
				title: `${i18n.t("tickets.notification.message")} ${contact.name}`,
				url: `${window.location.origin}/tickets/${ticket.id}`
			},
			body: `${message.body} - ${format(new Date(), "HH:mm")}`,
			icon: contact.profilePicUrl,
			tag: ticket.id,
			renotify: true,
		};

		/*const notification = new Notification(
			`${i18n.t("tickets.notification.message")} ${contact.name}`,
			options
		);

		notification.onclick = e => {
			e.preventDefault();
			window.focus();
			historyRef.current.push(`/tickets/${ticket.id}`);
		};*/

		const notification = {};

		if (navigator.serviceWorker)
			navigator.serviceWorker.controller.postMessage(options)

		setDesktopNotifications(prevState => {
			const notfiticationIndex = prevState.findIndex(
				n => n.tag === notification.tag
			);
			if (notfiticationIndex !== -1) {
				prevState[notfiticationIndex] = notification;
				return [...prevState];
			}
			return [notification, ...prevState];
		});

		soundAlertRef.current();
	};

	const handleClick = () => {
		setIsOpen(prevState => !prevState);
	};

	const handleClickAway = () => {
		setIsOpen(false);
	};

	const NotificationTicket = ({ children }) => {
		return <div onClick={handleClickAway}>{children}</div>;
	};

	return (
		<>
			<IconButton
				onClick={handleClick}
				ref={anchorEl}
				aria-label="Open Notifications"
				color="inherit"
			>
				<Badge overlap="rectangular" badgeContent={notifications.length} color="secondary">
					<ChatIcon />
				</Badge>
			</IconButton>
			<Popover
				disableScrollLock
				open={isOpen}
				anchorEl={anchorEl.current}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				classes={{ paper: classes.popoverPaper }}
				onClose={handleClickAway}
			>
				<List dense className={classes.tabContainer}>
					{notifications.length === 0 ? (
						<ListItem>
							<ListItemText>{i18n.t("notifications.noTickets")}</ListItemText>
						</ListItem>
					) : (
						notifications.map(ticket => (
							<NotificationTicket key={ticket.id}>
								<TicketListItem ticket={ticket} />
							</NotificationTicket>
						))
					)}
				</List>
			</Popover>
		</>
	);
};

export default NotificationsPopOver;
