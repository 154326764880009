import React, { useState, useEffect, useRef } from "react";
import { useTheme } from "@material-ui/core/styles";
import {
	BarChart,
	CartesianGrid,
	Bar,
	XAxis,
	YAxis,
	Label,
	ResponsiveContainer,
} from "recharts";
import { startOfHour, parseISO, format } from "date-fns";

// import { i18n } from "../../translate/i18n";


import Title from "./Title";
import useTickets from "../../hooks/useTickets";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

const colors = [
	"#00FF00", "#66FF00", "#99FF00", "#CCFF00",
	"#EEFF00", "#FFFF00", "#FFCC00", "#FF9900",
	"#FF6600", "#FF3300", "#FF0033", "#FF0000"
];

const Chart = ({firstDate, lastDate}) => {
	const theme = useTheme();

	// const date = useRef(new Date().toISOString());
	const fd = useRef(firstDate.toISOString());
	const ld = useRef(lastDate.toISOString());
	const { tickets } = useTickets({ firstDate: fd.current, lastDate: ld.current, limit: 50000 });

	const [chartData, setChartData] = useState([
		{ time: "08:00", amount: 0, fill: colors[0] },
		{ time: "09:00", amount: 0, fill: colors[1] },
		{ time: "10:00", amount: 0, fill: colors[2] },
		{ time: "11:00", amount: 0, fill: colors[3] },
		{ time: "12:00", amount: 0, fill: colors[4] },
		{ time: "13:00", amount: 0, fill: colors[5] },
		{ time: "14:00", amount: 0, fill: colors[6] },
		{ time: "15:00", amount: 0, fill: colors[7] },
		{ time: "16:00", amount: 0, fill: colors[8] },
		{ time: "17:00", amount: 0, fill: colors[9] },
		{ time: "18:00", amount: 0, fill: colors[10] },
		{ time: "19:00", amount: 0, fill: colors[11] },
	]);

	useEffect(() => {
		setChartData(prevState => {
			let aux = [...prevState];

			aux.forEach(a => {
				tickets.forEach(ticket => {
					format(startOfHour(parseISO(ticket.createdAt)), "HH:mm") === a.time &&
						a.amount++;
				});
			});

			return aux;
		});
	}, [tickets]);


	return (
		<React.Fragment>
			<Title>
				<span>{`Atendimentos por período: ${tickets.length}`} (total)</span>
				<span title="Gráfico afetado pelo filtro de data" style={{float: "right"}}><CalendarTodayIcon /></span>
			</Title>
			<ResponsiveContainer>
				<BarChart
					data={chartData}
					barSize={40}
					width={730}
					height={250}
					margin={{
						top: 16,
						right: 16,
						bottom: 0,
						left: 24,
					}}
				>
					<CartesianGrid strokeDasharray="3 3" />
					<XAxis dataKey="time" stroke={theme.palette.text.secondary} />
					<YAxis
						type="number"
						allowDecimals={false}
						stroke={theme.palette.text.secondary}
					>
						<Label
							angle={270}
							position="left"
							style={{ textAnchor: "middle", fill: theme.palette.text.primary }}
						>
							Tickets
						</Label>
					</YAxis>
					<Bar dataKey="amount" fill={theme.palette.primary.main} />
				</BarChart>
			</ResponsiveContainer>
		</React.Fragment>
	);
};

export default Chart;
