import React, {useState, useEffect} from "react";

import {makeStyles} from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import api from "../../services/api";
import {toast} from "react-toastify";
import RenderUserTicketInfoModal from "../RenderUserTicketsInfoModal";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    }
}));

const UserTicketInfoModal = ({open, onClose, user, queueId}) => {
    const classes = useStyles();

    const [userData, setUserData] = useState(null);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchUsers = async () => {
                try {
                    const {data} = await api.get("/dashboard/users/" + user.id, {
                        params: {
                            queueId
                        }
                    })

                    console.log(data)
                    setUserData(data);
                } catch (err) {
                    toast.error(err)
                }
            }

            fetchUsers()
        }, 100)
        return () => clearTimeout(delayDebounceFn)
    }, [user, open, queueId]);

    const handleClose = () => {
        onClose();
        setUserData(null);
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth scroll="paper">
                <DialogTitle>
                    {` Tickets sendo atendidos por ${user?.name}`}
                </DialogTitle>

                <DialogContent dividers>
                    {userData?.length > 0 ? userData.map(ticket => (
                        <RenderUserTicketInfoModal key={ticket.id} ticket={ticket} />
                    )) :
                        <p>Nenhum ticket foi encontrado!</p>
                    }
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        // disabled={isSubmitting}
                        variant="outlined"
                    >
                        Fechar
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default UserTicketInfoModal;
