import React, {useState, useEffect, useContext} from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

import api from "../../services/api";
import toastError from "../../errors/toastError";
import ContactSelect from "../ContactSelect";
import TagSelect from "../TagSelect";
import {AuthContext} from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
}));

const EnvioSchema = Yup.object().shape({
	mensagem: Yup.string()
		.min(1, "Too Short!")
		.required("Required")
});

const EnvioMassaModal = ({ open, onClose, envioId }) => {
	const classes = useStyles();

	const initialState = {
		mensagem: "",
		contactIds: [],
		tagIds: [],
		allContacts: false
	};

	const [envio, setEnvio] = useState(initialState);
	const [selectedContactIds, setSelectedContactIds] = useState([]);
	const [selectedTagIds, setSelectedTagIds] = useState([]);
	const [selectedAllContacts, setSelectedAllContacts] = useState(false);
	const { user } = useContext(AuthContext);

	useEffect(() => {
		(async () => {
			if (!envioId) return;
			try {
				const { data } = await api.get(`/envioMassa/${envioId}`);
				setEnvio(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
			return () => {
				setEnvio({
					mensagem: "",
					contactIds: [],
					tagIds: [],
					allContacts: false
				});
			};
		})();
	}, [envioId, open]);

	const handleClose = () => {
		onClose();
		setEnvio(initialState);
		setSelectedTagIds([]);
		setSelectedContactIds([]);
		setSelectedAllContacts(false)
	};

	const handleSaveEnvio = async values => {
		const request = {
			mensagem: values.mensagem,
			allContacts: selectedAllContacts,
			contactsIds: selectedContactIds,
			tagsIds: selectedTagIds,
			log: {
				userId: user?.id,
				userName: user?.name
			}
		}

		try {
			if (envioId) {
				await api.put(`/envioMassa/${envioId}`, request);
			} else {
				await api.post("/envioMassa", request);
			}
			toast.success("Envio saved successfully");
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{envioId
						? `Editar`
						: `Novo envio em massa`}
				</DialogTitle>
				<Formik
					initialValues={envio}
					enableReinitialize={true}
					validationSchema={EnvioSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveEnvio(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers style={{ width: "500px"}}>
								<div style={{color: 'red', fontStyle: 'italic'}}>Não recomendamos o envio em massa para todos os contatos, pois a mensagem pode ser interpretada como Span pela Meta(Facebook) e bloquear o número!</div>
								<Field
									as={TextField}
									label="Mensagem"
									type="greetingMessage"
									multiline
									minRows={5}
									fullWidth
									name="mensagem"
									error={
										touched.mensagem && Boolean(errors.mensagem)
									}
									helperText={
										touched.mensagem && errors.mensagem
									}
									variant="outlined"
									margin="dense"
								/>

								<TagSelect
									selectedTagIds={selectedTagIds}
									onChange={(values) => {
										setSelectedTagIds(values);
										setSelectedContactIds([]);
									}}
								/>

								<ContactSelect
									selectedContactIds={selectedContactIds}
									onChange={(selectedIds) => {
										setSelectedContactIds(selectedIds);
										setSelectedTagIds([]);

									}}
								/>

								<FormGroup style={{marginLeft: '5px'}}>
									<FormControlLabel onChange={(e) => {
										selectedAllContacts ? setSelectedAllContacts(false) : setSelectedAllContacts(true);
										setSelectedContactIds([]);
										setSelectedTagIds([]);
									}} control={<Switch />} label="Todos os contatos" />
								</FormGroup>

							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									Cancelar
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{envioId
										? `Salvar`
										: `Enviar`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default EnvioMassaModal;
