import React, {useState, useEffect} from "react";

import {makeStyles} from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import Paper from "@material-ui/core/Paper";

import TicketListItem from "../TicketListItem";
import TicketsListSkeleton from "../TicketsListSkeleton";

import {i18n} from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import ListItem from "@material-ui/core/ListItem";

const useStyles = makeStyles(theme => ({
    info: {
        textDecoration: "italic",
        fontSize: "9pt",
        alignItems: "center"
    },
    ticketsListWrapper: {
        position: "relative",
        display: "flex",
        height: "100%",
        flexDirection: "column",
        overflow: "hidden",
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },

    ticketsList: {
        flex: 1,
        overflowY: "scroll",
        ...theme.scrollbarStyles,
        borderTop: "2px solid rgba(0, 0, 0, 0.12)",
    },

    ticketsListHeader: {
        color: "rgb(67, 83, 105)",
        zIndex: 2,
        backgroundColor: "white",
        borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },

    ticketsCount: {
        fontWeight: "normal",
        color: "rgb(104, 121, 146)",
        marginLeft: "8px",
        fontSize: "14px",
    },

    noTicketsText: {
        textAlign: "center",
        color: "rgb(104, 121, 146)",
        fontSize: "14px",
        lineHeight: "1.4",
    },

    noTicketsTitle: {
        textAlign: "center",
        fontSize: "16px",
        fontWeight: "600",
        margin: "0px",
    },

    noTicketsDiv: {
        display: "flex",
        height: "100px",
        margin: 40,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
}));

/*
const reducer = (state, action) => {
    if (action.type === "LOAD_TICKETS") {
        const newTickets = action.payload;

        newTickets.forEach(ticket => {
            const ticketIndex = state.findIndex(t => t.id === ticket.id);
            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;
                if (ticket.unreadMessages > 0) {
                    state.unshift(state.splice(ticketIndex, 1)[0]);
                }
            } else {
                state.push(ticket);
            }
        });

        return [...state];
    }

    if (action.type === "RESET_UNREAD") {
        const ticketId = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state[ticketIndex].unreadMessages = 0;
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET") {
        const ticket = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
        } else {
            state.unshift(ticket);
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET_UNREAD_MESSAGES") {
        const ticket = action.payload;

        const ticketIndex = state.findIndex(t => t.id === ticket.id);
        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
            state.unshift(state.splice(ticketIndex, 1)[0]);
        } else {
            state.unshift(ticket);
        }

        return [...state];
    }

    if (action.type === "UPDATE_TICKET_CONTACT") {
        const contact = action.payload;
        const ticketIndex = state.findIndex(t => t.contactId === contact.id);
        if (ticketIndex !== -1) {
            state[ticketIndex].contact = contact;
        }
        return [...state];
    }

    if (action.type === "DELETE_TICKET") {
        const ticketId = action.payload;
        const ticketIndex = state.findIndex(t => t.id === ticketId);
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
        }

        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};
*/

const TicketsSearchedList = (props) => {
    const {status, searchParam, searchContact, showAll, selectedQueueIds, selectedUserIds, style, selectedTagIds} =
        props;
    const classes = useStyles();

    const [ticketsList, setTicketsList] = useState([]);
    const [loading, setLoading] = useState(false);
    // const {user} = useContext(AuthContext);
    // const [onlyAdmin, setOnlyAdmin] = useState(null);

/*
    useEffect(() => {
        const fetchSession = async () => {
            try {
                const {data} = await api.get("/settings");

                const ha = data.find(s => s.key === 'onlyAdminSeeEmptyQueue')
                if (ha?.value)
                    setOnlyAdmin(ha.value)
            } catch (err) {
                toastError(err);
            }
        };
        fetchSession();
    }, []);
*/

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async() => {
                try {
                    const { data } = await api.get("/tickets/filter" , {
                        params: {
                            searchParam,
                            searchContact,
                            searchUser: selectedUserIds,
                            searchTag: selectedTagIds,
                            status,
                            showAll,
                            queueIds: JSON.stringify(selectedQueueIds),
                        },
                    })

                    setTicketsList(data.tickets)

                    setLoading(false)
                } catch (err) {
                    setLoading(false)
                    toastError(err)
                }
            }

            fetchTickets()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [searchParam, status, showAll, searchContact, selectedUserIds, selectedQueueIds, selectedTagIds]);

    return (
        <Paper className={classes.ticketsListWrapper} style={style}>
            <Paper
                square
                name="closed"
                elevation={0}
                className={classes.ticketsList}
                // onScroll={handleScroll}
            >
                <List style={{paddingTop: 0}}>
                    {ticketsList.length === 0 && !loading ? (
                        <div className={classes.noTicketsDiv}>
							<span className={classes.noTicketsTitle}>
								{i18n.t("ticketsList.noTicketsTitle")}
							</span>
                            <p className={classes.noTicketsText}>
                                {i18n.t("ticketsList.noTicketsMessage")}
                            </p>
                        </div>
                    ) : (
                        <>
                            {ticketsList.length > 0 ? (
                                ticketsList.map(ticket => (
                                    <TicketListItem ticket={ticket} key={ticket.id}/>
                                ))
                            ) : null }

                            {ticketsList.length > 0 ? (
                                <ListItem className={classes.info}>
                                    Listagem limitada a 50 tickets
                                </ListItem>
                            ) : null}
                        </>
                    )}
                    {loading && <TicketsListSkeleton/>}
                </List>
            </Paper>
        </Paper>
    );
};

export default TicketsSearchedList;
