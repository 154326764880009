import React, {useState, useEffect, useContext} from "react";
import openSocket from "socket.io-client";

import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import { toast } from "react-toastify";

import Tooltip from "@material-ui/core/Tooltip";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";
import Button from "@material-ui/core/Button";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {AuthContext} from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		// alignItems: "center",
		padding: theme.spacing(8, 8, 3),
	},

	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12,

	},

	settingOption: {
		marginLeft: "auto",
	},
	margin: {
		margin: theme.spacing(1),
	},
	obs: {
		fontSize: "9pt",
		color: "red",
		fontStyle: "italic",
		display: "flex",
		flexDirection: "row"
	}
}));

const Settings = () => {
	const classes = useStyles();

	const {user} = useContext(AuthContext);
	const [settings, setSettings] = useState([]);
	const [horarioAbertura, setHorarioAbertura] = useState([]);
	const [horarioFechamento, setHorarioFechamento] = useState([]);
	const [horarioAlmoco, setHorarioAlmoco] = useState([]);
	const [horarioFimAlmoco, setHorarioFimAlmoco] = useState([]);
	const [mensagemHorario, setMensagemHorario] = useState([]);
	const [mensagemTransfer, setMensagemTransfer] = useState([]);
	const [messageBot, setMessageBot] = useState([]);
	const [mmm, setMmm] = useState(false);
	const [ttt, setTtt] = useState(false);
	const [www, setWww] = useState(false);
	const [thh, setThh] = useState(false);
	const [fff, setFff] = useState(false);
	const [sss, setSss] = useState(false);
	const [suu, setSuu] = useState(false);
	const [hhh, setHhh] = useState(false);
	const [queues, setQueues] = useState([]);
	const [users, setUsers] = useState([]);

	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/users", {
					params: { all: true },
				});
				setUsers(data.users);
			} catch (err) {
				toastError(err);
			}
		})();
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const { data } = await api.get("/queue");
				setQueues(data);
			} catch (err) {
				toastError(err);
			}
		})();
	}, []);

	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/settings");
				setSettings(data);

				const monday = data.find(s => s.key === 'monday');
				if (monday?.value)
					setMmm(monday.value === "enabled");
					// setState({ ...state, [monday.key]: monday.value === "enabled" });

				const tuesday = data.find(s => s.key === 'tuesday');
				if (tuesday?.value)
					setTtt(tuesday.value === "enabled");
					// setState({ ...state, [tuesday.key]: tuesday.value === "enabled" });

				const wednesday = data.find(s => s.key === 'wednesday');
				if (wednesday?.value)
					setWww(wednesday.value === "enabled");
					// setState({ ...state, [wednesday.key]: wednesday.value === "enabled" });

				const thursday = data.find(s => s.key === 'thursday');
				if (thursday?.value)
					setThh(thursday.value === "enabled");
					// setState({ ...state, [thursday.key]: thursday.value === "enabled" });

				const friday = data.find(s => s.key === 'friday');
				if (friday?.value)
					setFff(friday.value === "enabled");
					// setState({ ...state, [friday.key]: friday.value === "enabled" });

				const saturday = data.find(s => s.key === 'saturday');
				if (saturday?.value)
					setSss(saturday.value === "enabled");
					// setState({ ...state, [saturday.key]: saturday.value === "enabled" });

				const sunday = data.find(s => s.key === 'sunday');
				if (sunday?.value)
					setSuu(sunday.value === "enabled");
					// setState({ ...state, [sunday.key]: sunday.value === "enabled" });

				const holidays = data.find(s => s.key === 'holidays');
				if (holidays?.value)
					setHhh(holidays.value === "enabled");

				const ha = data.find(s => s.key === 'horarioAbertura')
				if (ha?.value)
					setHorarioAbertura(ha.value)

				const hf = data.find(s => s.key === 'horarioFechamento')
				if (hf?.value)
					setHorarioFechamento(hf.value)

				const hal = data.find(s => s.key === 'horarioAlmoco')
				if (hal?.value)
					setHorarioAlmoco(hal.value)

				const hfe = data.find(s => s.key === 'horarioFimAlmoco')
				if (hfe?.value)
					setHorarioFimAlmoco(hfe.value)

				const msgOfTime = data.find(s => s.key === 'msgOutOfTime')
				if (msgOfTime?.value)
					setMensagemHorario(msgOfTime.value)

				const msgTransfer = data.find(s => s.key === 'mensagemTransfer')
				if (msgTransfer?.value)
					setMensagemTransfer(msgTransfer.value)

				const msgBot = data.find(s => s.key === 'messageBot')
				if (msgBot?.value)
					setMessageBot(msgBot.value)

			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	useEffect(() => {
		const socket = openSocket(window.env?.REACT_APP_BACKEND_URL || process.env.REACT_APP_BACKEND_URL);

		socket.on("settings", data => {
			if (data.action === "update") {
				setSettings(prevState => {
					const aux = [...prevState];
					const settingIndex = aux.findIndex(s => s.key === data.setting.key);
					aux[settingIndex].value = data.setting.value;
					return aux;
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleChangeWeekdays = async (event) => {
		const { name, checked } = event.target;

		try {
			await api.put(`/settings/${name}`, {
				value: checked ? "enabled" : "disabled",
			});

			// setState({ ...state, [name]: checked });
			if (name === "monday")
				setMmm(checked)
			if (name === "tuesday")
				setTtt(checked)
			if (name === "wednesday")
				setWww(checked)
			if (name === "thursday")
				setThh(checked)
			if (name === "friday")
				setFff(checked)
			if (name === "saturday")
				setSss(checked)
			if (name === "sunday")
				setSuu(checked)
			if (name === "holidays")
				setHhh(checked)

			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	};
	const handleChangeSetting = async e => {
		const selectedValue = e.target.value;
		const settingKey = e.target.name;

		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	};

	const handleSaveHorario = async e => {
		e.preventDefault();

		let horarioAbertura = e.target.horarioAbertura.value;
		let horarioFechamento = e.target.horarioFechamento.value;
		let aberturaIsValid = true;
		let fechamentoIsValid = true;

		if (horarioAbertura.length > 5 || horarioAbertura.length < 5)
			aberturaIsValid = false;
		else if (horarioAbertura[0] > 2)
			aberturaIsValid = false;
		else if (horarioAbertura[0] === '2' && horarioAbertura[1] > 3)
			aberturaIsValid = false;
		else if (horarioAbertura[2] !== ':')
			aberturaIsValid = false;
		else if (horarioAbertura[3] > 5)
			aberturaIsValid = false;

		if (horarioFechamento.length > 5)
			fechamentoIsValid = false;
		else if (horarioFechamento[0] > 2)
			fechamentoIsValid = false;
		else if (horarioFechamento[0] === '2' && horarioFechamento[1] > 3)
			fechamentoIsValid = false;
		else if (horarioFechamento[2] !== ':')
			fechamentoIsValid = false;
		else if (horarioFechamento[3] > 5)
			fechamentoIsValid = false;


		if (aberturaIsValid && fechamentoIsValid) {
			try {
				await api.put(`/settings/horarioAbertura`, {
					value: horarioAbertura,
				});

				await api.put(`/settings/horarioFechamento`, {
					value: horarioFechamento,
				});
				toast.success(i18n.t("settings.success"));
			} catch (err) {
				toastError(err);
			}
		} else {
			if (!aberturaIsValid)
				toast.error("Horário de abertura inválido");

			if (!fechamentoIsValid)
				toast.error("Horário de fechamento inválido");
		}
	}

	const handleSaveAlmoco = async e => {
		e.preventDefault();

		let horarioAlmoco = e.target.horarioAlmoco.value;
		let horarioFimAlmoco = e.target.horarioFimAlmoco.value;
		let almocoIsValid = true;
		let fimIsValid = true;

		if (horarioAlmoco.length > 5 || horarioAlmoco.length < 5)
			almocoIsValid = false;
		else if (horarioAlmoco[0] > 2)
			almocoIsValid = false;
		else if (horarioAlmoco[0] === '2' && horarioAlmoco[1] > 3)
			almocoIsValid = false;
		else if (horarioAlmoco[2] !== ':')
			almocoIsValid = false;
		else if (horarioAlmoco[3] > 5)
			almocoIsValid = false;

		if (horarioFimAlmoco.length > 5)
			fimIsValid = false;
		else if (horarioFimAlmoco[0] > 2)
			fimIsValid = false;
		else if (horarioFimAlmoco[0] === '2' && horarioFimAlmoco[1] > 3)
			fimIsValid = false;
		else if (horarioFimAlmoco[2] !== ':')
			fimIsValid = false;
		else if (horarioFimAlmoco[3] > 5)
			fimIsValid = false;


		if (almocoIsValid && fimIsValid) {
			try {
				await api.put(`/settings/horarioAlmoco`, {
					value: horarioAlmoco,
				});

				await api.put(`/settings/horarioFimAlmoco`, {
					value: horarioFimAlmoco,
				});
				toast.success(i18n.t("settings.success"));
			} catch (err) {
				toastError(err);
			}
		} else {
			if (!almocoIsValid)
				toast.error("Horário de almoço inválido");

			if (!fimIsValid)
				toast.error("Horário de final de almoço inválido");
		}
	}

	const handleSaveMensagem = async e => {
		e.preventDefault();

		let mensagemHorario = e.target.mensagemHorario.value;

		if (!mensagemHorario) {
			toast.warning("Mensagem alterada para a padrão");
			mensagemHorario = "Neste momento estamos fora do nosso horário de atendimento, deixe sua mensagem que retornaremos o mais breve possível";
		}

		try {
			await api.put(`/settings/msgOutOfTime`, {
				value: mensagemHorario,
			});

			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	}

	const handleSaveMensagemTransfer = async e => {
		e.preventDefault();

		let mensagemTransfer = e.target.mensagemTransfer.value;

		if (!mensagemTransfer) {
			toast.warning("Mensagem alterada para a padrão");
			mensagemTransfer = "Atendimento encaminhado para o(a) atendente *{{user}}* e para a fila *{{queue}}*";
		}

		try {
			await api.put(`/settings/mensagemTransfer`, {
				value: mensagemTransfer,
			});

			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	}

	const handleSaveMessageBot = async e => {
		e.preventDefault();

		let mensagemBot = e.target.messageBot.value;

		if (!mensagemBot) {
			toast.warning("Mensagem alterada para a padrão");
			mensagemBot = "Desculpe, não consegui achar uma resposta para sua pergunta.";
		}

		try {
			await api.put(`/settings/messageBot`, {
				value: mensagemBot,
			});

			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	}

	const getSettingValue = key => {
		const { value } = settings.find(s => s.key === key);
		return value;
	};

	return (
		<div className={classes.root}>
			<Container className={classes.container} maxWidth="sm">
				<Typography variant="body2" gutterBottom>
					{i18n.t("settings.title")} - Tickets
				</Typography>
				{/*<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.userCreation.name")}
					</Typography>
					<Select
						margin="dense"
						variant="outlined"
						native
						id="userCreation-setting"
						name="userCreation"
						value={
							settings && settings.length > 0 && getSettingValue("userCreation")
						}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<option value="enabled">
							{i18n.t("settings.settings.userCreation.options.enabled")}
						</option>
						<option value="disabled">
							{i18n.t("settings.settings.userCreation.options.disabled")}
						</option>
					</Select>
				</Paper>*/}

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Quando desativado, ao receber uma ligação, a plataforma enviará uma mensagem informando ao contato que chamamadas estão desativadas"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">
							{i18n.t("settings.settings.call.name")}
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="call-setting"
							name="call"
							value={
								settings && settings.length > 0 && getSettingValue("call")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Ao ativar essa opção o contato poderá selecionar dentre as opções (00) para que o ticket seja finalizado! "}>
					<Paper className={classes.paper}>

						<Typography variant="body1">
							Permitir que o bot finalize o ticket perante solicitação do contato
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="botCloseTicket-setting"
							name="botCloseTicket"
							value={
								settings && settings.length > 0 && getSettingValue("botCloseTicket")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Quando apertar o botão para finalizar o ticket será criado um protocolo para o mesmo"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">
							Gerar protocolo ao finalizar ticket
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="generateProtocol-setting"
							name="generateProtocol"
							value={
								settings && settings.length > 0 && getSettingValue("generateProtocol")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Ativar respostas automáticas para contatos cadastrados automaticamente (contatos não castrados que enviam mensagem)"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">
							Ativar respostas automáticas para novos contatos
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="activeBot-setting"
							name="activeBot"
							value={
								settings && settings.length > 0 && getSettingValue("activeBot")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				{ user?.id === 1 && (
					<>
						<Typography variant="body2" gutterBottom></Typography>
						<Tooltip title={"Bloqueia o botão de importar contatos no cadastro de contatos"}>
							<Paper className={classes.paper}>

								<Typography variant="body1">
									Bloquear importação de contatos
								</Typography>
								<Select
									margin="dense"
									variant="outlined"
									native
									id="blockImportContacts-setting"
									name="blockImportContacts"
									value={
										settings && settings.length > 0 && getSettingValue("blockImportContacts")
									}
									className={classes.settingOption}
									onChange={handleChangeSetting}
								>
									<option value="enabled">
										{i18n.t("settings.settings.call.options.enabled")}
									</option>
									<option value="disabled">
										{i18n.t("settings.settings.call.options.disabled")}
									</option>
								</Select>
							</Paper>
						</Tooltip>
					</>
				) }

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Quando ativo, os grupos receberão a mensagem de saudação junto com a escolha de fila (caso tenha filas vinculadas), ao finalizar o ticket receberá a mensagem de despedida e protocolo (caso ativo), mensagens de aviso de fora do horário de atendimento, entre outros"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">
							Habilitar interação com grupos
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="greetingProtocolGroup-setting"
							name="greetingProtocolGroup"
							value={
								settings && settings.length > 0 && getSettingValue("greetingProtocolGroup")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Define um usuário e uma fila para os tickets sem movimento"}>
					<Paper className={classes.paper}>
						<Typography variant="body1">
							Definir a fila &nbsp;
							<Select
								margin="dense"
								variant="outlined"
								native
								id="queueCron-setting"
								name="queueCron"
								value={
									settings && settings.length > 0 && getSettingValue("queueCron")
								}
								className={classes.settingOption}
								onChange={handleChangeSetting}
							>
								<option value="disabled">
									Nenhuma
								</option>
								{queues.map(queue=>(
									<option value={queue.id}>
										{queue.name}
									</option>
								))}
							</Select>
							&nbsp;e o usuário&nbsp;
							<Select
								margin="dense"
								variant="outlined"
								native
								id="userCron-setting"
								name="userCron"
								value={
									settings && settings.length > 0 && getSettingValue("userCron")
								}
								className={classes.settingOption}
								onChange={handleChangeSetting}
							>
								<option value="disabled">
									Nenhum
								</option>
								{users.map(user=>(
									<option value={user.id}>
										{user.name}
									</option>
								))}
							</Select>
							&nbsp;após&nbsp;
							<Select
								margin="dense"
								variant="outlined"
								native
								id="intervalCron-setting"
								name="intervalCron"
								value={
									settings && settings.length > 0 && getSettingValue("intervalCron")
								}
								className={classes.settingOption}
								style={{marginTop: "10px"}}
								onChange={handleChangeSetting}
							>
								<option value="2">
									2 minutos
								</option>
								<option value="5">
									5 minutos
								</option>
								<option value="10">
									10 minutos
								</option>
								<option value="15">
									15 minutos
								</option>
								<option value="30">
									30 minutos
								</option>
								<option value="60">
									60 minutos
								</option>
							</Select>
							&nbsp;de inatividade nos tickets pendentes
						</Typography>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Os tickets não serão encerrados no sábado e domingo se estiverem desmarcados nos dias de funcionamento. Tickets serão encerrados na segunda após das 10h00 e na sexta antes das 19h00"}>
					<Paper className={classes.paper}>
						<Typography variant="body1">
							<Select
								margin="dense"
								variant="outlined"
								native
								id="closeCron-setting"
								name="closeCron"
								value={
									settings && settings.length > 0 && getSettingValue("closeCron")
								}
								className={classes.settingOption}
								onChange={handleChangeSetting}
							>
								<option value="enabled">
									Ativar
								</option>
								<option value="disabled">
									Desativar
								</option>
							</Select>
							&nbsp;fechamento automatico de tickets após&nbsp;
							<Select
								margin="dense"
								variant="outlined"
								native
								id="intervalClose-setting"
								name="intervalClose"
								value={
									settings && settings.length > 0 && getSettingValue("intervalClose")
								}
								className={classes.settingOption}
								style={{marginTop: "10px"}}
								onChange={handleChangeSetting}
							>
								<option value="10">
									10 minutos
								</option>
								<option value="15">
									15 minutos
								</option>
								<option value="30">
									30 minutos
								</option>
								<option value="60">
									60 minutos
								</option>
								<option value="180">
									3 horas
								</option>
								<option value="1440">
									24 horas
								</option>
							</Select>
							&nbsp;de inatividade nos tickets abertos
						</Typography>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Dias de funcionamento"}>
					<Paper className={classes.paper} style={{display: "block"}}>
						<Typography variant="body1">
							Dias de funcionamento
						</Typography>
						<FormGroup row>
							<FormControlLabel
								control={<Checkbox checked={mmm} onChange={handleChangeWeekdays} name="monday" />}
								label="Segunda"
							/>
							<FormControlLabel
								control={<Checkbox checked={ttt} onChange={handleChangeWeekdays} name="tuesday" />}
								label="Terça"
							/>
							<FormControlLabel
								control={<Checkbox checked={www} onChange={handleChangeWeekdays} name="wednesday" />}
								label="Quarta"
							/>
							<FormControlLabel
								control={<Checkbox checked={thh} onChange={handleChangeWeekdays} name="thursday" />}
								label="Quinta"
							/>
							<FormControlLabel
								control={<Checkbox checked={fff} onChange={handleChangeWeekdays} name="friday" />}
								label="Sexta"
							/>
							<FormControlLabel
								control={<Checkbox checked={sss} onChange={handleChangeWeekdays} name="saturday" />}
								label="Sábado"
							/>
							<FormControlLabel
								control={<Checkbox checked={suu} onChange={handleChangeWeekdays} name="sunday" />}
								label="Domingo"
							/>
							<FormControlLabel
								control={<Checkbox checked={hhh} onChange={handleChangeWeekdays} name="holidays" />}
								label="Feriados"
							/>
						</FormGroup>
					</Paper>
				</Tooltip>

				<form onSubmit={(e) => handleSaveHorario(e)}>
                    <Paper className={classes.paper}>
                        <TextField
                            id="horarioAbertura"
                            label="Horário de abertura"
							placeholder={"08:00"}
							style={{marginRight: '8px'}}
                            margin="dense"
                            variant="outlined"
							onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            value={ horarioAbertura }
							onChange={ (e)=> { setHorarioAbertura(e.target.value)}}
                        />

						<TextField
                            id="horarioFechamento"
                            label="Horário de Fechamento"
							placeholder={"18:00"}
							style={{marginRight: '8px'}}
                            margin="dense"
                            variant="outlined"
							onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
							value={ horarioFechamento }
							onChange={ (e)=> { setHorarioFechamento(e.target.value)}}
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            className={classes.btnWrapper}
                        >
                            Salvar
                        </Button>
                    </Paper>
				</form>

				<form onSubmit={(e) => handleSaveAlmoco(e)}>
                    <Paper className={classes.paper}>
                        <TextField
                            id="horarioAlmoco"
                            label="Horário de almoço"
							placeholder={"12:00"}
							style={{marginRight: '8px'}}
                            margin="dense"
                            variant="outlined"
							onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                            value={ horarioAlmoco }
							onChange={ (e)=> { setHorarioAlmoco(e.target.value)}}
                        />

						<TextField
                            id="horarioFimAlmoco"
                            label="Fim do almoço"
							placeholder={"13:00"}
							style={{marginRight: '8px'}}
                            margin="dense"
                            variant="outlined"
							onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
							value={ horarioFimAlmoco }
							onChange={ (e)=> { setHorarioFimAlmoco(e.target.value)}}
                        />

                        <Button
                            type="submit"
                            variant="contained"
                            className={classes.btnWrapper}
                        >
                            Salvar
                        </Button>
                    </Paper>
				</form>

				<Paper className={classes.paper}>
					<TextField
						id="api-token-setting"
						readOnly
						label="Token Api"
						margin="dense"
						variant="outlined"
						disabled
						fullWidth
						value={settings && settings.length > 0 && getSettingValue("userApiToken")}
					/>
				</Paper>

				<Paper className={classes.paper}>
					<TextField
						id="api-url-setting"
						readOnly
						label="URL Api"
						margin="dense"
						variant="outlined"
						disabled
						fullWidth
						value={window.env?.REACT_APP_BACKEND_URL || process.env.REACT_APP_BACKEND_URL}
					/>
				</Paper>
			</Container>

			<Container className={classes.container} maxWidth="sm">
				<Typography variant="body2" gutterBottom>
					{i18n.t("settings.title")} - Mensagens
				</Typography>

				<Tooltip title={"Se ativo, a plataforma irá ignorar todas as mensagens de grupos"}>
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{i18n.t("settings.settings.CheckMsgIsGroup.name")}
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="CheckMsgIsGroup-setting"
							name="CheckMsgIsGroup"
							value={
								settings && settings.length > 0 && getSettingValue("CheckMsgIsGroup")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.CheckMsgIsGroup.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.CheckMsgIsGroup.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Tooltip title={"Se ativo, a plataforma irá ignorar todas as mensagens enviadas pelo dispositivo"}>
					<Paper className={classes.paper}>
						<Typography variant="body1">
							{"Ignorar mensagens enviadas pelo dispositivo"}
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="CheckMsgFromCell-setting"
							name="CheckMsgFromCell"
							value={
								settings && settings.length > 0 && getSettingValue("CheckMsgFromCell")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.CheckMsgIsGroup.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.CheckMsgIsGroup.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={i18n.t("settings.settings.timeCreateNewTicket.note")}>
					<Paper className={classes.paper} elevation={3}>
						<Typography variant="body1">
							{i18n.t("settings.settings.timeCreateNewTicket.name")}
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="timeCreateNewTicket-setting"
							name="timeCreateNewTicket"
							value={
								settings && settings.length > 0 && getSettingValue("timeCreateNewTicket")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="0">
								Sempre enviar
							</option>
							<option value="5">
								5 Minutos
							</option>
							<option value="10">
								10 Minutos
							</option>
							<option value="30">
								30 Minutos
							</option>
							<option value="60">
								1 Hora
							</option>
							<option value="120">
								2 Horas
							</option>
							<option value="300">
								5 Horas
							</option>
							<option value="720">
								12 Horas
							</option>
							<option value="1440">
								24 Horas
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title="Selecione o tempo que será necessário para o atendimento ser finalizado automáticamente após a solicitação de avaliação, caso não haja resposta">
					<Paper className={classes.paper} elevation={3}>
						<Typography variant="body1">
							Tempo para expirar N.P.S.
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="closeNpsInterval-setting"
							name="closeNpsInterval"
							value={
								settings && settings.length > 0 && getSettingValue("closeNpsInterval")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="1">
								1 Minuto
							</option>
							<option value="5">
								5 Minutos
							</option>
							<option value="10">
								10 Minutos
							</option>
							<option value="30">
								30 Minutos
							</option>
							<option value="60">
								1 Hora
							</option>
							<option value="120">
								2 Horas
							</option>
							<option value="300">
								5 Horas
							</option>
							<option value="720">
								12 Horas
							</option>
							<option value="1440">
								24 Horas
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Envia uma notificação sobre mensagens novas nos tickets que não possuem fila definida"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">
							Notificar mensagens de tickets sem fila
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="notificateAllTickets-setting"
							name="notificateAllTickets"
							value={
								settings && settings.length > 0 && getSettingValue("notificateAllTickets")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Quando ativo o sistema enviará uma notificação de nova mensagem para todos os usuários que conseguem ver o grupo"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">
							Notificar todos os usuários sobre mensagens novas em grupos
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="notifyGroupToAllUsers-setting"
							name="notifyGroupToAllUsers"
							value={
								settings && settings.length > 0 && getSettingValue("notifyGroupToAllUsers")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Enviar a mensagem de saudação para os contatos, mesmo quando não tiver fila vinculada na conexão"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">
							Enviar mensagem de saudação sem filas
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="sendGreetingMessage-setting"
							name="sendGreetingMessage"
							value={
								settings && settings.length > 0 && getSettingValue("sendGreetingMessage")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Enviar a mensagem de saudação, e a mensagem de fora do expediente para os contatos apenas uma vez"}>
					<Paper className={classes.paper}>
						<Typography variant="body1">
							Enviar saudação e alerta de expediente apenas uma vez
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="sendOnlyOnce-setting"
							name="sendOnlyOnce"
							value={
								settings && settings.length > 0 && getSettingValue("sendOnlyOnce")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"O sistema enviará a mensagem de saudação mesmo quando o ticket já tiver uma fila atribuída"}>
					<Paper className={classes.paper}>
						<Typography variant="body1">
							Enviar saudação nos atendimentos pendentes com fila atribuída
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="greetingWithQueue-setting"
							name="greetingWithQueue"
							value={
								settings && settings.length > 0 && getSettingValue("greetingWithQueue")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<form onSubmit={(e) => handleSaveMensagem(e)}>
					<Paper className={classes.paper}>
						<TextField
							id="mensagemHorario"
							label="Mensagem fora do horario"
							placeholder={"Digite aqui sua mensagem"}
							style={{marginRight: '8px'}}
							margin="dense"
							variant="outlined"
							onKeyPress={(e) => {
								e.key === 'Enter' && e.preventDefault();
							}}
							value={mensagemHorario}
							fullWidth
							onChange={(e) => {
								setMensagemHorario(e.target.value)
							}}
						/>

						<Button
							type="submit"
							variant="contained"
							className={classes.btnWrapper}
						>
							Salvar
						</Button>
					</Paper>
				</form>

				<form onSubmit={(e) => handleSaveMensagemTransfer(e)}>
					<Tooltip title={"Use {{user}} para exibir o nome do usuário transferido, use {{queue}} para exibir o nome da fila transferida"}>
						<Paper className={classes.paper}>
							<TextField
								id="mensagemTransfer"
								label="Mensagem de transferência"
								placeholder={"Digite aqui sua mensagem"}
								style={{marginRight: '8px'}}
								margin="dense"
								variant="outlined"
								onKeyPress={(e) => {
									e.key === 'Enter' && e.preventDefault();
								}}
								value={mensagemTransfer}
								fullWidth
								onChange={(e) => {
									setMensagemTransfer(e.target.value)
								}}
							/>

							<Button
								type="submit"
								variant="contained"
								className={classes.btnWrapper}
							>
								Salvar
							</Button>
						</Paper>
					</Tooltip>
				</form>

				<form onSubmit={(e) => handleSaveMessageBot(e)}>
					<Tooltip title={"Mensagem enviada quando o bot não tem uma resposta para a pergunta enviada pelo contato"}>
						<Paper className={classes.paper}>
							<TextField
								id="messageBot"
								label="Mensagem sem resposta (BOT)"
								placeholder={"Digite aqui sua mensagem"}
								style={{marginRight: '8px'}}
								margin="dense"
								variant="outlined"
								onKeyPress={(e) => {
									e.key === 'Enter' && e.preventDefault();
								}}
								value={messageBot}
								fullWidth
								onChange={(e) => {
									setMessageBot(e.target.value)
								}}
							/>

							<Button
								type="submit"
								variant="contained"
								className={classes.btnWrapper}
							>
								Salvar
							</Button>
						</Paper>
					</Tooltip>
				</form>
			</Container>

			<Container className={classes.container} maxWidth="sm">
				<Typography variant="body2" gutterBottom>
					{i18n.t("settings.title")} - Visualização
				</Typography>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Quando desativado, o sistema irá listar todas as mensagem que já foram trocadas com o contato, quando ativo listará apenas as mensagens do ticket atual"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">
							Recuperar somente mensagem do ticket atual na conversa
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="onlyTicketMsg-setting"
							name="onlyTicketMsg"
							value={
								settings && settings.length > 0 && getSettingValue("onlyTicketMsg")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Ativar essa opção disponibilizará um botão (\"Ver todos\") para os usuario não administradores verem todos os tickets das filas em que pertencem"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">
							Visualização completa da fila para usuários
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="userShowAll-setting"
							name="userShowAll"
							value={
								settings && settings.length > 0 && getSettingValue("userShowAll")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Mostrar os tickets sem fila atribuída, somente para os usuários com o perfil de Administrador"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">
							Mostrar tickets sem fila apenas para Administradores
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="onlyAdminSeeEmptyQueue-setting"
							name="onlyAdminSeeEmptyQueue"
							value={
								settings && settings.length > 0 && getSettingValue("onlyAdminSeeEmptyQueue")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Permitir que o colaboradores com perfil Usuário visualize todos os atendimentos da fila dele nos encerrados"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">
							Permitir que usuario vejam tickets encerrados por fila
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="userClosedQueues-setting"
							name="userClosedQueues"
							value={
								settings && settings.length > 0 && getSettingValue("userClosedQueues")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Quando ativo o sistema limitará a visualização dos atendimento conforme o perfil do usuário logado (Administrador -> Gerente -> Usuário)"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">
							Habilitar sistema de visualização hierárquico
						</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="hierarchy-setting"
							name="hierarchy"
							value={
								settings && settings.length > 0 && getSettingValue("hierarchy")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Separar atendimentos de grupos por usuários"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">Grupos por usuário</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="groupPerUser-setting"
							name="groupPerUser"
							value={
								settings && settings.length > 0 && getSettingValue("groupPerUser")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Separar histórico de mensagens por conexão. Usuários com perfil Administrador ainda verão o histórico completo"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">Histórico por conexão</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="historyByConnection-setting"
							name="historyByConnection"
							value={
								settings && settings.length > 0 && getSettingValue("historyByConnection")
							}
							className={classes.settingOption}
							onChange={handleChangeSetting}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Separar histórico de mensagens por fila. Usuários com perfil Administrador ainda verão o histórico completo"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">Histórico por fila</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="historyByQueue-setting"
							name="historyByQueue"
							value={
								settings && settings.length > 0 && getSettingValue("historyByQueue")
							}
							className={classes.settingOption}
							onChange={(e) => {
								if (e.target.value === "enabled" && getSettingValue("historyByUser") === "enabled") {
									const evt = { target: { value: "disabled", name: "historyByUser" } };
									handleChangeSetting(evt);
								}
								handleChangeSetting(e)
							}}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>

				<Typography variant="body2" gutterBottom></Typography>
				<Tooltip title={"Separar histórico de mensagens por usuário. Usuários com perfil Administrador ainda verão o histórico completo"}>
					<Paper className={classes.paper}>

						<Typography variant="body1">Histórico por usuário</Typography>
						<Select
							margin="dense"
							variant="outlined"
							native
							id="historyByUser-setting"
							name="historyByUser"
							value={
								settings && settings.length > 0 && getSettingValue("historyByUser")
							}
							className={classes.settingOption}
							onChange={(e) => {
								if (e.target.value === "enabled" && getSettingValue("historyByQueue") === "enabled") {
									const evt = { target: { value: "disabled", name: "historyByQueue" } };
									handleChangeSetting(evt);
								}
 								handleChangeSetting(e)
							}}
						>
							<option value="enabled">
								{i18n.t("settings.settings.call.options.enabled")}
							</option>
							<option value="disabled">
								{i18n.t("settings.settings.call.options.disabled")}
							</option>
						</Select>
					</Paper>
				</Tooltip>
			</Container>
		</div>
	);
};

export default Settings;
