import React, { useState, useEffect } from "react";


import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import {format, parseISO} from "date-fns";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import {i18n} from "../../translate/i18n";
import TableBody from "@material-ui/core/TableBody";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import TableRowSkeleton from "../TableRowSkeleton";
import Table from "@material-ui/core/Table";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
}));

const AgendamentoLogModal = ({ open, onClose, agendamentoId }) => {
	const classes = useStyles();

	const [agendamentos, setAgendamentos] = useState([]);
	const [loading, setLoading] = useState(false);
	// const weekdays = { 'Monday': 'Segunda-Feira', 'Tuesday': 'Terça-Feira', 'Wednesday': 'Quarta-Feira', 'Thurday': 'Quinta-Feira', 'Friday': 'Sexta-Feira', 'Saturday': 'Sábado', 'Sunday': 'Domingo'};

	useEffect(() => {
		setLoading(true);
		(async () => {
			if (!agendamentoId) return;
			try {
				const { data } = await api.get(`/agendamento/logs/${agendamentoId}`);
				setAgendamentos(data);

				setLoading(false);
				// setAgendamentos(data);
			} catch (err) {
				setLoading(false);
				toastError(err);
			}
			return () => {
				setAgendamentos([]);
			};
		})();
	}, [agendamentoId, open])

	const handleClose = () => {
		onClose();
		setAgendamentos([]);
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{ ` Agendamento ${agendamentoId}`}
				</DialogTitle>
				<DialogContent dividers>
					<Table size="small">
						<TableHead>
							<TableRow>
								<TableCell padding="checkbox" />
								<TableCell>Contato</TableCell>
								<TableCell align="center">Enviado</TableCell>
								<TableCell align="center">
									Data de enviado
								</TableCell>
								<TableCell align="center">
									{i18n.t("contacts.table.actions")}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							<>
								{agendamentos.length > 0 && agendamentos.map((agendamento) => (
									<TableRow key={agendamento.id}>
										<TableCell style={{ paddingRight: 0 }}>
											{<Avatar src={agendamento.contact?.profilePicUrl} />}
										</TableCell>
										<TableCell>{agendamento.contact?.name}</TableCell>
										<TableCell align="center">{agendamento.wasSend ? "Sim" : "Não"}</TableCell>
										<TableCell align="center">{format(parseISO(agendamento.createdAt), "dd/MM/yy HH:mm:ss")}</TableCell>
										<TableCell align="center">
											<IconButton
												size="small"
												title={agendamento.body}
												// onClick={() => handleOpenTicket(agendamento.ticketId)}
											>
												<WhatsAppIcon />
											</IconButton>
										</TableCell>
									</TableRow>
								))}
								{loading && <TableRowSkeleton avatar columns={3} />}
							</>
						</TableBody>
					</Table>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						variant="outlined"
					>
						Fechar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default AgendamentoLogModal;
