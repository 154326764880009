import React, {useState, useEffect, useRef} from "react";
import {
	ResponsiveContainer,
	PieChart,
	Pie,
	Sector
} from "recharts";

import Title from "./Title";
import api from "../../services/api";
import {toast} from "react-toastify";
import {useTheme} from "@material-ui/core/styles";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";

const ChartClientes = ({firstDate, lastDate, queueId, userId}) => {

    const theme = useTheme();
	const [state, setState] = useState({activeIndex: 0});
	const [dataChart, setDataChart] = useState([{ name: 'Sem atendimentos', value: 0 }]);

	const fd = useRef(firstDate.toISOString());
	const ld = useRef(lastDate.toISOString());

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async() => {
                try {
                    let tickets = [];
                    const { data } = await api.get("/ticketCliente", {
						params: {
							firstDate: fd.current,
							lastDate: ld.current,
							queueId,
							userId
						},
					})

					if (data && data.length > 0) {
						for (let ticket of data) {
							tickets.push({name: ticket.contact?.name, value: ticket.Total})
						}

						setDataChart(tickets);
					} else {
						setDataChart([{ name: 'Sem atendimentos', value: 1 }])
					}
                } catch (err) {
                    toast.error(err)
                }
            }

            fetchTickets()
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [queueId, userId]);

	const renderActiveShape = (props) => {
		const RADIAN = Math.PI / 180;
		const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
		const sin = Math.sin(-RADIAN * midAngle);
		const cos = Math.cos(-RADIAN * midAngle);
		const sx = cx + (outerRadius + 10) * cos;
		const sy = cy + (outerRadius + 10) * sin;
		const mx = cx + (outerRadius + 30) * cos;
		const my = cy + (outerRadius + 30) * sin;
		const ex = mx + (cos >= 0 ? 1 : -1) * 22;
		const ey = my;
		const textAnchor = cos >= 0 ? 'start' : 'end';

		return (
			<g>
				<text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
					{payload.name}
				</text>
				<Sector
					cx={cx}
					cy={cy}
					innerRadius={innerRadius} //80
					outerRadius={outerRadius} //100
					startAngle={startAngle}
					endAngle={endAngle}
					fill={fill}
				/>
				<Sector
					cx={cx}
					cy={cy}
					startAngle={startAngle}
					endAngle={endAngle}
					innerRadius={outerRadius + 6} //106
					outerRadius={outerRadius + 10} //110
					fill={fill}
				/>
				<path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
				<circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
				<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Tickets: ${value}`}</text>
				<text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
					{`(${(percent * 100).toFixed(2)}%)`}
				</text>
			</g>
		);
	};

	return (
		<React.Fragment>
			<Title>
				<span>Tickets por Cliente</span>
				<span title="Gráfico afetado pelo filtro de data" style={{float: "right"}}><CalendarTodayIcon /></span>
			</Title>
			<ResponsiveContainer width="100%" height="100%">
				<PieChart width={400} height={400}>
					<Pie
						activeIndex={state.activeIndex}
						activeShape={renderActiveShape}
						data={dataChart}
						cx="50%"
						cy="50%"
						innerRadius={80}
						outerRadius={110}
						fill={theme.palette.primary.main}
						dataKey="value"
						onMouseEnter={(_, index) => {
							setState({
								activeIndex: index,
							});
						}}
					/>
				</PieChart>
			</ResponsiveContainer>
		</React.Fragment>
	);
};

export default ChartClientes;
