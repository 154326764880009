import React, {useContext, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Draggable from 'react-draggable';
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import api from "../../services/api";
import {toast} from "react-toastify";
import Typography from "@material-ui/core/Typography";
import {AuthContext} from "../../context/Auth/AuthContext";

function PaperComponent(props) {
	return (
		<Draggable defaultPosition={{x: 0, y: -200}} handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	);
}

export default function SearchMessageModal({open, onClose, ticket}) {
	const [messageToSearch, setMessageToSearch] = useState("");
	const [lastSearch, setLastSearch] = useState(null);
	const [loopMessages, setLoopMessages] = useState(0);
	const [messages, setMessages] = useState([]);
	const { user } = useContext(AuthContext);

	const scrollTo = (id) => {
		const element = document.getElementById(id);
		if (element) {
			element.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
			const defaultBorder = element.style.border;

			const intervalId = setInterval(() => {
				if (element.style.border === defaultBorder)
					element.style.border = "2px dashed #FF4242";
				else
					element.style.border = defaultBorder;
			},500)

			setTimeout(() => {
				clearInterval(intervalId);
			}, 5000);
		}
	}

	const searchMessage = async (e) => {
		if (messageToSearch && lastSearch !== messageToSearch) {
			const {data} = await api.get(`/messages/search/${ticket.id}`, {
				params: {
					messageToSearch,
					userId: user.id
				}
			});

			if (data.messages?.length > 0) {
				setLastSearch(messageToSearch);
				setLoopMessages(1);
				setMessages(data.messages);
				scrollTo(data.messages[0]?.id)
			}

			if (data.messages?.length <= 0) {
				toast.warn("Nenhuma mensagem foi encontrada")
			}

			return;
		}

		if (lastSearch === messageToSearch) {
			await setLoopMessages(loopMessages + 1);
			if (messages.length === loopMessages) {
				setLoopMessages(0);
			}

			const currentMessage = messages[loopMessages];
			if (currentMessage) {
				scrollTo(currentMessage?.id)
			}
		}
	}

	const handleClose = () => {
		setMessageToSearch("");
		setLastSearch(null);
		setLoopMessages(0);
		setMessages([]);
		onClose();
	}

	return (
		<Dialog
			open={open}
			onClose={handleClose}
			PaperComponent={PaperComponent}
			aria-labelledby="draggable-dialog-title"
		>
			<DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
				Pesquisar mensagem
			</DialogTitle>
			<DialogContent dividers>
				<TextField
					label="Mensagem"
					variant="outlined"
					value={messageToSearch}
					onChange={values => {setMessageToSearch(values.target.value)}}
				/>
				<Typography variant="subtitle1">{messages?.length > 0 ? `${loopMessages}/${messages.length} mensagens encontradas` : ""}</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={handleClose}
					color="default"
				>
					Fechar
				</Button>
				<Button
					variant="contained"
					onClick={(e) => {
						searchMessage(e);
					}}
					color="primary"
				>
					Pesquisar
				</Button>
			</DialogActions>
		</Dialog>
	);
}
