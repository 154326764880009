import React, { useState, useEffect } from "react";

import { Formik, Form, Field } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
}));

const EnvioMassaInfoModal = ({ open, onClose, envioId }) => {
	const classes = useStyles();

	const initialState = {
		mensagem: "",
		contactsIds: "",
		tagsIds: "",
		allContacts: false
	};

	const [envio, setEnvio] = useState(initialState);
	const [contatos, setContatos] = useState("");

	useEffect(() => {
		(async () => {
			if (!envioId) return;
			try {
				const { data } = await api.get(`/envioMassa/${envioId}`);
				setEnvio(prevState => {
					return { ...prevState, ...data };
				});

				if (!data.allContacts && (data.contactsIds || data.tagsIds)) {
					if (data.contactsIds) {
						let cont = data.contactsIds.split(',');
						let contAr = [];

						for (const c of cont) {
							const {data} = await api.get(`/contacts/${c}`);
							contAr.push(data.name);
						}
						setContatos(contAr.join(', '));
					} else if (data.tagsIds) {
						let tags = data.tagsIds.split(",");
						let contAr = [];

						for (const tag of tags) {
							const {data} = await api.get(`/tag/${tag}`);

							if (data.contacts) {
								for (const conts of data.contacts){
									contAr.push(conts.name);
								}
							}
						}
						setContatos(contAr.join(', '));
					}

				}
			} catch (err) {
				toastError(err);
			}
			return () => {
				setEnvio({
					mensagem: "",
					contactsIds: "",
					tagsIds: "",
					allContacts: false
				});
			};
		})();
	}, [envioId, open]);

	const handleClose = () => {
		onClose();
		setEnvio(initialState);
		setContatos("");
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{ ` Envio ${envioId}`}
				</DialogTitle>
				<Formik
					initialValues={envio}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers style={{ width: "500px"}}>
								<Field
									as={TextField}
									label="Mensagem"
									type="greetingMessage"
									multiline
									minRows={5}
									fullWidth
									value={envio?.mensagem}
									error={
										touched.mensagem && Boolean(errors.mensagem)
									}
									helperText={
										touched.mensagem && errors.mensagem
									}
									variant="outlined"
									margin="dense"
								/>

								{/*<TagSelect
									selectedTagIds={selectedTagIds}
									onChange={(values) => {
										setSelectedTagIds(values);
										setSelectedContactIds([]);
									}}
								/>*/}

								<Field
									as={TextField}
									label="Contatos enviados"
									type="greetingMessage"
									multiline
									minRows={5}
									fullWidth
									value={envio.allContacts ? "Todos os contatos" : contatos}
									error={
										touched.mensagem && Boolean(errors.mensagem)
									}
									helperText={
										touched.mensagem && errors.mensagem
									}
									variant="outlined"
									margin="dense"
								/>

							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									Fechar
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default EnvioMassaInfoModal;
