import React, {useCallback, useContext, useState} from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useDropzone } from "react-dropzone";

import { i18n } from "../../translate/i18n";
import {makeStyles} from "@material-ui/core/styles";
import api from "../../services/api";
import {toast} from "react-toastify";
import {AuthContext} from "../../context/Auth/AuthContext";
import ButtonWithSpinner from "../ButtonWithSpinner";

const useStyles = makeStyles(theme => ({
	container: {
		width: 1200,
		maxWidth: "90%",
		margin: "0rem auto"
	},
	dropzone: {
		width: "100%",
		height: "10rem",
		margin: "2rem 0",
		background: "#eee",
		border: "2px dashed #aaa",
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		color: "#333",
		fontWeight: 500
	},
	preview: {
		display: "grid",
		// gridTemplateColumns: "repeat(auto-fill, minmax(10rem, 1fr))",
		width: "100%",
		gridGap: "1rem"
	},
	item: {
		listStyleImage: `url(./public/default-profile.png)`
	}
}));

const ImportFileUser = ({ title, children, open, onClose, onConfirm }) => {
	const classes = useStyles();
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [loading, setLoading] = useState(false);
	const { user } = useContext(AuthContext);

	const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
		setSelectedFiles(acceptedFiles);
		/*acceptedFiles.forEach((file) => {
			setSelectedFiles((prevState) => [...prevState, file]);
		});*/
	}, []);

	const {
		getRootProps,
		getInputProps,
		isDragActive,
		// isDragAccept,
		// isDragReject,
	} = useDropzone({
		onDrop,
		multiple: false,
		accept: {
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
			"application/vnd.ms-excel": [".xls"]
		}
	});

	// Add this
	const onUpload = async () => {
		setLoading(true);
		const formData = new FormData();
		if (selectedFiles.length > 0) {
			selectedFiles.forEach((image) => {
				formData.append("medias", image);
			});
			try {
				await api.post("/users/import/" + user.id, formData);
				toast.success("Usuários importados com sucesso!");
				setTimeout(() => {
					window.location.reload();
				}, 1500);
				setLoading(false);
			} catch (error) {
				setLoading(false);
				toast.error("Ocorreu um erro na importação: " + error);
			}
		}

		if (selectedFiles.length === 0) {
			toast.warning("Insira um arquivo para importar");
			setLoading(false);
		}
	};

	return (
		<Dialog
			open={open}
			onClose={() => onClose(false)}
			aria-labelledby="confirm-dialog"
		>
			<DialogTitle id="confirm-dialog">{title}</DialogTitle>
			<DialogContent dividers>

				<div className={classes.container}>
					<div className={classes.dropzone} {...getRootProps()}>
						<input {...getInputProps()} />
						{isDragActive ? (
							<p>Solte o arquivo aqui ...</p>
						) : (
							<p>Arraste e solte arquivos aqui, ou clique para selecionar</p>
						)}
					</div>
					<div className={classes.preview}>
						{selectedFiles.length > 0 && (
							<ul className={classes.item}>
								{selectedFiles.map((file, index) => (
									<li key={new Date().getMilliseconds()}>{file.path}</li>
								))}
							</ul>
						)}
					</div>
				</div>

			</DialogContent>
			<DialogActions>
				<Button
					variant="contained"
					onClick={() => onClose(false)}
					color="default"
				>
					{i18n.t("confirmationModal.buttons.cancel")}
				</Button>
				<ButtonWithSpinner
					variant="contained"
					loading={loading}
					onClick={() => {
						// onClose(false);
						// onConfirm();
						onUpload();
					}}
					color="secondary"
				>
					{i18n.t("confirmationModal.buttons.confirm")}
				</ButtonWithSpinner>
			</DialogActions>
		</Dialog>
	);
};

export default ImportFileUser;
