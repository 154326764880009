import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import api from "../../services/api";
import toastError from "../../errors/toastError";
// import QueueSelect from "../QueueSelect";
import {InputLabel, MenuItem, Select} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import useQueues from "../../hooks/useQueues";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
}));

const PerguntaSchema = Yup.object().shape({
	pergunta: Yup.string()
		.min(1, "Too Short!")
		.required("Required"),
	resposta: Yup.string()
		.min(1, "Too Short!")
		.required("Required"),
	queueId: Yup.string(),
	openTicket: Yup.boolean(),
});

const PerguntaModal = ({ open, onClose, perguntaId }) => {
	const classes = useStyles();

	const initialState = {
		pergunta: "",
		resposta: "",
		queueId: "",
		openTicket: false
	};

	const [pergunta, setPergunta] = useState(initialState);
	const [queues, setQueues] = useState([]);
	const { findAll: findAllQueues } = useQueues();

	useEffect(() => {
		const loadQueues = async () => {
			const list = await findAllQueues();
			setQueues(list);
		}
		loadQueues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		(async () => {
			if (!perguntaId) return;
			try {
				const { data } = await api.get(`/pergunta/${perguntaId}`);
				if (!data.queueId)
					data.queueId = "";
				setPergunta(prevState => {
					return { ...prevState, ...data };
				});
			} catch (err) {
				toastError(err);
			}
		})();

		return () => {
			setPergunta({
				pergunta: "",
				resposta: "",
				queueId: "",
				openTicket: false
			});
		};
	}, [perguntaId, open]);

	const handleClose = () => {
		onClose();
		setPergunta(initialState);
	};

	const handleSavePergunta = async values => {
		try {
			if (perguntaId) {
				await api.put(`/pergunta/${perguntaId}`, values);
			} else {
				await api.post("/pergunta", values);
			}
			toast.success("Pergunta saved successfully");
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{perguntaId
						? `Editar`
						: `Adicionar nova pergunta e resposta`}
				</DialogTitle>
				<Formik
					initialValues={pergunta}
					enableReinitialize={true}
					validationSchema={PerguntaSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSavePergunta(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label="Pergunta"
									type="greetingMessage"
									multiline
									minRows={5}
									fullWidth
									name="pergunta"
									error={
										touched.pergunta && Boolean(errors.pergunta)
									}
									helperText={
										touched.pergunta && errors.pergunta
									}
									variant="outlined"
									margin="dense"
								/>
								<Field
									as={TextField}
									label="Resposta"
									type="greetingMessage"
									multiline
									minRows={5}
									fullWidth
									name="resposta"
									error={
										touched.resposta && Boolean(errors.resposta)
									}
									helperText={
										touched.resposta && errors.resposta
									}
									variant="outlined"
									margin="dense"
								/>
								<FormControl
									variant="outlined"
									className={classes.maxWidth}
									margin="dense"
									fullWidth>
									<>
										<InputLabel id="queue-selection-input-label">Fila</InputLabel>

										<Field
											as={Select}
											label="Fila"
											name="queueId"
											labelId="queue-selection-label"
											id="queue-selection"
											fullWidth>

											<MenuItem value={''}>&nbsp;</MenuItem>
											{queues.map((queue) => (
												<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
											))}
										</Field>
									</>
								</FormControl>
								<FormGroup style={{marginLeft: '5px'}}>
									<FormControlLabel
										control={
											<Field
												as={Switch}
												color="primary"
												name="openTicket"
												checked={values.openTicket}
											/>
										}
										label={"Abrir ticket ao selecionar pergunta"}
									/>
								</FormGroup>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									Cancelar
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{perguntaId
										? `Salvar`
										: `Adicionar`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default PerguntaModal;
