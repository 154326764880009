import React, { useState, useEffect } from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	extraAttr: {
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
}));

const ProtocoloSchema = Yup.object().shape({
	mensagem: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!"),
	protocolo: Yup.string(),
	name: Yup.string(),
	number: Yup.string()
});

const ProtocoloModal = ({ open, onClose, protocoloId, initialValues, onSave }) => {
	const classes = useStyles();
	// const isMounted = useRef(true);

	const initialState = {
		mensagem: "",
		protocolo: "",
		name: "",
		number: "",
	};

	const [protocolo, setProtocolo] = useState(initialState);

	/*useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);*/

	useEffect(() => {
		const fetchProtocolo = async () => {
			if (initialValues) {
				setProtocolo(prevState => {
					return { ...prevState, ...initialValues };
				});
			}

			if (!protocoloId) return;

			try {
				const { data } = await api.get(`/protocolos/${protocoloId}`);
				let proto = {
					mensagem: data.mensagem,
					protocolo: data.protocolo,
					name: data.contact.name,
					number: data.contact.number
				}
				setProtocolo(proto);

				/*if (isMounted.current) {
					setProtocolo(data);
					const contactTagsId = data.tags?.map(tag => tag.id);
					setSelectedTagIds(contactTagsId);
				}*/
			} catch (err) {
				toastError(err);
			}
		};

		fetchProtocolo();
	}, [protocoloId, open, initialValues]);

	const handleClose = () => {
		onClose();
		setProtocolo(initialState);
	};

	const handleSaveProtocolo = async values => {
		try {
			let protocoloData = {...values}
			if (protocoloId) {
				await api.put(`/protocolos/${protocoloId}`, protocoloData);
				handleClose();
			} /*else {
				const { data } = await api.post("/contacts", protocoloData);
				if (onSave) {
					onSave(data);
				}
				handleClose();
			}*/
			toast.success("Protocolo salvo com sucesso!");
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="lg" scroll="paper">
				<DialogTitle id="form-dialog-title">
					{"Editar protocolo"}
				</DialogTitle>
				<Formik
					initialValues={protocolo}
					enableReinitialize={true}
					validationSchema={ProtocoloSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveProtocolo(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ values, errors, touched, isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<Field
									as={TextField}
									label={i18n.t("contactModal.form.name")}
									name="name"
									variant="outlined"
									margin="dense"
									className={classes.textField}
									disabled
								/>
								<Field
									as={TextField}
									label={i18n.t("contactModal.form.number")}
									name="number"
									variant="outlined"
									margin="dense"
									disabled
								/>
								<div>
									<Field
										as={TextField}
										label={"Protocolo"}
										name="protocolo"
										error={touched.name && Boolean(errors.name)}
										helperText={touched.name && errors.name}
										variant="outlined"
										margin="dense"
										className={classes.textField}
										fullWidth
										disabled
									/>
								</div>
								<div>
									<Field
										as={TextField}
										label={"Detalhes"}
										type="greetingMessage"
										multiline
										minRows={5}
										fullWidth
										autoFocus
										name="mensagem"
										variant="outlined"
										margin="dense"
									/>
								</div>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("contactModal.buttons.cancel")}
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{"Salvar"}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default ProtocoloModal;
