import React, {useState, useEffect, useRef} from "react";

import api from "../../services/api";
import {toast} from "react-toastify";
import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import toastError from "../../errors/toastError";
import {isSameDay} from "date-fns";
import PersonIcon from '@material-ui/icons/Person';
import QueueIcon from '@material-ui/icons/Queue';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EventIcon from '@material-ui/icons/Event';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ForumIcon from '@material-ui/icons/Forum';
import MessageIcon from '@material-ui/icons/Message';
import TimerIcon from '@material-ui/icons/Timer';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';

const isMobile = (typeof window.orientation) === "number";

const useStyles = makeStyles(theme => ({
    container: {
        // paddingTop: theme.spacing(4),
        // paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 240,
    },
    fixedHeightPaperMaior: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 380,
    },
    customFixedHeightPaper: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "hidden",
        flexDirection: "column",
        height: 120,
        backgroundColor: "#0078a8",
        color: "white",
        position: 'relative', // Adiciona posição relativa
    },
    iconStyle: {
        position: 'absolute', // Torna o ícone posicionado dentro do papel
        top: '0.5em', // Ajuste para melhor posição vertical
        right: '0.5em', // Ajuste para melhor posição horizontal
        fontSize: isMobile ? "1.3em" : '4.5em', // Tamanho do ícone
    },
    customFixedHeightPaperLg: {
        padding: theme.spacing(2),
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: "100%",
    },
    groupGridTitle: {
        fontStyle: "italic",
        color: "#6a6a6a"
    },
    grid: {
        width: "100%"
    },
    hidden: {
        display: "none"
    }
}));

const Stats = ({firstDate, lastDate, queueId, userId}) => {

    const classes = useStyles();

    const [stats, setStats] = useState({
        user: 0,
        userTotal: 0,
        queue: 0,
        whatsapp: 0,
        whatsappTotal: 0,
        group: 0,
        agendamento: 0,
        tags: 0,
        tickets: 0,
        messages: 0,
        contacts: 0,
        diffs: ""
    });
    const [timeClosed, setTimeClosed] = useState(null);
    const [timeWorking, setTimeWorking] = useState(null);
    const [lunchTime, setLunchTime] = useState(null);
    const fd = useRef(firstDate.toISOString());
    const ld = useRef(lastDate.toISOString());
    const [saturday, setSaturday] = useState(null);
    const [sunday, setSunday] = useState(null);
    const [fechamento, setFechamento] = useState(null);
    const [abertura, setAbertura] = useState(null);
    const [startLunch, setStartLunch] = useState(null);
    const [endLunch, setEndLunch] = useState(null);

    const isWeekend = (date) => {
        return (saturday === "disabled" && date.getDay() === 6) || (sunday === "disabled" && date.getDay() === 0);
    }

    const dateDiffInDays = (a, b) => {
        const _MS_PER_DAY = 1000 * 60 * 60 * 24;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / _MS_PER_DAY);
    }

    const dateDiffInSeconds = (a, b) => {
        const _MS_PER_SECONDS = 1000;
        // Discard the time and time-zone information.
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate(), a.getHours(), a.getMinutes(), a.getSeconds());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate(), b.getHours(), b.getMinutes(), b.getSeconds());

        return Math.floor((utc2 - utc1) / _MS_PER_SECONDS);
    }

    const checkLunchTime = (startDate, endDate, sLunch, eLunch) => {
        let lunchSeconds = 0;
        let strtLunch = new Date();
        strtLunch.setFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        strtLunch.setHours(sLunch?.split(":")[0], sLunch?.split(":")[1], 0);

        let edLunch = new Date();
        edLunch.setFullYear(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
        edLunch.setHours(eLunch?.split(":")[0], eLunch?.split(":")[1], 0);

        if (startDate < strtLunch && endDate > edLunch)
            lunchSeconds = lunchTime;

        if ((startDate > strtLunch && startDate < edLunch) && endDate > edLunch)
            lunchSeconds = dateDiffInSeconds(startDate, edLunch)

        if (startDate < strtLunch && (endDate > strtLunch && endDate < edLunch))
            lunchSeconds = dateDiffInSeconds(strtLunch, endDate)

        if ((startDate > strtLunch && startDate < edLunch) && (endDate > strtLunch && endDate < edLunch))
            lunchSeconds = dateDiffInSeconds(startDate, endDate);

        return lunchSeconds;
    }

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const { data } = await api.get("/settings");
                const ha = data.find(s => s.key === 'horarioAbertura');
                const hf = data.find(s => s.key === 'horarioFechamento');
                const strd = data.find(s => s.key === 'saturday');
                const sud = data.find(s => s.key === 'sunday');
                const sl = data.find(s => s.key === 'horarioAlmoco');
                const el = data.find(s => s.key === 'horarioFimAlmoco');

                if (strd?.value)
                    setSaturday(strd.value)

                if (sud?.value)
                    setSunday(sud.value)

                if (ha?.value)
                    setAbertura(ha.value)

                if (hf?.value)
                    setFechamento(hf.value)

                if (sl?.value)
                    setStartLunch(sl.value)

                if (el?.value)
                    setEndLunch(el.value)

                let closeLunchSeconds = (parseInt(el.value?.split(":")[0] * 60, 10) + parseInt(el.value?.split(":")[1], 10)) * 60;
                let openLunchSeconds = (parseInt(sl.value?.split(":")[0] * 60, 10) + parseInt(sl.value?.split(":")[1], 10)) * 60;
                let staticLunchSeconds = closeLunchSeconds - openLunchSeconds;
                setLunchTime(staticLunchSeconds);

                let closeSeconds = (parseInt(hf.value?.split(":")[0] * 60, 10) + parseInt(hf.value?.split(":")[1], 10)) * 60;
                let openSeconds = (parseInt(ha.value?.split(":")[0] * 60, 10) + parseInt(ha.value?.split(":")[1], 10)) * 60;
                setTimeClosed(86400 - (closeSeconds - openSeconds));
                setTimeWorking((closeSeconds - openSeconds) - staticLunchSeconds);

            } catch (err) {
                console.log(err)
                toastError(err);
            }
        };
        fetchSession();
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async () => {
                try {
                    let {data} = await api.get("/dashboard/stats", {
                        params: {
                            firstDate: fd.current,
                            lastDate: ld.current,
                            queueId,
                            userId
                        },
                    })
                    // console.log(data)

                    /* TEMPO TOTAL DE ESPERA */
                    let seconds = 0;
                    let minutes = 0;
                    let hours = 0;
                    let totalSeconds = 0;
                    let waitingSeconds = 0;


                    if (data.tme.length > 0 && 1 === 0) {
                        data.tme.map(diff => {
                            /* convert createdAt to js datetime */
                            let tempDate = diff.createdAt.split("T")[0].split("-");
                            let tempHours = diff.createdAt.split("T")[1].split(":");
                            let cretd = new Date();
                            cretd.setFullYear(tempDate[0], tempDate[1] - 1, tempDate[2]);
                            /* dote is because seconds contains miliseconds */
                            cretd.setHours(tempHours[0], tempHours[1], tempHours[2].split(".")[0]);
                            /* add timezone manually because sequelzie return without her and datetime is alreay setted */
                            cretd.setHours(cretd.getHours() - 3);

                            /* convert acceptedAt to js datetime with same params of createdAt */
                            let tempDate2 = diff.acceptedAt.split("T")[0].split("-");
                            let tempHours2 = diff.acceptedAt.split("T")[1].split(":");
                            let accpt = new Date();
                            accpt.setFullYear(tempDate2[0], tempDate2[1] - 1, tempDate2[2]);
                            accpt.setHours(tempHours2[0], tempHours2[1], tempHours2[2].split(".")[0])
                            accpt.setHours(accpt.getHours() - 3);

                            let staticDiffdays = dateDiffInDays(cretd, accpt);

                            if (staticDiffdays > 0) {
                                for (let i = 0; i <= staticDiffdays; i++) {
                                    let currentTime = new Date(cretd.getFullYear(), cretd.getMonth(), cretd.getDate(), cretd.getHours(), cretd.getMinutes(), cretd.getSeconds());
                                    currentTime.setDate(currentTime.getDate() + i);

                                    let hrFechamento = new Date();
                                    hrFechamento.setFullYear(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
                                    hrFechamento.setHours(fechamento?.split(":")[0], fechamento?.split(":")[1], 0);

                                    let hrAbertura = new Date();
                                    hrAbertura.setFullYear(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
                                    hrAbertura.setHours(abertura?.split(":")[0], abertura?.split(":")[1], 0);

                                    if (isWeekend(currentTime))
                                        continue;

                                    /* first day */
                                    if (i === 0) {
                                        if (cretd > hrAbertura && cretd < hrFechamento) {
                                            let staticLunchTime = checkLunchTime(cretd, hrFechamento, startLunch, endLunch);
                                            waitingSeconds += dateDiffInSeconds(cretd, hrFechamento) - staticLunchTime;
                                        }

                                        if (cretd < hrAbertura && cretd < hrFechamento) {
                                            let staticLunchTime = checkLunchTime(hrAbertura, hrFechamento, startLunch, endLunch);
                                            waitingSeconds += dateDiffInSeconds(hrAbertura, hrFechamento) - staticLunchTime;
                                        }
                                    }

                                    /* interval day */
                                    if (i > 0 && !isSameDay(currentTime, accpt)) {
                                        waitingSeconds += timeWorking;
                                    }

                                    /* last day */
                                    if (i > 0 && isSameDay(currentTime, accpt)) {
                                        if (accpt > hrAbertura && accpt < hrFechamento) {
                                            let staticLunchTime = checkLunchTime(hrAbertura, accpt, startLunch, endLunch);
                                            waitingSeconds += dateDiffInSeconds(hrAbertura, accpt) - staticLunchTime;
                                        }

                                        if (accpt > hrAbertura && accpt > hrFechamento) {
                                            waitingSeconds += timeWorking;
                                        }
                                    }
                                }
                            }

                            if (isSameDay(cretd, accpt)) {
                                if (isWeekend(cretd) && isWeekend(accpt))
                                    return false;

                                let hrFechamento = new Date();
                                hrFechamento.setFullYear(cretd.getFullYear(), cretd.getMonth(), cretd.getDate());
                                hrFechamento.setHours(fechamento?.split(":")[0], fechamento?.split(":")[1], 0);

                                let hrAbertura = new Date();
                                hrAbertura.setFullYear(cretd.getFullYear(), cretd.getMonth(), cretd.getDate());
                                hrAbertura.setHours(abertura?.split(":")[0], abertura?.split(":")[1], 0);

                                if (cretd > hrFechamento && accpt > hrFechamento) {
                                    return false;
                                }

                                if (cretd < hrAbertura && accpt < hrAbertura) {
                                    return false;
                                }

                                if (cretd > hrAbertura && accpt < hrFechamento) {
                                    let staticSecondsDiff = dateDiffInSeconds(cretd, accpt);
                                    let staticLunchTime = checkLunchTime(cretd, accpt, startLunch, endLunch);
                                    waitingSeconds += staticSecondsDiff - staticLunchTime;
                                }

                                if (cretd < hrAbertura && accpt < hrFechamento) {
                                    let staticSecondsDiff = dateDiffInSeconds(hrAbertura, accpt);
                                    let staticLunchTime = checkLunchTime(hrAbertura, accpt, startLunch, endLunch);
                                    waitingSeconds += staticSecondsDiff - staticLunchTime;
                                }

                                if (cretd > hrAbertura && accpt > hrFechamento) {
                                    let staticSecondsDiff = dateDiffInSeconds(cretd, hrFechamento);
                                    let staticLunchTime = checkLunchTime(cretd, hrFechamento, startLunch, endLunch);
                                    waitingSeconds += staticSecondsDiff - staticLunchTime;
                                }

                                if (cretd < hrAbertura && accpt > hrFechamento) {
                                    waitingSeconds += timeWorking;
                                }
                            }
                            return false;
                        });

                        totalSeconds = waitingSeconds;

                        minutes = parseInt(totalSeconds / 60);
                        seconds = parseInt(totalSeconds % 60);

                        hours = parseInt(minutes / 60);
                        minutes = minutes % 60;

                        let tme = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`

                        data = {
                            ...data,
                            tte: tme,
                            te: data.tme.length
                        }
                    } else {
                        data = {
                            ...data,
                            tte: "00:00:00",
                            te: 0
                        }
                    }

                    /* TEMPO TOTAL DE ATENDIMENTO */
                    seconds = 0;
                    minutes = 0;
                    hours = 0;
                    totalSeconds = 0;
                    waitingSeconds = 0;

                    if (data.tma.length > 0 && 1 === 0) {
                        data.tma.map(diff => {
                            /* convert createdAt to js datetime */
                            let tempDate = diff.acceptedAt.split("T")[0].split("-");
                            let tempHours = diff.acceptedAt.split("T")[1].split(":");
                            let accpt = new Date();
                            accpt.setFullYear(tempDate[0], tempDate[1] - 1, tempDate[2]);
                            /* dote is because seconds contains miliseconds */
                            accpt.setHours(tempHours[0], tempHours[1], tempHours[2].split(".")[0]);
                            /* add timezone manually because sequelzie return without her and datetime is alreay setted */
                            accpt.setHours(accpt.getHours() - 3);

                            /* convert acceptedAt to js datetime with same params of acceptedAt */
                            let tempDate2 = diff.closedAt.split("T")[0].split("-");
                            let tempHours2 = diff.closedAt.split("T")[1].split(":");
                            let clsed = new Date();
                            clsed.setFullYear(tempDate2[0], tempDate2[1] - 1, tempDate2[2]);
                            clsed.setHours(tempHours2[0], tempHours2[1], tempHours2[2].split(".")[0])
                            clsed.setHours(clsed.getHours() - 3);

                            let staticDiffdays = dateDiffInDays(accpt, clsed);

                            if (staticDiffdays > 0) {
                                for (let i = 0; i <= staticDiffdays; i++) {
                                    let currentTime = new Date(accpt.getFullYear(), accpt.getMonth(), accpt.getDate(), accpt.getHours(), accpt.getMinutes(), accpt.getSeconds());
                                    currentTime.setDate(currentTime.getDate() + i);

                                    let hrFechamento = new Date();
                                    hrFechamento.setFullYear(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
                                    hrFechamento.setHours(fechamento?.split(":")[0], fechamento?.split(":")[1], 0);

                                    let hrAbertura = new Date();
                                    hrAbertura.setFullYear(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
                                    hrAbertura.setHours(abertura?.split(":")[0], abertura?.split(":")[1], 0);

                                    if (isWeekend(currentTime))
                                        continue;

                                    /* first day */
                                    if (i === 0) {
                                        if (accpt > hrAbertura && accpt < hrFechamento) {
                                            let staticLunchTime = checkLunchTime(accpt, hrFechamento, startLunch, endLunch);
                                            waitingSeconds += dateDiffInSeconds(accpt, hrFechamento) - staticLunchTime;
                                        }

                                        if (accpt < hrAbertura && accpt < hrFechamento) {
                                            let staticLunchTime = checkLunchTime(hrAbertura, hrFechamento, startLunch, endLunch);
                                            waitingSeconds += dateDiffInSeconds(hrAbertura, hrFechamento) - staticLunchTime;
                                        }
                                    }

                                    /* interval day */
                                    if (i > 0 && !isSameDay(currentTime, clsed)) {
                                        waitingSeconds += timeWorking;
                                    }

                                    /* last day */
                                    if (i > 0 && isSameDay(currentTime, clsed)) {
                                        if (clsed > hrAbertura && clsed < hrFechamento) {
                                            let staticLunchTime = checkLunchTime(hrAbertura, clsed, startLunch, endLunch);
                                            waitingSeconds += dateDiffInSeconds(hrAbertura, clsed) - staticLunchTime;
                                        }

                                        if (clsed > hrAbertura && clsed > hrFechamento) {
                                            waitingSeconds += timeWorking;
                                        }
                                    }
                                }
                            }

                            if (isSameDay(accpt, clsed)) {
                                if (isWeekend(accpt) && isWeekend(clsed))
                                    return false;

                                let hrFechamento = new Date();
                                hrFechamento.setFullYear(accpt.getFullYear(), accpt.getMonth(), accpt.getDate());
                                hrFechamento.setHours(fechamento?.split(":")[0], fechamento?.split(":")[1], 0);

                                let hrAbertura = new Date();
                                hrAbertura.setFullYear(accpt.getFullYear(), accpt.getMonth(), accpt.getDate());
                                hrAbertura.setHours(abertura?.split(":")[0], abertura?.split(":")[1], 0);

                                if (accpt > hrFechamento && clsed > hrFechamento) {
                                    return false;
                                }

                                if (accpt < hrAbertura && clsed < hrAbertura) {
                                    return false;
                                }

                                if (accpt > hrAbertura && clsed < hrFechamento) {
                                    let staticLunchTime = checkLunchTime(accpt, clsed, startLunch, endLunch);
                                    let staticSecondsDiff = dateDiffInSeconds(accpt, clsed);
                                    waitingSeconds += staticSecondsDiff - staticLunchTime;
                                }

                                if (accpt < hrAbertura && clsed < hrFechamento) {
                                    let staticLunchTime = checkLunchTime(hrAbertura, clsed, startLunch, endLunch);
                                    let staticSecondsDiff = dateDiffInSeconds(hrAbertura, clsed);
                                    waitingSeconds += staticSecondsDiff - staticLunchTime;
                                }

                                if (accpt > hrAbertura && clsed > hrFechamento) {
                                    let staticLunchTime = checkLunchTime(accpt, hrFechamento, startLunch, endLunch);
                                    let staticSecondsDiff = dateDiffInSeconds(accpt, hrFechamento);
                                    waitingSeconds += staticSecondsDiff - staticLunchTime;
                                }

                                if (accpt < hrAbertura && clsed > hrFechamento) {
                                    waitingSeconds += timeWorking;
                                }
                            }
                            return false;
                        });

                        totalSeconds = waitingSeconds;

                        minutes = parseInt(totalSeconds / 60);
                        seconds = parseInt(totalSeconds % 60);

                        hours = parseInt(minutes / 60);
                        minutes = minutes % 60;

                        let tma = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`

                        data = {
                            ...data,
                            tta: tma,
                            ta: data.tma.length
                        }
                    } else {
                        data = {
                            ...data,
                            tta: "00:00:00",
                            ta: 0
                        }
                    }

                    /* TEMPO MEDIO DE ESPERA */
                    seconds = 0;
                    minutes = 0;
                    hours = 0;
                    totalSeconds = 0;
                    waitingSeconds = 0;

                    if (data.tme.length > 0) {
                        data.tme.map(diff => {
                            /* convert createdAt to js datetime */
                            let tempDate = diff.createdAt.split("T")[0].split("-");
                            let tempHours = diff.createdAt.split("T")[1].split(":");
                            let cretd = new Date();
                            cretd.setFullYear(tempDate[0], tempDate[1] - 1, tempDate[2]);
                            /* dote is because seconds contains miliseconds */
                            cretd.setHours(tempHours[0], tempHours[1], tempHours[2].split(".")[0]);
                            /* add timezone manually because sequelzie return without her and datetime is alreay setted */
                            cretd.setHours(cretd.getHours() - 3);

                            /* convert acceptedAt to js datetime with same params of createdAt */
                            let tempDate2 = diff.acceptedAt.split("T")[0].split("-");
                            let tempHours2 = diff.acceptedAt.split("T")[1].split(":");
                            let accpt = new Date();
                            accpt.setFullYear(tempDate2[0], tempDate2[1] - 1, tempDate2[2]);
                            accpt.setHours(tempHours2[0], tempHours2[1], tempHours2[2].split(".")[0])
                            accpt.setHours(accpt.getHours() - 3);

                            let staticDiffdays = dateDiffInDays(cretd, accpt);

                            if (staticDiffdays > 0) {
                                for (let i = 0; i <= staticDiffdays; i++) {
                                    let currentTime = new Date(cretd.getFullYear(), cretd.getMonth(), cretd.getDate(), cretd.getHours(), cretd.getMinutes(), cretd.getSeconds());
                                    currentTime.setDate(currentTime.getDate() + i);

                                    let hrFechamento = new Date();
                                    hrFechamento.setFullYear(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
                                    hrFechamento.setHours(fechamento?.split(":")[0], fechamento?.split(":")[1], 0);

                                    let hrAbertura = new Date();
                                    hrAbertura.setFullYear(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
                                    hrAbertura.setHours(abertura?.split(":")[0], abertura?.split(":")[1], 0);

                                    if (isWeekend(currentTime))
                                        continue;

                                    /* first day */
                                    if (i === 0) {
                                        if (cretd > hrAbertura && cretd < hrFechamento) {
                                            let staticLunchTime = checkLunchTime(cretd, hrFechamento, startLunch, endLunch);
                                            waitingSeconds += dateDiffInSeconds(cretd, hrFechamento) - staticLunchTime;
                                        }

                                        if (cretd < hrAbertura && cretd < hrFechamento) {
                                            let staticLunchTime = checkLunchTime(hrAbertura, hrFechamento, startLunch, endLunch);
                                            waitingSeconds += dateDiffInSeconds(hrAbertura, hrFechamento) - staticLunchTime;
                                        }
                                    }

                                    /* interval day */
                                    if (i > 0 && !isSameDay(currentTime, accpt)) {
                                        waitingSeconds += timeWorking;
                                    }

                                    /* last day */
                                    if (i > 0 && isSameDay(currentTime, accpt)) {
                                        if (accpt > hrAbertura && accpt < hrFechamento) {
                                            let staticLunchTime = checkLunchTime(hrAbertura, accpt, startLunch, endLunch);
                                            waitingSeconds += dateDiffInSeconds(hrAbertura, accpt) - staticLunchTime;
                                        }

                                        if (accpt > hrAbertura && accpt > hrFechamento) {
                                            waitingSeconds += timeWorking;
                                        }
                                    }
                                }
                            }

                            if (isSameDay(cretd, accpt)) {
                                if (isWeekend(cretd) && isWeekend(accpt))
                                    return false;

                                let hrFechamento = new Date();
                                hrFechamento.setFullYear(cretd.getFullYear(), cretd.getMonth(), cretd.getDate());
                                hrFechamento.setHours(fechamento?.split(":")[0], fechamento?.split(":")[1], 0);

                                let hrAbertura = new Date();
                                hrAbertura.setFullYear(cretd.getFullYear(), cretd.getMonth(), cretd.getDate());
                                hrAbertura.setHours(abertura?.split(":")[0], abertura?.split(":")[1], 0);

                                if (cretd > hrFechamento && accpt > hrFechamento) {
                                    return false;
                                }

                                if (cretd < hrAbertura && accpt < hrAbertura) {
                                    return false;
                                }

                                if (cretd > hrAbertura && accpt < hrFechamento) {
                                    let staticSecondsDiff = dateDiffInSeconds(cretd, accpt);
                                    let staticLunchTime = checkLunchTime(cretd, accpt, startLunch, endLunch);
                                    waitingSeconds += staticSecondsDiff - staticLunchTime;
                                }

                                if (cretd < hrAbertura && accpt < hrFechamento) {
                                    let staticSecondsDiff = dateDiffInSeconds(hrAbertura, accpt);
                                    let staticLunchTime = checkLunchTime(hrAbertura, accpt, startLunch, endLunch);
                                    waitingSeconds += staticSecondsDiff - staticLunchTime;
                                }

                                if (cretd > hrAbertura && accpt > hrFechamento) {
                                    let staticSecondsDiff = dateDiffInSeconds(cretd, hrFechamento);
                                    let staticLunchTime = checkLunchTime(cretd, hrFechamento, startLunch, endLunch);
                                    waitingSeconds += staticSecondsDiff - staticLunchTime;
                                }

                                if (cretd < hrAbertura && accpt > hrFechamento) {
                                    waitingSeconds += timeWorking;
                                }
                            }
                            return false;
                        });

                        totalSeconds = waitingSeconds;

                        let average = totalSeconds / data.tme.length;

                        minutes = parseInt(average / 60);
                        seconds = parseInt(average % 60);

                        hours = parseInt(minutes / 60);
                        minutes = parseInt(minutes % 60);

                        let tme = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`

                        data = {
                            ...data,
                            tme: tme
                        }
                    } else {
                        data = {
                            ...data,
                            tme: "00:00:00"
                        }
                    }

                    /* TEMPO MEDIO DE ATENDIMENTO */
                    seconds = 0;
                    minutes = 0;
                    hours = 0;
                    totalSeconds = 0;
                    waitingSeconds = 0;

                    if (data.tma.length > 0) {
                        data.tma.map(diff => {
                            /* convert createdAt to js datetime */
                            let tempDate = diff.acceptedAt.split("T")[0].split("-");
                            let tempHours = diff.acceptedAt.split("T")[1].split(":");
                            let accpt = new Date();
                            accpt.setFullYear(tempDate[0], tempDate[1] - 1, tempDate[2]);
                            /* dote is because seconds contains miliseconds */
                            accpt.setHours(tempHours[0], tempHours[1], tempHours[2].split(".")[0]);
                            /* add timezone manually because sequelzie return without her and datetime is alreay setted */
                            accpt.setHours(accpt.getHours() - 3);

                            /* convert acceptedAt to js datetime with same params of acceptedAt */
                            let tempDate2 = diff.closedAt.split("T")[0].split("-");
                            let tempHours2 = diff.closedAt.split("T")[1].split(":");
                            let clsed = new Date();
                            clsed.setFullYear(tempDate2[0], tempDate2[1] - 1, tempDate2[2]);
                            clsed.setHours(tempHours2[0], tempHours2[1], tempHours2[2].split(".")[0])
                            clsed.setHours(clsed.getHours() - 3);

                            let staticDiffdays = dateDiffInDays(accpt, clsed);

                            if (staticDiffdays > 0) {
                                for (let i = 0; i <= staticDiffdays; i++) {
                                    let currentTime = new Date(accpt.getFullYear(), accpt.getMonth(), accpt.getDate(), accpt.getHours(), accpt.getMinutes(), accpt.getSeconds());
                                    currentTime.setDate(currentTime.getDate() + i);

                                    let hrFechamento = new Date();
                                    hrFechamento.setFullYear(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
                                    hrFechamento.setHours(fechamento?.split(":")[0], fechamento?.split(":")[1], 0);

                                    let hrAbertura = new Date();
                                    hrAbertura.setFullYear(currentTime.getFullYear(), currentTime.getMonth(), currentTime.getDate());
                                    hrAbertura.setHours(abertura?.split(":")[0], abertura?.split(":")[1], 0);

                                    if (isWeekend(currentTime))
                                        continue;

                                    /* first day */
                                    if (i === 0) {
                                        if (accpt > hrAbertura && accpt < hrFechamento) {
                                            let staticLunchTime = checkLunchTime(accpt, hrFechamento, startLunch, endLunch);
                                            waitingSeconds += dateDiffInSeconds(accpt, hrFechamento) - staticLunchTime;
                                        }

                                        if (accpt < hrAbertura && accpt < hrFechamento) {
                                            let staticLunchTime = checkLunchTime(hrAbertura, hrFechamento, startLunch, endLunch);
                                            waitingSeconds += dateDiffInSeconds(hrAbertura, hrFechamento) - staticLunchTime;
                                        }
                                    }

                                    /* interval day */
                                    if (i > 0 && !isSameDay(currentTime, clsed)) {
                                        waitingSeconds += timeWorking;
                                    }

                                    /* last day */
                                    if (i > 0 && isSameDay(currentTime, clsed)) {
                                        if (clsed > hrAbertura && clsed < hrFechamento) {
                                            let staticLunchTime = checkLunchTime(hrAbertura, clsed, startLunch, endLunch);
                                            waitingSeconds += dateDiffInSeconds(hrAbertura, clsed) - staticLunchTime;
                                        }

                                        if (clsed > hrAbertura && clsed > hrFechamento) {
                                            waitingSeconds += timeWorking;
                                        }
                                    }
                                }
                            }

                            if (isSameDay(accpt, clsed)) {
                                if (isWeekend(accpt) && isWeekend(clsed))
                                    return false;

                                let hrFechamento = new Date();
                                hrFechamento.setFullYear(accpt.getFullYear(), accpt.getMonth(), accpt.getDate());
                                hrFechamento.setHours(fechamento?.split(":")[0], fechamento?.split(":")[1], 0);

                                let hrAbertura = new Date();
                                hrAbertura.setFullYear(accpt.getFullYear(), accpt.getMonth(), accpt.getDate());
                                hrAbertura.setHours(abertura?.split(":")[0], abertura?.split(":")[1], 0);

                                if (accpt > hrFechamento && clsed > hrFechamento) {
                                    return false;
                                }

                                if (accpt < hrAbertura && clsed < hrAbertura) {
                                    return false;
                                }

                                if (accpt > hrAbertura && clsed < hrFechamento) {
                                    let staticLunchTime = checkLunchTime(accpt, clsed, startLunch, endLunch);
                                    let staticSecondsDiff = dateDiffInSeconds(accpt, clsed);
                                    waitingSeconds += staticSecondsDiff - staticLunchTime;
                                }

                                if (accpt < hrAbertura && clsed < hrFechamento) {
                                    let staticLunchTime = checkLunchTime(hrAbertura, clsed, startLunch, endLunch);
                                    let staticSecondsDiff = dateDiffInSeconds(hrAbertura, clsed);
                                    waitingSeconds += staticSecondsDiff - staticLunchTime;
                                }

                                if (accpt > hrAbertura && clsed > hrFechamento) {
                                    let staticLunchTime = checkLunchTime(accpt, hrFechamento, startLunch, endLunch);
                                    let staticSecondsDiff = dateDiffInSeconds(accpt, hrFechamento);
                                    waitingSeconds += staticSecondsDiff - staticLunchTime;
                                }

                                if (accpt < hrAbertura && clsed > hrFechamento) {
                                    waitingSeconds += timeWorking;
                                }
                            }
                            return false;
                        });

                        totalSeconds = waitingSeconds;

                        let average = totalSeconds / data.tma.length;

                        minutes = parseInt(average / 60);
                        seconds = parseInt(average % 60);

                        hours = parseInt(minutes / 60);
                        minutes = parseInt(minutes % 60);

                        let tma = `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${seconds < 10 ? '0' + seconds : seconds}`

                        data = {
                            ...data,
                            tma: tma
                        }
                    } else {
                        data = {
                            ...data,
                            tma: "00:00:00"
                        }
                    }

                    setStats(data);
                } catch (err) {
                    console.log(err)
                    toast.error(err)
                }
            }

            fetchTickets()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queueId, timeClosed, timeWorking, userId, startLunch, endLunch]);

    return (
        <div>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Typography component="h5" variant="h5" className={classes.groupGridTitle}>
                        Estatísticas (total)
                    </Typography>
                </Grid>

                <Grid item xs={isMobile ? 6 : 3}>
                    <Paper className={classes.customFixedHeightPaper}>
                        <PersonIcon className={classes.iconStyle} />
                        <Typography component="h3" variant="h6" paragraph>
                            Usuários
                        </Typography>
                        <Grid item>
                            <Typography component="h1" variant="h4">
                                {stats.user}/{stats.userTotal - 1}
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={isMobile ? 6 : 3}>
                    <Paper className={classes.customFixedHeightPaper}>
                        <QueueIcon className={classes.iconStyle} />
                        <Typography component="h3" variant="h6" paragraph>
                            Filas
                        </Typography>
                        <Grid item>
                            <Typography component="h1" variant="h4">
                                {stats.queue}
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={isMobile ? 6 : 3}>
                    <Paper className={classes.customFixedHeightPaper}>
                        <WhatsAppIcon className={classes.iconStyle} />
                        <Typography component="h3" variant="h6" paragraph>
                            Conexões
                        </Typography>
                        <Grid item>
                            <Typography component="h1" variant="h4">
                                {stats.whatsapp}/{stats.whatsappTotal}
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={isMobile ? 6 : 3}>
                    <Paper className={classes.customFixedHeightPaper}>
                        <EventIcon className={classes.iconStyle} />
                        <Typography component="h3" variant="h6" paragraph>
                            Agendamentos
                        </Typography>
                        <Grid item>
                            <Typography component="h1" variant="h4">
                                {stats.agendamento}
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={isMobile ? 6 : 3}>
                    <Paper className={classes.customFixedHeightPaper}>
                        <PermContactCalendarIcon className={classes.iconStyle} />
                        <Typography component="h3" variant="h6" paragraph>
                            Contatos
                        </Typography>
                        <Grid item>
                            <Typography component="h1" variant="h4">
                                {stats.contacts.toLocaleString('pt-BR')}
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={isMobile ? 6 : 3}>
                    <Paper className={classes.customFixedHeightPaper}>
                        <RecentActorsIcon className={classes.iconStyle} />
                        <Typography component="h3" variant="h6" paragraph>
                            Grupos
                        </Typography>
                        <Grid item>
                            <Typography component="h1" variant="h4">
                                {stats.group}
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={isMobile ? 6 : 3}>
                    <Paper className={classes.customFixedHeightPaper}>
                        <ForumIcon className={classes.iconStyle} />
                        <Typography component="h3" variant="h6" paragraph>
                            Tickets
                        </Typography>
                        <Grid item>
                            <Typography component="h1" variant="h4">
                                {stats.tickets.toLocaleString('pt-BR')}
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={isMobile ? 6 : 3}>
                    <Paper className={classes.customFixedHeightPaper}>
                        <MessageIcon className={classes.iconStyle} />
                        <Typography component="h3" variant="h6" paragraph>
                            Mensagens
                        </Typography>
                        <Grid item>
                            <Typography component="h1" variant="h4">
                                {stats.messages.toLocaleString('pt-BR')}
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>

                {/*<Grid item xs={isMobile ? 6 : 3}>
                    <Paper className={classes.customFixedHeightPaper} style={{overflow: "hidden", backgroundColor: "#0078a8", color: "white}}>
                        <Typography component="h3" variant="h6" color="primary" paragraph title={`${stats.te} tickets no total`}>
                            TTE <span style={{fontSize: '9pt'}} className={isMobile ? classes.hidden : null}>(tempo total de espera)</span>
                        </Typography>
                        <Grid item>
                            <Typography component="h1" variant="h4">
                                {stats.tte}
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={isMobile ? 6 : 3}>
                    <Paper className={classes.customFixedHeightPaper} style={{overflow: "hidden", backgroundColor: "#0078a8", color: "white}}>
                        <Typography component="h3" variant="h6" color="primary" paragraph title={`${stats.ta} tickets no total`}>
                            TTA <span style={{fontSize: '9pt'}} className={isMobile ? classes.hidden : null}>(tempo total de atendimento)</span>
                        </Typography>
                        <Grid item>
                            <Typography component="h1" variant="h4">
                                {stats.tta}
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>*/}

                <Grid item xs={isMobile ? 6 : 3}>
                    <Paper className={classes.customFixedHeightPaper}>
                        <TimerIcon className={classes.iconStyle} style={{top: "0.7em"}} />
                        <Typography component="h3" variant="h6" paragraph title="Tempo médio de espera">
                            TME <span style={{fontSize: '9pt'}} className={isMobile ? classes.hidden : null}>(tempo médio de espera)</span>
                        </Typography>
                        <Grid item>
                            <Typography component="h1" variant="h4">
                                {stats.tme}
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={isMobile ? 6 : 3}>
                    <Paper className={classes.customFixedHeightPaper}>
                        <AccessAlarmIcon className={classes.iconStyle} style={{top: '0.7em'}} />
                        <Typography component="h3" variant="h6" paragraph title="Tempo médio de atendimento">
                            TMA <span style={{fontSize: '9pt'}} className={isMobile ? classes.hidden : null}>(tempo médio de atendimento)</span>
                        </Typography>
                        <Grid item>
                            <Typography component="h1" variant="h4">
                                {stats.tma}
                            </Typography>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
};

export default Stats;
