import React, { useState, useEffect } from "react";

import { Formik, Form, Field } from "formik";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import {format, parseISO} from "date-fns";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
}));

const AgendamentoInfoModal = ({ open, onClose, agendamentoId }) => {
	const classes = useStyles();

	const initialState = {
		message: "",
		contactsIds: "",
		tagsIds: "",
		allContacts: false
	};

	const [agendamento, setAgendamento] = useState(initialState);
	const [contatos, setContatos] = useState("");
	const weekdays = { 'Monday': 'Segunda-Feira', 'Tuesday': 'Terça-Feira', 'Wednesday': 'Quarta-Feira', 'Thurday': 'Quinta-Feira', 'Friday': 'Sexta-Feira', 'Saturday': 'Sábado', 'Sunday': 'Domingo'};

	useEffect(() => {
		(async () => {
			if (!agendamentoId) return;
			try {
				const { data } = await api.get(`/agendamento/${agendamentoId}`);
				setAgendamento(prevState => {
					return { ...prevState, ...data };
				});

				if (!data.allContacts && (data.contactsIds || data.tagsIds)) {
					if (data.contactsIds) {
						let cont = data.contactsIds.split(',');
						let contAr = [];

						for (const c of cont) {
							const {data} = await api.get(`/contacts/${c}`);
							contAr.push(data.name);
						}
						setContatos(contAr.join(', '));
					} else if (data.tagsIds) {
						let tags = data.tagsIds.split(",");
						let contAr = [];

						for (const tag of tags) {
							const {data} = await api.get(`/tag/${tag}`);

							if (data.contacts) {
								for (const conts of data.contacts){
									contAr.push(conts.name);
								}
							}
						}
						setContatos(contAr.join(', '));
					}

				}
			} catch (err) {
				toastError(err);
			}
			return () => {
				setAgendamento({
					message: "",
					contactsIds: "",
					tagsIds: "",
					allContacts: false
				});
			};
		})();
	}, [agendamentoId, open]);

	const handleClose = () => {
		onClose();
		setAgendamento(initialState);
		setContatos("");
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} scroll="paper">
				<DialogTitle>
					{ ` Agendamento ${agendamentoId}`}
				</DialogTitle>
				<Formik
					initialValues={agendamento}
					enableReinitialize={true}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent dividers style={{ width: "500px"}}>
								<Field
									as={TextField}
									label="Mensagem"
									type="greetingMessage"
									multiline
									minRows={5}
									fullWidth
									value={agendamento?.message}
									error={
										touched.message && Boolean(errors.message)
									}
									helperText={
										touched.message && errors.message
									}
									variant="outlined"
									margin="dense"
								/>

								<Field style={{ marginRight: "10px"}}
									as={TextField}
									label="Tipo de recorrência"
									type="text"
									multiline
									minRows={1}
									value={agendamento.recurrence === "once" ? "Único" :
										agendamento.recurrence === "every_day" ? "Todo dia" :
											agendamento.recurrence === "every_month" ? "Todo mês" :
												agendamento.recurrence === "every_week" ? "Semanal" :
													agendamento.recurrence === "quarterly" ? "Trimestral" :
														agendamento.recurrence ?? ""}
									error={
										touched.recurrence && Boolean(errors.recurrence)
									}
									helperText={
										touched.recurrence && errors.recurrence
									}
									variant="outlined"
									margin="dense"
								/>

								<Field
									as={TextField}
									label="Valor da recorrência"
									type="text"
									multiline
									minRows={1}
									value={agendamento.recurrence === "once" && agendamento.RValue === "1" ? "Já enviado" :
										agendamento.recurrence === "last_day_of_month" && agendamento.RValue === "1" ? "Já eviado" :
											agendamento.recurrence === "business_day" ? agendamento.RValue + "th" :
												agendamento.recurrence === "every_month" ? "Dia " + agendamento.RValue :
													agendamento.recurrence === "every_week" ? weekdays[agendamento.RValue] :
														agendamento.recurrence === "every_day" ? format(parseISO(agendamento.sendAt), "HH:mm") :
															agendamento.RValue ?? ""}
									error={
										touched.RValue && Boolean(errors.RValue)
									}
									helperText={
										touched.RValue && errors.RValue
									}
									variant="outlined"
									margin="dense"
								/>

								<Field style={{ marginRight: "10px"}}
									as={TextField}
									label="Horário de envio"
									type="text"
									multiline
									minRows={1}
									value={agendamento.sendAt ? format(parseISO(agendamento.sendAt), "HH:mm") : ""}
									error={
										touched.sendAt && Boolean(errors.sendAt)
									}
									helperText={
										touched.sendAt && errors.sendAt
									}
									variant="outlined"
									margin="dense"
								/>

								<Field
									as={TextField}
									label="Última atualização"
									type="text"
									multiline
									minRows={1}
									value={agendamento.updatedAt ? format(parseISO(agendamento.updatedAt), "dd/MM/yyyy HH:mm") : ""}
									error={
										touched.updatedAt && Boolean(errors.updatedAt)
									}
									helperText={
										touched.updatedAt && errors.updatedAt
									}
									variant="outlined"
									margin="dense"
								/>

								<Field style={{ marginRight: "10px"}}
									as={TextField}
									label="Enviados"
									type="text"
									multiline
									minRows={1}
									value={agendamento.enviados ?? "0"}
									error={
										touched.enviados && Boolean(errors.enviados)
									}
									helperText={
										touched.enviados && errors.enviados
									}
									variant="outlined"
									margin="dense"
								/>

								<Field
									as={TextField}
									label="Total"
									type="text"
									multiline
									minRows={1}
									value={agendamento.total ?? "0"}
									error={
										touched.total && Boolean(errors.total)
									}
									helperText={
										touched.total && errors.total
									}
									variant="outlined"
									margin="dense"
								/>

								<Field
									as={TextField}
									label="Conexão"
									type="text"
									multiline
									minRows={1}
									value={agendamento.whatsapp?.name ?? "Padrão"}
									error={
										touched.total && Boolean(errors.total)
									}
									helperText={
										touched.total && errors.total
									}
									variant="outlined"
									margin="dense"
								/>

								{/*<TagSelect
									selectedTagIds={selectedTagIds}
									onChange={(values) => {
										setSelectedTagIds(values);
										setSelectedContactIds([]);
									}}
								/>*/}

								<Field
									as={TextField}
									label="Contatos enviados"
									type="greetingMessage"
									multiline
									minRows={5}
									fullWidth
									value={agendamento.allContacts ? "Todos os contatos" : contatos}
									error={
										touched.mensagem && Boolean(errors.mensagem)
									}
									helperText={
										touched.mensagem && errors.mensagem
									}
									variant="outlined"
									margin="dense"
								/>

								<div style={{color: 'red', fontStyle: 'italic', fontSize: '9pt'}}>Altereções feitas aqui não serão efetivadas!</div>

							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									Fechar
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default AgendamentoInfoModal;
