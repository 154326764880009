import React from "react";

import { Avatar, CardHeader } from "@material-ui/core";

import { i18n } from "../../translate/i18n";

const ChatInfo = ({ chat, onClick, user }) => {
	return (
		<CardHeader
			onClick={onClick}
			style={{ cursor: "pointer" }}
			titleTypographyProps={{ noWrap: true }}
			subheaderTypographyProps={{ noWrap: true }}
			avatar={<Avatar src={"/default-profile.png"} alt="contact_image" />}
			// title={`${contact.name} #${ticket.id}`}
			title={chat.callingUserId === user.id ? chat.user ? chat.user?.name : chat.userName ? `Usuário excluído (${chat.userName})` : "Usuário excluído" : chat.callingUser ? chat.callingUser?.name : chat.callingName ? `Usuário excluído (${chat.callingName})` : "Usuário excluído"}
			subheader={
				chat.callingUserId === user.id ? `${i18n.t("messagesList.header.assignedTo")} ${chat.callingUser?.name}` : `${i18n.t("messagesList.header.assignedTo")} ${chat.user?.name}`
			}
		/>
	);
};

export default ChatInfo;
