import React, { useEffect, useState } from "react";
import FormControl from "@material-ui/core/FormControl";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import Select from "react-select";

const TemplateSelect = ({ selectedTemplateIds, onChange, ticketId, whatsappId }) => {
	const [optionList, setOptionList] = useState([]);
	const [selections, setSelections] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (ticketId) {
			(async () => {
				try {
					setLoading(true);
					const {data} = await api.get(`/meta/templates/${ticketId}`);

					setOptionList(data.data?.map(template => {
						if (template.status === "APPROVED")
							return {value: template.id, label: `${template.name} - ${template.category}`, data: JSON.stringify(template)};
						return null;
					}));
					setLoading(false);
				} catch (err) {
					toastError(err);
					setLoading(false);
				}
			})();
		}
	}, [ticketId]);

	useEffect(() => {
		if (whatsappId) {
			(async () => {
				try {
					setLoading(true);
					const {data} = await api.get(`/meta/templates/wpp/${whatsappId}`);

					setOptionList(data.data?.map(template => {
						if (template.status === "APPROVED")
							return {value: template.id, label: `${template.name} - ${template.category}`, data: JSON.stringify(template)};
						return null;
					}));
					setLoading(false);
				} catch (err) {
					toastError(err);
					setLoading(false);
				}
			})();
		}
	}, [whatsappId]);

	useEffect(() => {
		if (selectedTemplateIds)
			setSelections(selectedTemplateIds);
	}, [optionList, selectedTemplateIds]);

	const handleChange = e => {
		setSelections(e);
		onChange(e);
	};

	return (
		<div>
			<FormControl fullWidth margin="dense" variant="outlined">
				<Select
					options={optionList}
					placeholder={loading ? "Carregando..." : "Selecione um template"}
					value={selections}
					onChange={handleChange}
					isSearchable={true}
					// isMulti
					max={1}
					menuPosition="fixed"
				/>
			</FormControl>
		</div>
	)
}

export default TemplateSelect;