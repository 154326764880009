import React, { useState, useEffect } from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import api from "../../services/api";
import toastError from "../../errors/toastError";

// import { AuthContext } from "../../context/Auth/AuthContext";


const PatchNotesModal = ({ modalOpen, onClose }) => {

	// const [loading, setLoading] = useState(false);
	const [, setNotes] = useState([]);
	// const { user } = useContext(AuthContext);

	useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			const fetchNotes = async () => {
				try {
					const { data } = await api.get("/notes");
					if (data.notes)
						setNotes(data.notes);

				} catch (err) {
					toastError(err);
				}
			};
			fetchNotes();
		}, 500);
		return () => {
			clearTimeout(delayDebounceFn);
		};
	}, [])

	const handleClose = () => {
		onClose();
	};


	return (
		<>
			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					{"Notas de atualização"}
				</DialogTitle>
				<DialogContent dividers style={{fontSize: "12pt"}}>
					<p>Fique por dentro das mais recentes atualizações do nosso sistema! </p>
					<p><a target="_blank" rel="noopener noreferrer" style={{fontWeight: "bold"}} href="https://app.goneweb.com.br/patch_notes">Clique aqui</a> e acesse agora mesmo para descobrir todas as novidades que agora estão disponíveis para você.</p>
					<p><i>Version: </i>&nbsp;<b>v6.1.3</b></p>
					{/*{notes.length > 0 ? notes.map(note => (
						<p key={note.id}><span style={{fontWeight: "bold"}}>{note.header}</span><span> - {note.body}</span></p>
					)) : (
						<p><span style={{fontWeight: "bold"}}>Nenhuma atualização recente foi encontrada! </span></p>
					)}*/}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						// disabled={loading}
						variant="outlined"
					>
						{"Fechar"}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default PatchNotesModal;
