import React, { useState, useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";
import Chip from '@material-ui/core/Chip';

import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";
import toastError from "../../errors/toastError";
import AcceptTicketWithouSelectQueue from "../AcceptTicketWithoutQueueModal";
import useQueues from "../../hooks/useQueues";
import useConnections from "../../hooks/useConnections";
import {toast} from "react-toastify";

const useStyles = makeStyles(theme => ({
	ticket: {
		position: "relative",
	},

	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	lastMessageTime: {
		justifySelf: "flex-end",
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "center",
		marginRight: 42,
		paddingRight: 20,
		top: "-2px",
		marginLeft: "auto",
	},

	chip: {
		color: "white",
		marginLeft: "5px",
		padding: "1px"
	},

	contactLastMessage: {
		paddingRight: 20,
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	acceptButton: {
		position: "absolute",
		left: "50%",
	},

	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},
	userTag: {
		position: "absolute",
		marginRight: 5,
		right: 40,
		bottom: 5,
		background: "#2576D2",
		color: "#ffffff",
		border: "1px solid #CCC",
		padding: 1,
		paddingLeft: 5,
		paddingRight: 5,
		borderRadius: 10,
		fontSize: "0.9em"
	},
}));

const TicketListItem = ({ ticket }) => {
	const classes = useStyles();
	const history = useHistory();
	const [loading, setLoading] = useState(false);
	const { ticketId } = useParams();
	const isMounted = useRef(true);
	const [acceptTicketWithouSelectQueueOpen, setAcceptTicketWithouSelectQueueOpen] = useState(false);
	const { user, handleLogout } = useContext(AuthContext);
	const [queues, setQueues] = useState([]);
	const [connections, setConnections] = useState([]);
	const { findAll: findAllQueues } = useQueues();
	const { findAll: findAllConnections } = useConnections();

	useEffect(() => {
		const loadQueues = async () => {
			const list = await findAllQueues();
			setQueues(list);
		}
		loadQueues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	useEffect(() => {
		const loadConnections = async () => {
			const list = await findAllConnections();
			setConnections(list);
		}
		loadConnections();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleAcepptTicket = async id => {
		setLoading(true);
		try {
			const { data: tick } = await api.put(`/tickets/${id}`, {
				status: "open",
				userId: user?.id,
			});

			await api.post("/log", {
				log: {
					descricao: `O usuário ${user?.name} aceitou o ticket(${tick.id}), fila(${tick.queue?.name}), conexão(${tick.whatsapp?.name}) do contato ${tick.contact?.name}`,
					origem: 'Ticket',
					userId: user?.id,
					ticketId: tick.id,
					contactId: tick.contact?.id,
					queueId: tick.queue?.id,
					whatsappId: tick.whatsapp?.id
				}
			});
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
		if (isMounted.current) {
			setLoading(false);
		}
		history.push(`/tickets/${id}`);
	};

	const handleSelectTicket = id => {
		const now = new Date();
		if (user && user.profile !== "admin" && user.startTime && user.endTime) {
			let startTime = new Date();
			startTime.setHours(parseInt(user.startTime.split(":")[0], 10), parseInt(user.startTime.split(":")[1], 10));
			let endTime = new Date();
			endTime.setHours(parseInt(user.endTime.split(":")[0], 10), parseInt(user.endTime.split(":")[1], 10));

			if (now < startTime || now > endTime) {
				toast.info("Você está fora do seu horário de trabalho. Seu acesso está temporariamente bloqueado.", {autoClose: 5000});
				handleLogout();
			}

			// 0 Sunday | 6 Saturday
			if (!user.saturday && now.getDay() === 6) {
				toast.info("Você está fora do seu horário de trabalho. Seu acesso está temporariamente bloqueado.", {autoClose: 5000});
				handleLogout();
			}

			if (!user.sunday && now.getDay() === 0) {
				toast.info("Você está fora do seu horário de trabalho. Seu acesso está temporariamente bloqueado.", {autoClose: 5000});
				handleLogout();
			}

			if (!user.weekdays && (now.getDay() > 0 && now.getDay() < 6)) {
				toast.info("Você está fora do seu horário de trabalho. Seu acesso está temporariamente bloqueado.", {autoClose: 5000});
				handleLogout();
			}
		}

		history.push(`/tickets/${id}`);
	};

	/*const queueName = selectedTicket => {
		let name = null;
		let color = null;
		user.queues.forEach(userQueue => {
			if (userQueue.id === selectedTicket.queueId) {
				name = userQueue.name;
				color = userQueue.color;
			}
		});
		return {
			name,
			color
		};
	}*/

	const handleOpenAcceptTicketWithouSelectQueue = () => {
		setAcceptTicketWithouSelectQueueOpen(true);
	};

	return (
		<React.Fragment key={ticket.id}>
			<AcceptTicketWithouSelectQueue
				modalOpen={acceptTicketWithouSelectQueueOpen}
				onClose={(e) => setAcceptTicketWithouSelectQueueOpen(false)}
				ticketId={ticket.id}
			/>
			<ListItem
				dense
				button
				onClick={e => {
					// if (ticket.status === "pending") return;
					handleSelectTicket(ticket.id);
				}}
				selected={ticketId && +ticketId === ticket.id}
				className={clsx(classes.ticket, {
					[classes.pendingTicket]: ticket.status === "pending",
				})}
			>
				<Tooltip
					arrow
					placement="right"
					title={ticket.queue?.name || "Sem fila"}
				>
					<span
						style={{ backgroundColor: ticket.queue?.color || "#7C7C7C" }}
						className={classes.ticketQueueColor}
					></span>
				</Tooltip>
				<ListItemAvatar>
					<Avatar src={ticket?.contact?.profilePicUrl} />
				</ListItemAvatar>
				<ListItemText
					disableTypography
					primary={
						<span className={classes.contactNameWrapper}>
							<Typography
								noWrap
								component="span"
								variant="body2"
								color="textPrimary"
							>
								{ticket.contact.name.trim()}
								{ticket.contact.tags?.map(tag => (
									<Chip
										key={ticket.contact.id + tag.id}
										className={classes.chip}
										label={tag.name}
										size="small"
										style={{backgroundColor: tag.color}}
									/>
								))}
							</Typography>

							{ticket.status === "closed" && (
								<Badge overlap="rectangular"
									className={classes.closedBadge}
									badgeContent={"Encerrada"}
									color="primary"
								/>
							)}
							{ticket.lastMessage && (
								<Typography
									className={classes.lastMessageTime}
									component="span"
									variant="body2"
									color="textSecondary"
								>
									{isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
										<>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
									) : (
										<>{format(parseISO(ticket.updatedAt), "dd/MM/yyyy")}</>
									)}
								</Typography>
							)}
							{ticket.whatsappId && connections.length > 1 && (
								<div className={classes.userTag} title="Conexão que está sendo utilizada nesse ticket.">{ticket.whatsapp?.name}</div>
							)}
						</span>
					}
					secondary={
						<span className={classes.contactNameWrapper}>
							<Typography
								className={classes.contactLastMessage}
								noWrap
								component="span"
								variant="body2"
								color="textSecondary"
							>
								{ticket.lastMessage ? (
									<MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
								) : (
									<br />
								)}
							</Typography>

							<Badge overlap="rectangular"
								className={classes.newMessagesCount}
								badgeContent={ticket.unreadMessages}
								classes={{
									badge: classes.badgeStyle,
								}}
							/>
						</span>
					}
				/>
				{(ticket.status === "pending" && (ticket.queue !== null || queues.length === 0)) && (
					<ButtonWithSpinner
						color="primary"
						variant="contained"
						className={classes.acceptButton}
						size="small"
						loading={loading}
						onClick={e => handleAcepptTicket(ticket.id)}
					>
						{i18n.t("ticketsList.buttons.accept")}
					</ButtonWithSpinner>
				)}
				{(ticket.status === "pending" && queues.length > 0 && (ticket.queue === null || ticket.queue === undefined)) && (
					<ButtonWithSpinner
						color="secondary"
						variant="contained"
						className={classes.acceptButton}
						size="small"
						loading={loading}
						onClick={e => handleOpenAcceptTicketWithouSelectQueue()}
					>
						{i18n.t("ticketsList.buttons.acceptBeforeBot")}
					</ButtonWithSpinner>
				)}
				{ }
			</ListItem>
			<Divider variant="inset" component="li" />
		</React.Fragment>
	);
};

export default TicketListItem;
