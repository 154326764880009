import React, { useContext, useEffect, useRef, useState } from "react";

import MenuItem from "@material-ui/core/MenuItem";
import Menu from "@material-ui/core/Menu";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ConfirmationModal from "../ConfirmationModal";
import toastError from "../../errors/toastError";
import { Can } from "../Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import {toast} from "react-toastify";
import {useHistory} from "react-router-dom";

const TicketOptionsMenu = ({ ticket, menuOpen, handleClose, anchorEl }) => {
	const [confirmationOpen, setConfirmationOpen] = useState(false);
	const [confirmationCloseOpen, setConfirmationCloseOpen] = useState(false);
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);
	const history = useHistory();

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleDeleteTicket = async () => {
		try {
			const { data } = await api.get(`/protocolos/ticket/${ticket.id}`);

			if (data) {
				toast.warning("Não é possível deletar esse ticket pois existe um protocolo vinculado a ele");
			}

			if (!data) {
				await api.delete(`/tickets/${ticket.id}`, {
					data: {
						log: {
							descricao: `O usuário ${user?.name} excluiu o ticket ${ticket.id}`,
							origem: 'Ticket',
							userId: user?.id,
							ticketId: ticket.id
						}
					}
				});
			}

		} catch (err) {
			toastError(err);
		}
	};

	const handleOpenConfirmationModal = e => {
		setConfirmationOpen(true);
		handleClose();
	};

	const handleCloseTicket = async e => {
		toast.warn("Finalizando ticket....");
		setConfirmationCloseOpen(false);
		try {
			setTimeout(async (e)=>{
				await api.put(`/tickets/${ticket.id}`, {
					status: 'closed',
					userId: user.id || null,
					onlyClose: true,
					log: {
						descricao: `O usuário ${user?.name} mudou o status do ticket ${ticket.id} para closed usando o menu de botões`,
						origem: 'Ticket',
						userId: user?.id,
						ticketId: ticket.id
					}
				});

				history.push("/tickets");
				toast.success(`Ticket finalizado!`);
			}, 4500);
		} catch (err) {
			toastError(err);
		}
	}

	return (
		<>
			<Menu
				id="menu-appbar"
				anchorEl={anchorEl}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={handleClose}
			>
				{/*<MenuItem onClick={handleOpenTransferModal}>
					{i18n.t("ticketOptionsMenu.transfer")}
				</MenuItem>*/}
				<MenuItem onClick={()=>{setConfirmationCloseOpen(true)}}>
					Fechar
				</MenuItem>
				<Can
					role={user.profile}
					perform="ticket-options:deleteTicket"
					yes={() => (
						<MenuItem onClick={handleOpenConfirmationModal}>
							{i18n.t("ticketOptionsMenu.delete")}
						</MenuItem>
					)}
				/>
			</Menu>
			<ConfirmationModal
				title={`${i18n.t("ticketOptionsMenu.confirmationModal.title")}${
					ticket.id
				} ${i18n.t("ticketOptionsMenu.confirmationModal.titleFrom")} ${
					ticket.contact.name
				}?`}
				open={confirmationOpen}
				onClose={setConfirmationOpen}
				onConfirm={handleDeleteTicket}
			>
				{i18n.t("ticketOptionsMenu.confirmationModal.message")}
			</ConfirmationModal>
			<ConfirmationModal
				title={`Fechar ticket ${ticket.id} do contato ${ticket.contact.name}`}
				open={confirmationCloseOpen}
				onClose={setConfirmationCloseOpen}
				onConfirm={handleCloseTicket}
			>
				Essa ação encerrará o ticket sem enviar a mensagem de despedida e sem enviar protocolo. Deseja continuar?
			</ConfirmationModal>
		</>
	);
};

export default TicketOptionsMenu;
