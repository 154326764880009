import React, {useEffect, useRef, useState} from "react";
import GaugeChart from 'react-gauge-chart'
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import Title from "./Title";
import {makeStyles} from "@material-ui/core/styles";
import api from "../../services/api";
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
    container: {
        position: "relative",
        width: "100%",
        height: "300px",
    },
    gaugeLabel: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        fontSize: "12px",
        color: "black",
        fontWeight: "bold",
    },
    labelVeryLow: {
        top: "50%",
        left: "10%",
    },
    labelLow: {
        top: "12%",
        left: "25%",
    },
    labelMedium: {
        top: "1%",
        left: "50%",
    },
    labelHigh: {
        top: "12%",
        left: "75%",
    },
    labelVeryHigh: {
        top: "50%",
        left: "90%",
    },
    labelNPS: {
        top: "80%",
        left: "50%",
    }
}));

const ChartNPS = ({firstDate, lastDate, queueId, userId}) => {
    const classes = useStyles();
    const colors = [
        "#FF0000",  // Vermelho
        "#FF8000",  // Laranja
        "#FFFF00",  // Amarelo
        "#80FF00",  // Verde-limão
        "#00FF00"   // Verde
    ];
    const fd = useRef(firstDate.toISOString());
    const ld = useRef(lastDate.toISOString());
    const [percent, setPercent] = useState(0.01);

    const calcAverage = (atendimentos) => {
        if (atendimentos.length === 0) return 0; // Verifica se a array está vazia

        // Soma todas as avaliações (rate)
        const somaAvaliacoes = atendimentos.reduce((soma, atendimento) => soma + atendimento.rate, 0);

        // Calcula a média das avaliações
        const mediaAvaliacoes = somaAvaliacoes / atendimentos.length;

        // Converte a média para a escala de 1 a 100
        const mediaEscala100 = (mediaAvaliacoes / 5) * 100;

        return mediaEscala100;
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async() => {
                try {
                    const { data } = await api.get("/ticketNPS",{
                        params: {
                            firstDate: fd.current,
                            lastDate: ld.current,
                            queueId,
                            userId
                        },
                    });

                    if (data && data.length > 0){
                        const average = calcAverage(data);
                        setTimeout(()=>setPercent(average / 100), 1200);
                    } else {
                        setPercent(1)
                    }
                } catch (err) {
                    toast.error(err)
                }
            }

            fetchTickets()
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [queueId, userId]);

    return (
        <React.Fragment>
            <Title>
                <span>N.P.S.</span>
                <span title="Gráfico afetado pelo filtro de data" style={{float: "right"}}><CalendarTodayIcon/></span>
            </Title>
            <div className={classes.container}>
                <GaugeChart
                    id="gauge-chart5"
                    textColor="black"
                    nrOfLevels={420}
                    arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2]}
                    colors={colors}
                    percent={percent}
                    arcPadding={0.02}
                    formatTextValue={(vr)=> {
                        let emoji = "😠";  // Valor padrão

                        if (vr >= 80)
                            emoji = "😁";
                        else if (vr >= 60)
                            emoji = "😊";
                        else if (vr >= 40)
                            emoji = "😐";
                        else if (vr >= 20)
                            emoji = "😞";

                        // Adiciona um span ao redor do emoji com um tamanho de fonte menor
                        return `      ${emoji}      `;
                    }}
                />
                {/* Labels */}
                <div className={`${classes.gaugeLabel} ${classes.labelVeryLow}`}>Péssimo</div>
                <div className={`${classes.gaugeLabel} ${classes.labelLow}`}>Ruim</div>
                <div className={`${classes.gaugeLabel} ${classes.labelMedium}`}>Médio</div>
                <div className={`${classes.gaugeLabel} ${classes.labelHigh}`}>Bom</div>
                <div className={`${classes.gaugeLabel} ${classes.labelVeryHigh}`}>Ótimo</div>
                <div className={`${classes.gaugeLabel} ${classes.labelNPS}`}>NPS - {((percent) * 5).toFixed(2)}</div>
            </div>
        </React.Fragment>
    );
};

export default ChartNPS;
