import React, {useState, useEffect, useReducer, useRef, useContext} from "react";

import { isSameDay, parseISO, format } from "date-fns";
import openSocket from "socket.io-client";
import clsx from "clsx";

import { green } from "@material-ui/core/colors";
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import {
  DoneAll,
  ExpandMore,
  GetApp,
} from "@material-ui/icons";

import MarkdownWrapper from "../MarkdownWrapper";
import VcardPreview from "../VcardPreview";
import LocationPreview from "../LocationPreview";
import ModalImageCors from "../ModalImageCors";
import MessageOptionsMenu from "../MessageOptionsMenu";
import whatsBackground from "../../assets/wa-background.png";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import {AuthContext} from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  messagesListWrapper: {
    overflow: "hidden",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },

  messageCenter: {
    marginTop: 5,
    alignItems: "center",
    verticalAlign: "center",
    alignContent: "center",
    backgroundColor: "#E1F5FEEB",
    fontSize: "12px",
    minWidth: 100,
    maxWidth: 270,
    color: "#272727",
    borderRadius: "5px",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 0,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  messagesList: {
    backgroundImage: `url(${whatsBackground})`,
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    padding: "20px 20px 20px 20px",
    overflowY: "scroll",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "90px",
    },
    ...theme.scrollbarStyles,
  },

  circleLoading: {
    color: green[500],
    position: "absolute",
    opacity: "70%",
    top: 0,
    left: "50%",
    marginTop: 12,
  },

  messageLeft: {
    marginRight: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#ffffff",
    color: "#303030",
    alignSelf: "flex-start",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerLeft: {
    margin: "-3px -80px 6px -6px",
    overflow: "hidden",
    backgroundColor: "#f0f0f0",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsg: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    display: "block",
    whiteSpace: "pre-wrap",
    overflow: "hidden",
  },

  quotedSideColorLeft: {
    flex: "none",
    width: "4px",
    backgroundColor: "#6bcbef",
  },

  messageRight: {
    marginLeft: 20,
    marginTop: 2,
    minWidth: 100,
    maxWidth: 600,
    height: "auto",
    display: "block",
    position: "relative",
    "&:hover #messageActionsButton": {
      display: "flex",
      position: "absolute",
      top: 0,
      right: 0,
    },

    whiteSpace: "pre-wrap",
    backgroundColor: "#dcf8c6",
    color: "#303030",
    alignSelf: "flex-end",
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 0,
    paddingLeft: 5,
    paddingRight: 5,
    paddingTop: 5,
    paddingBottom: 0,
    boxShadow: "0 1px 1px #b3b3b3",
  },

  quotedContainerRight: {
    margin: "-3px -80px 6px -6px",
    overflowY: "hidden",
    backgroundColor: "#cfe9ba",
    borderRadius: "7.5px",
    display: "flex",
    position: "relative",
  },

  quotedMsgRight: {
    padding: 10,
    maxWidth: 300,
    height: "auto",
    whiteSpace: "pre-wrap",
  },

  quotedSideColorRight: {
    flex: "none",
    width: "4px",
    backgroundColor: "#35cd96",
  },

  messageActionsButton: {
    display: "none",
    position: "relative",
    color: "#999",
    zIndex: 1,
    backgroundColor: "inherit",
    opacity: "90%",
    "&:hover, &.Mui-focusVisible": { backgroundColor: "inherit" },
  },

  messageContactName: {
    display: "flex",
    color: "#6bcbef",
    fontWeight: 500,
  },

  textContentItem: {
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  textContentItemDeleted: {
    fontStyle: "italic",
    color: "rgba(0, 0, 0, 0.36)",
    overflowWrap: "break-word",
    padding: "3px 80px 6px 6px",
  },

  messageMedia: {
    objectFit: "cover",
    width: 250,
    height: 200,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },

  timestamp: {
    fontSize: 11,
    position: "absolute",
    bottom: 0,
    right: 5,
    color: "#999",
  },

  dailyTimestamp: {
    alignItems: "center",
    textAlign: "center",
    alignSelf: "center",
    width: "110px",
    backgroundColor: "#e1f3fb",
    margin: "10px",
    borderRadius: "10px",
    boxShadow: "0 1px 1px #b3b3b3",
  },

  dailyTimestampText: {
    color: "#808888",
    padding: 8,
    alignSelf: "center",
    marginLeft: "0px",
  },

  ackIcons: {
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  deletedIcon: {
    fontSize: 18,
    verticalAlign: "middle",
    marginRight: 4,
  },

  ackDoneAllIcon: {
    color: green[500],
    fontSize: 18,
    verticalAlign: "middle",
    marginLeft: 4,
  },

  downloadMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "inherit",
    padding: 10,
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_MESSAGES") {
    const messages = action.payload;
    const newMessages = [];

    messages.forEach((message) => {
      const messageIndex = state.findIndex((m) => m.id === message.id);
      if (messageIndex !== -1) {
        state[messageIndex] = message;
      } else {
        newMessages.push(message);
      }
    });

    return [...newMessages, ...state];
  }

  if (action.type === "ADD_MESSAGE") {
    const newMessage = action.payload;
    const messageIndex = state.findIndex((m) => m.id === newMessage.id);

    if (messageIndex !== -1) {
      state[messageIndex] = newMessage;
    } else {
      state.push(newMessage);
    }

    return [...state];
  }

  if (action.type === "UPDATE_MESSAGE") {
    const messageToUpdate = action.payload;
    const messageIndex = state.findIndex((m) => m.id === messageToUpdate.id);

    if (messageIndex !== -1) {
      state[messageIndex] = messageToUpdate;
    }

    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const ChatMessagesList = ({ chatId, isGroup }) => {
  const classes = useStyles();

  const [messagesList, dispatch] = useReducer(reducer, []);
  const [pageNumber, setPageNumber] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [loading, setLoading] = useState(false);
  const lastMessageRef = useRef();

  const [selectedMessage] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const messageOptionsMenuOpen = Boolean(anchorEl);
  const currentTicketId = useRef(chatId);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);

    currentTicketId.current = chatId;
  }, [chatId]);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchMessages = async () => {
        try {
          // const { data } = await api.get("/chats/messages/" + chatId, {params: { pageNumber, user }});
          const { data } = await api.post("/chats/messages/get", {chatId, pageNumber, user});

          if (currentTicketId.current === chatId) {
            dispatch({ type: "LOAD_MESSAGES", payload: data.messages });
            setHasMore(data.hasMore);
            setLoading(false);
          }

          if (pageNumber === 1 && data.messages.length > 1) {
            scrollToBottom();
          }
        } catch (err) {
          setLoading(false);
          toastError(err);
        }
      };
      fetchMessages();
    }, 500);
    return () => {
      clearTimeout(delayDebounceFn);
    };
  }, [pageNumber, chatId, user]);

  useEffect(() => {
    const socket = openSocket(window.env?.REACT_APP_BACKEND_URL || process.env.REACT_APP_BACKEND_URL);

    socket.on("connect", () => {
      // console.log("MessageList Connected")
      socket.emit("joinChatBoxChat", chatId)
    });

    socket.on("appMessageChat", (data) => {
      // console.log("MessageList appMessageChat")
      if (data.action === "create") {
        dispatch({ type: "ADD_MESSAGE", payload: data.message });
        scrollToBottom();
        if (parseInt(data.message?.senderId) !== user.id)
          setMessageAsRead(data.message);
      }

      if (data.action === "update") {
        dispatch({ type: "UPDATE_MESSAGE", payload: data.message });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, [chatId, user]);

  const loadMore = () => {
    setPageNumber((prevPageNumber) => prevPageNumber + 1);
  };

  const scrollToBottom = () => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({});
    }
  };

  const setMessageAsRead = async message => {
      await api.post(`/chats/messages/read/${message.id}`, {});
  }

  const handleScroll = (e) => {
    if (!hasMore) return;
    const { scrollTop } = e.currentTarget;

    if (scrollTop === 0) {
      document.getElementById("messagesList").scrollTop = 1;
    }

    if (loading) {
      return;
    }

    if (scrollTop < 50) {
      loadMore();
    }
  };

  const handleCloseMessageOptionsMenu = (e) => {
    setAnchorEl(null);
  };

  const checkMessageMedia = (message) => {
    if(message.mediaType === "location" && message.body.split('|').length >= 2) {
      let locationParts = message.body.split('|')
      let imageLocation = locationParts[0]
      let linkLocation = locationParts[1]

      let descriptionLocation = null

      if(locationParts.length > 2)
        descriptionLocation = message.body.split('|')[2]

      return <LocationPreview image={imageLocation} link={linkLocation} description={descriptionLocation} />
    }
    else if (message.mediaType === "vcard") {
      let array = message.body.split("\n");
      let obj = [];
      let contact = "";
      for (let index = 0; index < array.length; index++) {
        const v = array[index];
        let values = v.split(":");
        for (let ind = 0; ind < values.length; ind++) {
          if (values[ind].indexOf("+") !== -1) {
            obj.push({ number: values[ind] });
          }
          if (values[ind].indexOf("FN") !== -1) {
            contact = values[ind + 1];
          }
        }
      }

      /*let contact = String;
      let number = String;

      let re = /(begin|end|version|cell|pref|adr|nickname|photo|bday|anniversary|gender|tel|email|impp|lang|tz|geo|title|role|logo|org|member|related|categories|note|prodid|rev|sound|uid|fn|n):(.*?)(?=(?:begin|end|version|cell|pref|adr|nickname|photo|bday|anniversary|gender|tel|email|impp|lang|tz|geo|title|role|logo|org|member|related|categories|note|prodid|rev|sound|uid|fn|n):|\n|$)/gi;
      let str = message.body;
      let m;

      while ((m = re.exec(str)) !== null) {
        console.log(m);
        if (m.index === re.lastIndex) {
          re.lastIndex++;
        }
        if (m[1].toLowerCase() === "n") {
          contact = m[2].replace(/;/g, '');
        }
        else if (m[1].toLowerCase() === "org") {
          /!*document.write("Org: " + m[2].replace(/;/g, '') + "<br/>");*!/
        }
        else if (m[1].toLowerCase().indexOf("tel") === 0 ||
            m[1].toLowerCase().indexOf("cell") === 0 ||
            m[1].toLowerCase().indexOf("pref") === 0) {
          number =  m[2].replace(/[ +-/(/)/;]/g,'')
        }
      }*/
      // return <VcardPreview contact={contact} numbers={number} />
      return <VcardPreview contact={contact} numbers={obj[0].number} />
    }
    /*else if (message.mediaType === "multi_vcard") {
          console.log("multi_vcard")
          console.log(message)

          if(message.body !== null && message.body !== "") {
              let newBody = JSON.parse(message.body)
              return (
                  <>
                    {
                      newBody.map(v => (
                        <VcardPreview contact={v.name} numbers={v.number} />
                      ))
                    }
                  </>
              )
          } else return (<></>)
      }*/
    else if (message.mediaType === "image") {
      return <ModalImageCors imageUrl={message.mediaUrl} />;
    } else if (message.mediaType === "audio") {
      return (
          <audio controls>
            <source src={message.mediaUrl} type="audio/ogg"></source>
          </audio>
      );
    } else if (message.mediaType === "video") {
      return (
          <video
              className={classes.messageMedia}
              src={message.mediaUrl}
              controls
          />
      );
    } else {
      return (
          <>
            <div className={classes.downloadMedia}>
              <Button
                  startIcon={<GetApp />}
                  color="primary"
                  variant="outlined"
                  target="_blank"
                  href={message.mediaUrl}
              >
                Download
              </Button>
            </div>
            <Divider />
          </>
      );
    }
  };

  const renderMessageAck = (message) => {
    // if (message.ack === 0) {
      // return <AccessTime fontSize="small" className={classes.ackIcons} />;
      // return <Done fontSize="small" className={classes.ackIcons} />;
    // }
    // if (message.ack === 1) {
    //   return <Done fontSize="small" className={classes.ackIcons} />;
    // }
    if (message.ack === 2) {
      return <DoneAll fontSize="small" className={classes.ackIcons} />;
    }
    // if (message.ack === 3 || message.ack === 4) {
    //   return <DoneAll fontSize="small" className={classes.ackDoneAllIcon} />;
    // }
  };

  const renderDailyTimestamps = (message, index) => {
    if (index === 0) {
      return (
          <span
              className={classes.dailyTimestamp}
              key={`timestamp-${message.id}`}
          >
          <div className={classes.dailyTimestampText}>
            {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
          </div>
        </span>
      );
    }
    if (index < messagesList.length - 1) {
      let messageDay = parseISO(messagesList[index].createdAt);
      let previousMessageDay = parseISO(messagesList[index - 1].createdAt);

      if (!isSameDay(messageDay, previousMessageDay)) {
        return (
            <span
                className={classes.dailyTimestamp}
                key={`timestamp-${message.id}`}
            >
            <div className={classes.dailyTimestampText}>
              {format(parseISO(messagesList[index].createdAt), "dd/MM/yyyy")}
            </div>
          </span>
        );
      }
    }
    if (index === messagesList.length - 1) {
      return (
          <div
              key={`ref-${message.createdAt}`}
              ref={lastMessageRef}
              style={{ float: "left", clear: "both" }}
          />
      );
    }
  };

  const renderMessageDivider = (message, index) => {
    if (index < messagesList.length && index > 0) {
      let messageUser = parseInt(messagesList[index].senderId) === user?.id;
      let previousMessageUser = parseInt(messagesList[index - 1].senderId) === user?.id;

      if (messageUser !== previousMessageUser) {
        return (
            <span style={{ marginTop: 16 }} key={`divider-${message.id}`}></span>
        );
      }
    }
  };

  const renderMessages = () => {
    if (messagesList.length > 0) {
      const viewMessagesList = messagesList.map((message, index) => {
        /*if (message.mediaType === "call_log") {
          return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}
                <div className={classes.messageCenter}>
                  <IconButton
                      variant="contained"
                      size="small"
                      id="messageActionsButton"
                      disabled={message.isDeleted}
                      className={classes.messageActionsButton}
                      onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                  >
                    <ExpandMore />
                  </IconButton>
                  {isGroup && (
                      <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                  )}
                  <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 17" width="20" height="17">
                      <path fill="#df3333" d="M18.2 12.1c-1.5-1.8-5-2.7-8.2-2.7s-6.7 1-8.2 2.7c-.7.8-.3 2.3.2 2.8.2.2.3.3.5.3 1.4 0 3.6-.7 3.6-.7.5-.2.8-.5.8-1v-1.3c.7-1.2 5.4-1.2 6.4-.1l.1.1v1.3c0 .2.1.4.2.6.1.2.3.3.5.4 0 0 2.2.7 3.6.7.2 0 1.4-2 .5-3.1zM5.4 3.2l4.7 4.6 5.8-5.7-.9-.8L10.1 6 6.4 2.3h2.5V1H4.1v4.8h1.3V3.2z"></path>
                    </svg> <span>Chamada de voz/vídeo perdida às {format(parseISO(message.createdAt), "HH:mm")}</span>
                  </div>
                </div>
              </React.Fragment>
          );
        }*/
        if (parseInt(message.senderId) !== user?.id) {
          return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}
                <div className={classes.messageLeft}>
                  <IconButton
                      variant="contained"
                      size="small"
                      id="messageActionsButton"
                      // disabled={message.isDeleted}
                      className={classes.messageActionsButton}
                      // onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                  >
                    <ExpandMore />
                  </IconButton>
                  {/*{isGroup && (
                      <span className={classes.messageContactName}>
                    {message.contact?.name}
                  </span>
                  )}*/}
                  {(message.mediaUrl || message.mediaType === "location" || message.mediaType === "vcard"
                      //|| message.mediaType === "multi_vcard"
                  ) && checkMessageMedia(message)}
                  <div className={classes.textContentItem}>
                    {/*{message.quotedMsg && renderQuotedMessage(message)}*/}
                    <MarkdownWrapper>{message.body}</MarkdownWrapper>
                    <span className={classes.timestamp}>
                    {format(parseISO(message.createdAt), "HH:mm")}
                  </span>
                  </div>
                </div>
              </React.Fragment>
          );
        } else {
          return (
              <React.Fragment key={message.id}>
                {renderDailyTimestamps(message, index)}
                {renderMessageDivider(message, index)}
                <div className={classes.messageRight}>
                  <IconButton
                      variant="contained"
                      size="small"
                      id="messageActionsButton"
                      // disabled={message.isDeleted}
                      className={classes.messageActionsButton}
                      // onClick={(e) => handleOpenMessageOptionsMenu(e, message)}
                  >
                    <ExpandMore />
                  </IconButton>
                  {(message.mediaUrl || message.mediaType === "location" || message.mediaType === "vcard"
                      //|| message.mediaType === "multi_vcard"
                  ) && checkMessageMedia(message)}
                  <div
                      className={clsx(classes.textContentItem, {
                        [classes.textContentItemDeleted]: false,
                        // [classes.textContentItemDeleted]: message.isDeleted,
                      })}
                  >
                    {/*{message.isDeleted && (
                        <Block
                            color="disabled"
                            fontSize="small"
                            className={classes.deletedIcon}
                        />
                    )}*/}
                    {/*{message.quotedMsg && renderQuotedMessage(message)}*/}
                    <MarkdownWrapper>{message.body}</MarkdownWrapper>
                    <span className={classes.timestamp}>
                    {format(parseISO(message.createdAt), "HH:mm")}
                      {renderMessageAck(message)}
                  </span>
                  </div>
                </div>
              </React.Fragment>
          );
        }
      });
      return viewMessagesList;
    } else {
      return <div>Diga olá ao seu novo contato!</div>;
    }
  };

  return (
      <div className={classes.messagesListWrapper}>
        <MessageOptionsMenu
            message={selectedMessage}
            anchorEl={anchorEl}
            menuOpen={messageOptionsMenuOpen}
            handleClose={handleCloseMessageOptionsMenu}
        />
        <div
            id="messagesList"
            className={classes.messagesList}
            onScroll={handleScroll}
        >
          {messagesList.length > 0 ? renderMessages() : []}
        </div>
        {loading && (
            <div>
              <CircularProgress className={classes.circleLoading} />
            </div>
        )}
      </div>
  );
};

export default ChatMessagesList;