import React, {useState, useEffect, useRef} from "react";

import {makeStyles} from "@material-ui/core/styles";
import {green} from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import whatsBackground from "../../assets/wa-background.png";
import {i18n} from "../../translate/i18n";

import ContactSelect from "../ContactSelect";
import api from "../../services/api";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
    },
    textField: {
        marginRight: theme.spacing(1),
        flex: 1,
    },

    extraAttr: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    btnWrapper: {
        position: "relative",
    },

    buttonProgress: {
        color: green[500],
        position: "absolute",
        top: "50%",
        left: "50%",
        marginTop: -12,
        marginLeft: -12,
    },
    messagesList: {
        backgroundImage: `url(${whatsBackground})`,
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
        borderRadius: 10,
        marginTop: 10,
        padding: "20px 20px 20px 20px",
        overflowY: "scroll",
        [theme.breakpoints.down("sm")]: {
            paddingBottom: "90px",
        },
        ...theme.scrollbarStyles,
    },
    messageRight: {
        marginLeft: 20,
        marginTop: 2,
        minWidth: 100,
        maxWidth: 600,
        height: "auto",
        display: "block",
        position: "relative",
        "&:hover #messageActionsButton": {
            display: "flex",
            position: "absolute",
            top: 0,
            right: 0,
        },

        whiteSpace: "pre-wrap",
        backgroundColor: "#dcf8c6",
        color: "#303030",
        alignSelf: "flex-end",
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomLeftRadius: 8,
        borderBottomRightRadius: 0,
        paddingLeft: 5,
        paddingRight: 5,
        paddingTop: 5,
        paddingBottom: 0,
        boxShadow: "0 1px 1px #b3b3b3",
    },
    textContentItem: {
        overflowWrap: "break-word",
        padding: "3px 80px 6px 6px",
    },

    textContentItemDeleted: {
        fontStyle: "italic",
        color: "rgba(0, 0, 0, 0.36)",
        overflowWrap: "break-word",
        padding: "3px 80px 6px 6px",
    },
    timestamp: {
        fontSize: 11,
        position: "absolute",
        bottom: 0,
        right: 5,
        color: "#999",
    },
    ackIcons: {
        fontSize: 18,
        verticalAlign: "middle",
        marginLeft: 4,
    },
    downloadMedia: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "inherit",
        padding: 10,
    },
    dailyLog: {
        alignItems: "center",
        textAlign: "center",
        alignSelf: "center",
        width: "450px",
        backgroundColor: "#e1f3fb",
        margin: "10px",
        borderRadius: "10px",
        boxShadow: "0 1px 1px #b3b3b3",
        "&:hover": {
            cursor: "pointer"
        }
    },
    dailyTimestampText: {
        color: "#808888",
        padding: 8,
        alignSelf: "center",
        marginLeft: "0px",
    },
}));

const VcardModal = ({open, onClose, ticketId, initialValues, onSave}) => {
    const classes = useStyles();
    const isMounted = useRef(true);

    // const {user} = useContext(AuthContext);
    const [selectedContactIds, setSelectedContactIds] = useState(null);

    useEffect(() => {
        return () => {
            isMounted.current = false;
        };
    }, []);

    const handleClose = () => {
        onClose();
        setSelectedContactIds(null);
    };

	const handleSave = async () => {
        if (ticketId && selectedContactIds) {
            await api.post(`/messages/vcard/${ticketId}`, {contactId: selectedContactIds.value});
            handleClose();
        }
    };

    return (
        <div className={classes.root}>
            <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth scroll="paper">
                <DialogTitle id="form-dialog-title">
                    Enviar contato
                </DialogTitle>

                <DialogContent dividers>
                    <Typography variant="subtitle1">Escolha um contato para ser enviado</Typography>
                    <div>
                        <ContactSelect
                            selectedContactIds={selectedContactIds}
                            isMulti={false}
                            onChange={(selectedIds) => {
                                setSelectedContactIds(selectedIds);
                            }}
                        />
                    </div>

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        // disabled={isSubmitting}
                        variant="outlined"
                    >
                        {i18n.t("contactModal.buttons.cancel")}
                    </Button>
                    <Button
                        type="button"
                        color="primary"
                        disabled={!selectedContactIds}
                        variant="contained"
                        className={classes.btnWrapper}
						onClick={handleSave}
                    >
                        Enviar
                        {/*{isSubmitting && (
                            <CircularProgress
                                size={24}
                                className={classes.buttonProgress}
                            />
                        )}*/}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default VcardModal;
