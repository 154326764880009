import React, {useEffect, useState} from "react";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow, TextField, Tooltip,
  withStyles,
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { DeleteOutline, Edit, HelpOutlineOutlined } from "@material-ui/icons";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Nps = () => {
  const classes = useStyles();

  const [npss, setNpss] = useState([]);
  const [loading, setLoading] = useState(false);

  const date = new Date();
  const [firstDate, setFirstDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
  const [lastDate, setLastDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/ticketNPS",{
          params: {
            firstDate: firstDate,
            lastDate: lastDate,
            // queueId,
            // userId
          },
        });

        setNpss(data);
        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, [firstDate, lastDate]);

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  return (
    <MainContainer>
      {/*<QueueModal
        open={queueModalOpen}
        onClose={handleCloseQueueModal}
        queueId={selectedQueue?.id}
      />*/}
      <MainHeader>
        <Title>N.P.S.</Title>
        <MainHeaderButtonsWrapper>
          <TextField
              placeholder="01/01/2024"
              type="date"
              value={firstDate?.toLocaleDateString("en-CA")}
              onChange={(e) => {
                const date = new Date(`${e.target.value}T12:00:00`);
                setFirstDate(date)
              }}
              /*InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: "gray" }} />
                    </InputAdornment>
                ),
              }}*/
          />
          <TextField
              placeholder="31/01/2024"
              type="date"
              value={lastDate?.toLocaleDateString("en-CA")}
              onChange={(e)=> {
                const date = new Date(`${e.target.value}T12:00:00`);
                setLastDate(date);
              }}
          />
          <LightTooltip
              title={
                <div style={{ whiteSpace: 'pre-line' }}>{"N.P.S. (Net Promoter Score) é a avaliação da sua empresa pelo seus clientes"}</div>
              }
          >
            <Button>
              <HelpOutlineOutlined />
            </Button>
          </LightTooltip>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                Contato
              </TableCell>
              <TableCell align="center">
                Avaliação
              </TableCell>
              <TableCell align="center">
                Usuário
              </TableCell>
              <TableCell align="center">
                Data da avaliação
              </TableCell>
              <TableCell align="center">
                Conexão
              </TableCell>
              <TableCell align="center">
                {i18n.t("queues.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {npss.map((nps) => (
                <TableRow key={nps.id}>
                  <TableCell align="center">{nps.contact.name}</TableCell>
                  <TableCell align="center">{'⭐'.repeat(nps.rate, 10)}</TableCell>
                  <TableCell align="center">{nps.user?.name}</TableCell>
                  <TableCell align="center">{new Date(nps.createdAt).toLocaleString('pt-BR', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit'})}</TableCell>
                  <TableCell align="center">{nps.whatsapp?.name}</TableCell>
                  <TableCell align="center">
                    <IconButton
                        size="small"
                        onClick={() => {
                          const novaUrl = `${window.location.origin}/tickets/${nps.ticketId}`;
                          window.open(novaUrl, '_blank');
                        }}
                    >
                      <WhatsAppIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      disabled
                      // onClick={() => handleEditQueue(nps)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      disabled
                      // onClick={() => {}}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={5} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Nps;
