import React, {useState, useEffect, useRef, useContext} from "react";

import * as Yup from "yup";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import CircularProgress from "@material-ui/core/CircularProgress";

// import { i18n } from "../../translate/i18n";

import api from "../../services/api";
import toastError from "../../errors/toastError";
import { IconButton } from "@material-ui/core";
import { Colorize } from "@material-ui/icons";
import {ChromePicker} from "react-color";
import ContactSelect from "../ContactSelect";
import {AuthContext} from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	textField: {
		marginRight: theme.spacing(1),
		flex: 1,
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	colorAdorment: {
		width: 20,
		height: 20,
	},
}));

const TagSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, "Too Short!")
		.max(50, "Too Long!")
		.required("Required"),
	color: Yup.string().min(3, "Too Short!").max(9, "Too Long!").required(),
});

const TagModal = ({ open, onClose, tagId }) => {
	const classes = useStyles();

	const initialState = {
		name: "",
		color: {hex: "#7a7a7a"},
		contacts: []
	};

	// const [colorPickerModalOpen, setColorPickerModalOpen] = useState(false);
	const [displayColorPicker, setDisplayColorPicker] = useState(false);
	const [showColorWarning, setShowColorWarning] = useState(true);
	// const [selectedContactIds, setSelectedContactIds] = useState([]);
	const [color, setColor] = useState({hex: "#7a7a7a"});
	const [tag, setTag] = useState(initialState);
	const buttonRef = useRef();
	const { user } = useContext(AuthContext);

	useEffect(() => {
		(async () => {
			if (!tagId) return;
			try {
				const { data } = await api.get(`/tag/${tagId}`);
				data.contacts = data.contacts.map(contact => {
					return contact.id;
				})
				setTag(prevState => {
					return { ...prevState, ...data };
				});

				setColor({hex: data.color});
			} catch (err) {
				toastError(err);
			}
		})();

		return () => {
			setTag({
				name: "",
				color: {hex: "#7a7a7a"},
				contacts: []
			});
		};
	}, [tagId, open]);

	const handleClose = () => {
		setDisplayColorPicker(false);
		onClose();
		setTag(initialState);
		setShowColorWarning(true);
		setColor({hex: "#7a7a7a"});
	};

	const handleSaveTag = async values => {
		try {
			if (tagId) {
				values = {
					...values,
					contacts: values.contacts.join(",")
				}
				await api.put(`/tag/${tagId}`, values);

				await api.post("/log", {
					log: {
						descricao: `O usuário ${user?.name} alterou a tag ${values.name} (${tagId})`,
						origem: 'Tags',
						userId: user?.id,
						tagId
					}
				});
			} else {
				const { data } = await api.post("/tag", values);

				await api.post("/log", {
					log: {
						descricao: `O usuário ${user?.name} criou a tag ${values.name} (${data.id})`,
						origem: 'Tags',
						userId: user?.id,
						tagId: data.id
					}
				});
			}
			toast.success("Tag saved successfully");
			handleClose();
		} catch (err) {
			toastError(err);
		}
	};

	return (
		<div className={classes.root}>
			<Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth scroll="paper">
				<DialogTitle>
					{tagId
						? `Editar tag`
						: `Adicionar tag`}
				</DialogTitle>
				<Formik
					initialValues={tag}
					enableReinitialize={true}
					validationSchema={TagSchema}
					onSubmit={(values, actions) => {
						setTimeout(() => {
							handleSaveTag(values);
							actions.setSubmitting(false);
						}, 400);
					}}
				>
					{({ touched, errors, isSubmitting, values }) => (
						<Form>
							<DialogContent style={{minHeight: "350px"}} dividers>
								<Field
									as={TextField}
									label="Nome"
									autoFocus
									name="name"
									inputRef={buttonRef}
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									className={classes.textField}
								/>

								{/*<Field
									label="Nome"
									autoFocus
									name="name"
									inputRef={buttonRef}
									error={touched.name && Boolean(errors.name)}
									helperText={touched.name && errors.name}
									variant="outlined"
									margin="dense"
									className={classes.textField}
									render={({ field  }) => (

									)}
								/>*/}

								<div
									style={{ width:"auto", display: "inline-block", marginTop: "8px", marginBottom: "4px"}}
								>
									<div
										style={ {padding: '5px', background: '#fff', borderRadius: '1px', boxShadow: '0 0 0 1px rgba(0,0,0,.1)', display: 'inline-flex', cursor: 'pointer', alignItems: "center"} }
										onClick={ () => {
											setDisplayColorPicker(!displayColorPicker);
										}}
									>
										<div
											style={ {width: '60px', height: '30px', borderRadius: '2px', background: color.hex, marginLeft: "10px"} }
										/>
										<span style={{marginLeft: "12px", marginRight: "12px"}}>{color.hex}</span>
										<IconButton
											size="small"
											color="default"
											onClick={() => setDisplayColorPicker(true)}
										>
											<Colorize />
										</IconButton>
									</div>
									{ displayColorPicker ?
										<div style={ {position: 'absolute', zIndex: '2',} }>
											<div
												style={ {position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px',} }
												onClick={ () => {setDisplayColorPicker(false)} }
											/>
											<ChromePicker
												disableAlpha={true}
												color={ color }
												onChangeComplete ={(cor) => {
													setShowColorWarning(false);
													setColor({hex: cor.hex});
													values.color = cor.hex;
													setTag(() => {
														return { ...values, color: cor.hex };
													});
												}}
											/>
										</div>
										: null }
								</div>

								<div
									style={{display: "inline-flex", justifyContent: "center", marginTop: 0, marginLeft: "8px", marginBottom: "15px", alignItems: "center", verticalAlign: "middle", textAlign: "center"}}
								>
									<span style={{cursor: 'pointer', color: "red", fontSize: "9pt"}} hidden={!showColorWarning}>{!tagId ? "Escolha outra cor" : null}</span>
								</div>
								<ContactSelect
									selectedContactIds={tag.contacts}
									onChange={(selectedIds) => {
										values.contacts = selectedIds;
										setTag(() => {
											return { ...values, contacts: selectedIds };
										});
										// setSelectedContactIds(selectedIds);
									}}
								/>

								{/*<Field
									as={TextField}
									label="Cor"
									name="color"
									id="color"
									onFocus={() => {
										setColorPickerModalOpen(true);
										buttonRef.current.focus();
									}}
									error={touched.color && Boolean(errors.color)}
									helperText={touched.color && errors.color}
									InputProps={{
										startAdornment: (
											<InputAdornment position="start">
												<div
													style={{ backgroundColor: values.color }}
													className={classes.colorAdorment}
												></div>
											</InputAdornment>
										),
										endAdornment: (
											<IconButton
												size="small"
												color="default"
												onClick={() => setColorPickerModalOpen(true)}
											>
												<Colorize />
											</IconButton>
										),
									}}
									variant="outlined"
									margin="dense"
								/>
								<ColorPicker
									open={colorPickerModalOpen}
									handleClose={() => setColorPickerModalOpen(false)}
									onChange={color => {
										values.color = color;
										setTag(() => {
											return { ...values, color };
										});
									}}
								/>*/}
							</DialogContent>
							<DialogActions>
								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									Cancelar
								</Button>
								<Button
									type="submit"
									color="primary"
									disabled={isSubmitting}
									variant="contained"
									className={classes.btnWrapper}
								>
									{tagId
										? `Salvar`
										: `Adicionar`}
									{isSubmitting && (
										<CircularProgress
											size={24}
											className={classes.buttonProgress}
										/>
									)}
								</Button>
							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

export default TagModal;
