import React, { useState, useContext } from "react";

import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Typography,
  Container,
  InputAdornment,
  IconButton,
} from '@material-ui/core';

import { LockOutlined, Visibility, VisibilityOff, WhatsApp } from '@material-ui/icons';

import { makeStyles } from "@material-ui/core/styles";

import { i18n } from "../../translate/i18n";

import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  alignRow: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  ml5: {
    margin: theme.spacing(1),
    textDecoration: 'none',
    color: "black",
  }
}));

const Login = () => {
  const classes = useStyles();

  const [user, setUser] = useState({ email: "", password: "" });
  const [showPassword, setShowPassword] = useState(false);

  const { handleLogin, isBlocked } = useContext(AuthContext);

  const handleChangeInput = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handlSubmit = (e) => {
    e.preventDefault();
    handleLogin(user);
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined/>
        </Avatar>
        <Typography component="h1" variant="h5">
          {i18n.t("login.title")}
        </Typography>
        <form className={classes.form} noValidate onSubmit={handlSubmit}>
          <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label={i18n.t("login.form.email")}
              name="email"
              value={user.email}
              onChange={handleChangeInput}
              autoComplete="email"
              autoFocus
          />
          <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label={i18n.t("login.form.password")}
              id="password"
              value={user.password}
              onChange={handleChangeInput}
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((e) => !e)}
                      >
                        {showPassword ? <VisibilityOff/> : <Visibility/>}
                      </IconButton>
                    </InputAdornment>
                )
              }}
          />
          <Button
              type={!isBlocked ? "submit" : "button"}
              fullWidth
              disabled={isBlocked}
              variant="contained"
              color={isBlocked ? "secondary" : "primary"}
              className={classes.submit}
          >
            {!isBlocked ? i18n.t("login.buttons.submit") : "SISTEMA BLOQUEADO"}
          </Button>
        </form>
        { isBlocked && (
          <>
            <Typography component="body" variant="subtitle1">Para mais inforções, entrar em contato</Typography>
            <div className={classes.alignRow}>
              <WhatsApp/>
              <a target="_blank" rel="noopener noreferrer" href={"https://api.whatsapp.com/send/?phone=5514997613595&text=Meu+acesso+do+WhatsCont%C3%A1bil+est%C3%A1+bloqueado%2C+como+proceder%3F&type=phone_number&app_absent=0"} className={classes.ml5}><b>Suporte</b> +55 14 99761-3595</a>
            </div>
            <div className={classes.alignRow}>
              <WhatsApp/>
              <a target="_blank" rel="noopener noreferrer" href={"https://api.whatsapp.com/send/?phone=5514997613595&text=Meu+acesso+do+WhatsCont%C3%A1bil+est%C3%A1+bloqueado%2C+como+proceder%3F&type=phone_number&app_absent=0"} className={classes.ml5}><b>Financeiro</b> +55 14 99656-7101</a>
            </div>
          </>
        )}
      </div>
    </Container>
  );
};

export default Login;
