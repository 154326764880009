import React, {useContext} from "react";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import {toast} from "react-toastify";

import { AuthContext } from "../../context/Auth/AuthContext";

const ResquestNotificationPermissionModal = ({ modalOpen, onClose }) => {

	// const [loading, setLoading] = useState(false);
	const { user, handleLogout } = useContext(AuthContext);

	/*useEffect(() => {
		const delayDebounceFn = setTimeout(() => {
			const fetchNotes = async () => {
				try {

				} catch (err) {
					toastError(err);
				}
			};
			fetchNotes();
		}, 500);
		return () => {
			clearTimeout(delayDebounceFn);
		};
	}, [])*/

	const handleClose = () => {
		onClose();
	};

	const requestPermission = () => {
		if (!("Notification" in window)) {
			console.log("This browser doesn't support notifications");
			toast.error("Seu navegador não suporta notificações");
		} else {
			Notification.requestPermission().then((permission) => {
				// If the user accepts, let's create a notification
				handleClose();
				if (permission === "granted") {
					toast.success("Notificações ativadas com sucesso! Realize o login novamente para continuar");
					handleLogout();
				}
			});
		}
	}

	return (
		<>
			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					{"Notificações"}
				</DialogTitle>
				<DialogContent dividers style={{fontSize: "12pt"}}>
					{new Date().getHours() >= 6 && new Date().getHours() <= 12 ? "Bom dia" : new Date().getHours() >= 13 && new Date().getHours() <= 18 ? "Boa tarde" : new Date().getHours() > 18 || new Date().getHours() < 6 ? "Boa noite" : "Olá"}
					<b> {user.name}!</b>
					<br/><br/>
					Deseja ativar as notificações para nosso site?
					<br/>
					Essas notificações serão usadas apenas para alertá-lo
					quando você receber novas mensagens, garantindo que você nunca perca uma conversa.
					<br/><br/>
					<span style={{color: 'red', fontStyle: 'italic', fontSize: "10pt"}}>A sua privacidade é importante para nós e só enviaremos notificações para esse fim.</span>
					<br/><br/>
					Gostaria de ativar as notificações para novas mensagens?
					<br/><br/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						// disabled={loading}
						variant="outlined"
					>
						{"Fechar"}
					</Button>
					<Button
						onClick={requestPermission}
						color="primary"
						// disabled={loading}
						variant="outlined"
					>
						Ativar!
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default ResquestNotificationPermissionModal;
