import React, { useEffect, useRef, useContext } from "react";

import { useHistory, useParams } from "react-router-dom";
import { parseISO, format, isSameDay } from "date-fns";
import clsx from "clsx";

import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Typography from "@material-ui/core/Typography";
import Avatar from "@material-ui/core/Avatar";
import Divider from "@material-ui/core/Divider";
import Badge from "@material-ui/core/Badge";


import MarkdownWrapper from "../MarkdownWrapper";
import { Tooltip } from "@material-ui/core";
import { AuthContext } from "../../context/Auth/AuthContext";

const useStyles = makeStyles(theme => ({
	ticket: {
		position: "relative",
	},

	pendingTicket: {
		cursor: "unset",
	},

	noTicketsDiv: {
		display: "flex",
		height: "100px",
		margin: 40,
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},

	noTicketsText: {
		textAlign: "center",
		color: "rgb(104, 121, 146)",
		fontSize: "14px",
		lineHeight: "1.4",
	},

	noTicketsTitle: {
		textAlign: "center",
		fontSize: "16px",
		fontWeight: "600",
		margin: "0px",
	},

	contactNameWrapper: {
		display: "flex",
		justifyContent: "space-between",
	},

	lastMessageTime: {
		justifySelf: "flex-end",
	},

	closedBadge: {
		alignSelf: "center",
		justifySelf: "center",
		marginRight: 42,
		paddingRight: 20,
		top: "-2px",
		marginLeft: "auto",
	},

	contactLastMessage: {
		paddingRight: 20,
	},

	newMessagesCount: {
		alignSelf: "center",
		marginRight: 8,
		marginLeft: "auto",
	},

	badgeStyle: {
		color: "white",
		backgroundColor: green[500],
	},

	acceptButton: {
		position: "absolute",
		left: "50%",
	},

	ticketQueueColor: {
		flex: "none",
		width: "8px",
		height: "100%",
		position: "absolute",
		top: "0%",
		left: "0%",
	},
}));

const ChatListItem = ({ chat }) => {
	const classes = useStyles();
	const history = useHistory();
	const { chatId } = useParams();
	const isMounted = useRef(true);
	const { user } = useContext(AuthContext);

	/*useEffect(() => {
		const loadQueues = async () => {
			const list = await findAllQueues();
			setQueues(list);
		}
		loadQueues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);*/

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleSelectChat = id => {
		history.push(`/tickets/0/${id}`);
	};

	return (
		<React.Fragment key={chat.id}>
			<ListItem
				dense
				button
				onClick={e => {
					// if (chat.status === "pending") return;
					handleSelectChat(chat.id);
				}}
				selected={chatId && +chatId === chat.id}
				className={clsx(classes.ticket, {
					[classes.pendingTicket]: false,
				})}
			>
				<Tooltip
					arrow
					placement="right"
					title={"Sem fila"}
				>
					<span
						style={{ backgroundColor: "#7C7C7C" }}
						className={classes.ticketQueueColor}
					></span>
				</Tooltip>
				<ListItemAvatar>
					<Avatar src={chat?.contact?.profilePicUrl} />
				</ListItemAvatar>
				<ListItemText
					disableTypography
					primary={
						<span className={classes.contactNameWrapper}>
							<Typography
								noWrap
								component="span"
								variant="body2"
								color="textPrimary"
							>
								{chat.user?.id !== user.id ? chat.user ? chat.user?.name : chat.userName ? `Usuário excluído (${chat.userName})` : "Usuário excluído" : chat.callingUser?.name ? chat.callingUser?.name : chat.callingName ? `Usuário excluído (${chat.callingName})` : "Usuário excluído"}
							</Typography>
							{/*{chat.status === "closed" && (
								<Badge overlap="rectangular"
									className={classes.closedBadge}
									badgeContent={"Encerrada"}
									color="primary"
								/>
							)}*/}
							{chat.lastMessage && (
								<Typography
									className={classes.lastMessageTime}
									component="span"
									variant="body2"
									color="textSecondary"
								>
									{isSameDay(parseISO(chat.updatedAt), new Date()) ? (
										<>{format(parseISO(chat.updatedAt), "HH:mm")}</>
									) : (
										<>{format(parseISO(chat.updatedAt), "dd/MM/yyyy")}</>
									)}
								</Typography>
							)}
						</span>
					}
					secondary={
						<span className={classes.contactNameWrapper}>
							<Typography
								className={classes.contactLastMessage}
								noWrap
								component="span"
								variant="body2"
								color="textSecondary"
							>
								{chat.lastMessage ? (
									<MarkdownWrapper>{chat.lastMessage}</MarkdownWrapper>
								) : (
									<br />
								)}
							</Typography>

							{ chat[`messagesUnreadBy${user.id}`] || chat[`messagesUnreadBy${user.id}`] === 0 ? (
								chat[`messagesUnreadBy${user.id}`] > 0 ? (
									<Badge overlap="rectangular"
										   className={classes.newMessagesCount}
										   badgeContent={chat[`messagesUnreadBy${user.id}`] ?? "0"}
										   classes={{
											   badge: classes.badgeStyle,
										   }}
									/>
								) : null
							) : (
								chat.messagesUnread > 0 ? (
									<Badge overlap="rectangular"
										   className={classes.newMessagesCount}
										   badgeContent={chat.messagesUnread ?? "0"}
										   classes={{
											   badge: classes.badgeStyle,
										   }}
									/>
								) : null
							) }
							{/*{ chat.messagesUnread > 0 ? (
								<Badge overlap="rectangular"
									className={classes.newMessagesCount}
									badgeContent={chat.messagesUnread ?? "0"}
									classes={{
										badge: classes.badgeStyle,
									}}
								/>
							) : null }*/}
						</span>
					}
				/>
			</ListItem>
			<Divider variant="inset" component="li" />
		</React.Fragment>
	);
};

export default ChatListItem;
