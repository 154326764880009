import React, { useEffect, useReducer, useState } from "react";

import openSocket from "socket.io-client";

import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";

import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import Title from "../../components/Title";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { toast } from "react-toastify";
import ConfirmationModal from "../../components/ConfirmationModal";
import EnvioMassaModal from "../../components/EnvioMassaModal";
import EnvioMassaInfoModal from "../../components/EnvioMassaInfoModal";
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const reducer = (state, action) => {
  if (action.type === "LOAD_ENVIOS") {
    const envios = action.payload;
    const newEnvios = [];

    envios.forEach((envio) => {
      const envioIndex = state.findIndex((q) => q.id === envio.id);
      if (envioIndex !== -1) {
        state[envioIndex] = envio;
      } else {
        newEnvios.push(envio);
      }
    });

    return [...state, ...newEnvios];
  }

  if (action.type === "UPDATE_ENVIOS") {
    const envio = action.payload;
    const envioIndex = state.findIndex((u) => u.id === envio.id);

    if (envioIndex !== -1) {
      state[envioIndex] = envio;
      return [...state];
    } else {
      return [envio, ...state];
    }
  }

  if (action.type === "DELETE_PERGUNTA") {
    const envioId = action.payload;
    const envioIndex = state.findIndex((q) => q.id === envioId);
    if (envioIndex !== -1) {
      state.splice(envioIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const EnvioMassa = () => {
  const classes = useStyles();

  const [envios, dispatch] = useReducer(reducer, []);
  const [loading, setLoading] = useState(false);

  const [envioModalOpen, setEnvioModalOpen] = useState(false);
  const [envioModalInfoOpen, setEnvioModalInfoOpen] = useState(false);
  const [selectedEnvio, setSelectedEnvio] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const { data } = await api.get("/envioMassa");
        dispatch({ type: "LOAD_ENVIOS", payload: data });

        setLoading(false);
      } catch (err) {
        toastError(err);
        setLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    const socket = openSocket(window.env?.REACT_APP_BACKEND_URL || process.env.REACT_APP_BACKEND_URL);

    socket.on("envioMassa", (data) => {
      if (data.action === "update" || data.action === "create") {
        toast.success('Envio atualizado!');
        dispatch({ type: "UPDATE_ENVIOS", payload: data.envios });
      }

      if (data.action === "delete") {
        dispatch({ type: "DELETE_PERGUNTA", payload: data.envioId });
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  const handleOpenEnvioModal = () => {
    setEnvioModalOpen(true);
    setSelectedEnvio(null);
  };

  const handleCloseEnvioModal = () => {
    setEnvioModalOpen(false);
    setSelectedEnvio(null);
  };

  const handleCloseEnvioInfoModal = () => {
    setEnvioModalInfoOpen(false);
    setSelectedEnvio(null);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmModalOpen(false);
    setSelectedEnvio(null);
  };

  /*const handleDeleteEnvio = async (queueId) => {
    try {
      await api.delete(`/envio/${queueId}`);
      toast.success(i18n.t("Envio deleted successfully!"));
    } catch (err) {
      toastError(err);
    }
    setSelectedEnvio(null);
  };*/

  return (
    <MainContainer>
      <ConfirmationModal
        title={"Aviso! Essa ação não pode ser interrompida!"}
        open={confirmModalOpen}
        onClose={handleCloseConfirmationModal}
        onConfirm={handleOpenEnvioModal}
      >
        Por medidas de segurança, e para atender as normas da Meta(Facebook), as mensagens em massa serão enviadas com intervalos de 15 segundos!
      </ConfirmationModal>
      <EnvioMassaModal
        open={envioModalOpen}
        onClose={handleCloseEnvioModal}
        envioId={selectedEnvio?.id}
      />
      <EnvioMassaInfoModal
          open={envioModalInfoOpen}
          onClose={handleCloseEnvioInfoModal}
          envioId={selectedEnvio?.id}
      />
      <MainHeader>
        <Title>Envios em Massa</Title>
        <MainHeaderButtonsWrapper>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              setConfirmModalOpen(true);
            }}
          >
            Novo envio
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                Mensagem
              </TableCell>
              <TableCell align="center">
                Enviados
              </TableCell>
              <TableCell align="center">
                Total
              </TableCell>
              <TableCell align="center">
                Destinatários
              </TableCell>
              <TableCell align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {envios.map((envio) => (
                <TableRow key={envio.id}>
                  <TableCell align="center">
                    <div className={classes.customTableCell}>
                      <Typography
                        style={{ width: 300, align: "center" }}
                        noWrap
                        variant="body2"
                      >
                        {envio.mensagem}
                      </Typography>
                    </div>
                  </TableCell>

                  <TableCell align="center">{envio.enviados}</TableCell>

                  <TableCell align="center">{envio.total !== 0 ? envio.total : 'Calculando...' }</TableCell>

                  <TableCell align="center">{envio.allContacts ? "Todos os contatos" : "Tag/Contato"}</TableCell>

                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedEnvio(envio);
                        setEnvioModalInfoOpen(true);
                      }}
                    >
                      <SearchIcon />
                    </IconButton>

                    {/* <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedEnvio(envio);
                        setConfirmModalOpen(true);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton> */}
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={4} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default EnvioMassa;
