import React, {useState, useEffect, useContext} from "react";
import { useHistory } from "react-router-dom";
import { isMobile } from 'react-device-detect';

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
import Switch from "@material-ui/core/Switch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";

import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";
import {AuthContext} from "../../context/Auth/AuthContext";
import {toast} from "react-toastify";

const useStyles = makeStyles((theme) => ({
  	maxWidth: {
    	width: "100%",
		marginBottom: 20
	},
	transfer: {
	  	marginTop: "15px",
		marginLeft: "5px"
	},
	textWarning: {
		color: 'red',
		fontStyle: 'italic',
		marginLeft: "5px",
		fontSize: '9pt',
	}
}));

const filterOptions = createFilterOptions({
	trim: true,
});

const TransferTicketModal = ({ modalOpen, onClose, ticketid }) => {
	const history = useHistory();
	const [options, setOptions] = useState([]);
	const [queues, setQueues] = useState([]);
	const [allQueues, setAllQueues] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedQueue, setSelectedQueue] = useState('');
	const [transferMessage, setTransferMessage] = useState(false);
	const classes = useStyles();
	const { findAll: findAllQueues } = useQueues();
	const { user } = useContext(AuthContext);
	const [whatsapps, setWhatsapps] = useState([]);
	const [selectedWhatsapp, setSelectedWhatsapp] = useState("");

	useEffect(() => {
		const loadQueues = async () => {
			const list = await findAllQueues();
			setAllQueues(list);
			setQueues(list);
		}
		loadQueues();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchWhatsapps = async () => {
				try {
					const { data: wpps} = await api.get("whatsapp" );
					setWhatsapps(wpps);

					const { data: tkt } = await api.get(`/tickets/${ticketid}`);

					wpps.map((wpp) => {
						if (tkt.whatsappId)
							setSelectedWhatsapp(tkt.whatsappId)
						else if (user.whatsapp?.id) {
							if (user.whatsapp.id === wpp.id) setSelectedWhatsapp(wpp.id);
						} else {
							if (wpp.isDefault) setSelectedWhatsapp(wpp.id);
						}
						return null;
					})

					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchWhatsapps();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [user, modalOpen, ticketid]);

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {
			const fetchUsers = async () => {
				try {
					const { data } = await api.get("/users/", {
						params: { searchParam },
					});
					setOptions(data.users);
					setLoading(false);
				} catch (err) {
					setLoading(false);
					toastError(err);
				}
			};

			fetchUsers();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedUser(null);
	};

	const handleSaveTicket = async e => {
		e.preventDefault();
		if (!ticketid) return;
		setLoading(true);
		try {
			let data = {};

			if (selectedUser) {
				data.userId = selectedUser.id
			}

			if (transferMessage)
				data.transferMessage = transferMessage;

			if (selectedWhatsapp)
				data.whatsappId = selectedWhatsapp;

			if (selectedQueue && selectedQueue !== null) {
				data.queueId = selectedQueue

				if (!selectedUser) {
					data.status = 'pending';
					data.userId = null;
				}
			}

			if (data.queueId) {
				const {data: ticket} = await api.put(`/tickets/${ticketid}`, data);

				await api.post("/log", {
					log: {
						descricao: `O usuário ${user?.name} transferiu o ticket(${ticket.id}) do contato ${ticket.contact?.name} para a fila ${ticket.queue?.name}, usuário ${ticket.user?.name ?? "no_user"}, conexão ${ticket.whatsapp?.name}`,
						origem: 'Ticket',
						userId: user?.id,
						ticketId: ticket.id,
						contactId: ticket.contact?.id,
						queueId: ticket.queue?.id,
						whatsappId: ticket.whatsapp?.id
					}
				});

				setLoading(false);
				history.push(`/tickets`);
				if (isMobile)
					window.location.reload();
			} else {
				setLoading(false);
				toast.error("Preencha todos os campos para realizar a transferencia");
			}
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} maxWidth="sm" scroll="paper">
			<form onSubmit={handleSaveTicket}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("transferTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					<Autocomplete
						className={classes.maxWidth}
						getOptionLabel={option => `${option.name}`}
						onChange={(e, newValue) => {
							setSelectedUser(newValue);
							if (newValue != null && Array.isArray(newValue.queues)) {
								setQueues(newValue.queues);
							} else {
								setQueues(allQueues);
								setSelectedQueue('');
							}
						}}
						options={options}
						filterOptions={filterOptions}
						freeSolo
						autoHighlight
						noOptionsText={i18n.t("transferTicketModal.noOptions")}
						loading={loading}
						renderInput={params => (
							<TextField
								{...params}
								label={i18n.t("transferTicketModal.fieldLabel")}
								variant="outlined"
								// required
								autoFocus
								onChange={e => setSearchParam(e.target.value)}
								InputProps={{
									...params.InputProps,
									endAdornment: (
										<React.Fragment>
											{loading ? (
												<CircularProgress color="inherit" size={20}/>
											) : null}
											{params.InputProps.endAdornment}
										</React.Fragment>
									),
								}}
							/>
						)}
					/>

					<FormControl variant="outlined" className={classes.maxWidth}>
						<InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
						<Select
							required
							value={selectedQueue}
							onChange={(e) => setSelectedQueue(e.target.value)}
							label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
						>
							<MenuItem value={''}>&nbsp;</MenuItem>
							{queues.map((queue) => (
								<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
							))}
						</Select>
					</FormControl>

					<FormControl variant="outlined" className={classes.maxWidth} style={{marginBottom: 5}}>
						<InputLabel>Conexão</InputLabel>
						<Select
							required
							labelWidth={60}
							value={selectedWhatsapp}
							onChange={(e) => setSelectedWhatsapp(e.target.value)}
							MenuProps={{
								anchorOrigin: {
									vertical: "bottom",
									horizontal: "left",
								},
								transformOrigin: {
									vertical: "top",
									horizontal: "left",
								},
								getContentAnchorEl: null,
							}}
						>
							{whatsapps.map((wpp) => (
								<MenuItem key={wpp.id} value={wpp.id}>{wpp.name}</MenuItem>
							))}
						</Select>
					</FormControl>

					<div className={classes.textWarning}>Atenção! Transferir a conexão fará com que as próximas mensagens sejam enviadas por outro número.</div>

					<FormControlLabel
						label="Enviar mensagem de transferencia"
						className={classes.transfer}
						labelPlacement="end"
						control={
							<Switch
								size="small"
								checked={transferMessage}
								onChange={() =>
									setTransferMessage((prevState) => !prevState)
								}
								name="transferMessage"
								color="primary"
							/>
						}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("transferTicketModal.buttons.cancel")}
					</Button>
					<ButtonWithSpinner
						variant="contained"
						type="submit"
						color="primary"
						loading={loading}
					>
						{i18n.t("transferTicketModal.buttons.ok")}
					</ButtonWithSpinner>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TransferTicketModal;
