import React, {useState, useEffect, useRef} from "react";

import api from "../../services/api";
import {toast} from "react-toastify";
import {IconButton, Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import UserTicketsInfoModal from "../../components/UserTicketsInfoModal";
import Chip from "@material-ui/core/Chip";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
    userName: {
        fontWeight: "bold",
        backgroundColor: "lightblue"
    },
    limeGreenBg: {
        backgroundColor: "limegreen",
        color: "white"
    },
    redBg: {
        backgroundColor: "red",
        color: "white"
    },
    yellowBg: {
        backgroundColor: "#f0b232",
        color: "black"
    },
    nps: {
        backgroundColor: "lightblue",
        borderColor: "#0480b6"
    }
}));

const UsersDashboard = ({firstDate, lastDate, queueId, userId}) => {
    const classes = useStyles();
    const fd = useRef(firstDate.toISOString());
    const ld = useRef(lastDate.toISOString());
    const [users, setUsers] = useState([]);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userModalInfoOpen, setUserModalInfoOpen] = useState(false);
    const [mediasPorUsuario, setMediasPorUsuario] = useState([{}]);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchUsers = async () => {
                try {
                    const {data} = await api.get("/dashboard/users", {
                        params: {
                            firstDate: fd.current,
                            lastDate: ld.current,
                            queueId,
                            userId
                        },
                    })

                    data.sort(function(a,b) {
                        return a.open > b.open ? -1 : a.open < b.open ? 1 : 0;
                    });

                    data.sort(function(a,b) {
                        return a.closed > b.closed ? -1 : a.closed < b.closed ? 1 : 0;
                    });

                    setUsers(data);
                } catch (err) {
                    toast.error(err)
                }
            }

            fetchUsers()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [queueId, userId]);

    const calcularMediaPorUsuario = (atendimentos) => {
        const mediasPorUsuario = {};

        // Agrupa as avaliações por userId
        atendimentos.forEach(atendimento => {
            const userId = atendimento.userId;
            const rate = atendimento.rate;

            if (!mediasPorUsuario[userId]) {
                mediasPorUsuario[userId] = { soma: 0, count: 0 };
            }

            mediasPorUsuario[userId].soma += rate;
            mediasPorUsuario[userId].count += 1;
        });

        // Calcula a média para cada usuário e converte para escala de 1 a 100
        for (const userId in mediasPorUsuario) {
            const { soma, count } = mediasPorUsuario[userId];
            const media = soma / count;
            mediasPorUsuario[userId] = media;
            // mediasPorUsuario[userId] = (media / 5) * 100;
        }

        return mediasPorUsuario;
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async() => {
                try {
                    const { data } = await api.get("/ticketNPS",{
                        params: {
                            firstDate: fd.current,
                            lastDate: ld.current,
                            queueId,
                            userId
                        },
                    });

                    if (data && data.length > 0){
                        const average = calcularMediaPorUsuario(data);
                        setMediasPorUsuario(average)
                    } else {
                        setMediasPorUsuario([{}])
                    }
                } catch (err) {
                    toast.error(err)
                }
            }

            fetchTickets()
        }, 1000)
        return () => clearTimeout(delayDebounceFn)
    }, [queueId, userId]);

    const handleCloseUserInfoModal = () => {
        setUserModalInfoOpen(false);
        setSelectedUser(null);
    };

    return (
        <div>
            <UserTicketsInfoModal
                open={userModalInfoOpen}
                onClose={handleCloseUserInfoModal}
                user={selectedUser}
                queueId={queueId}
            />
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">#</TableCell>
                        <TableCell align="center">
                            Nome
                        </TableCell>
                        <TableCell align="center">
                            Status
                        </TableCell>
                        <TableCell align="center">
                            Em atendimento
                        </TableCell>
                        {/*<TableCell align="center">*/}
                        {/*    Status*/}
                        {/*</TableCell>*/}
                        <TableCell align="center">
                            Encerrados
                        </TableCell>
                        <TableCell align="center">
                            N.P.S.
                        </TableCell>
                        <TableCell align="center">
                            Ações
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user, idx) => (
                        <TableRow key={user.id}>
                            <TableCell align="center"><b>{idx + 1}</b></TableCell>
                            <TableCell align="center">
                                <Chip variant="outlined" className={classes.userName} label={user.name}/>
                            </TableCell>
                            <TableCell align="center">
                                <Chip
                                    variant="outlined"
                                    className={user.status === "away" ? classes.yellowBg : user.status === "online" ? classes.limeGreenBg : classes.userName}
                                    label={user.status === "away" ? "Ausente" : user.status.charAt(0).toUpperCase() + user.status.slice(1)}
                                />
                            </TableCell>
                            <TableCell align="center">
                                <Chip variant="outlined" className={classes.limeGreenBg} label={user.open ?? 0}/>
                            </TableCell>
                            {/*<TableCell align="center">Online</TableCell>*/}
                            <TableCell align="center">
                                <Chip variant="outlined" className={classes.redBg} label={user.closed ?? 0} />
                            </TableCell>
                            <TableCell align="center">
                                <Chip variant="outlined" title={mediasPorUsuario[user.id]} className={classes.nps} label={mediasPorUsuario[user.id] ? '⭐'.repeat(parseInt(Math.round(mediasPorUsuario[user.id]) || 0), 10) : "❌ Sem avaliações "} />
                                {/*<Chip variant="outlined" className={classes.nps} label={`${Math.floor(Math.random() * 5) + 1} ⭐`} />*/}
                            </TableCell>
                            <TableCell align="center">
                                <IconButton
                                    size="small"
                                    onClick={() => {
                                        setSelectedUser(user);
                                        setUserModalInfoOpen(true);
                                    }}
                                >
                                    <SearchIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    )
};

export default UsersDashboard;
